import classNames from "./Heading.module.scss";
import Title from "../../Title";
import { CSSProperties } from "react";

type Props = {
  children?: React.ReactNode | null;
  className?: string;
  as?: "header" | "section" | "div";
  style?: CSSProperties;
};

const Heading = ({
  as: El = "div",
  children,
  className,
  style,
}: Props): React.ReactElement | null => {
  return (
    <El style={style} className={`${classNames.title} ${className}`}>
      {children}
    </El>
  );
};

Heading.Title = Title;

export default Heading;
