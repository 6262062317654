import api from "../../../../api";
import types from "../../../types";
import { generateSyncAction } from "../../../utils.redux";

export default function createQuestion(question) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.question.createQuestion.started));

    try {
      const data = await api.question.create(question);
      dispatch(generateSyncAction(types.question.createQuestion.success, data));
      dispatch(generateSyncAction(types.reset.questionCreation));
    } catch (error) {
      dispatch(generateSyncAction(types.question.createQuestion.failed, error));
    }
  };
}
