import types from "../../types";
import { generateSyncAction } from "../../utils.redux";
import API from "../../../api";

export default function completeOnBoardingSteps(input) {
  return async function (dispatch) {
    dispatch(
      generateSyncAction(types.common.completeOnBoardingSteps.started, input)
    );

    try {
      const data = await API.common.completeSteps(input);
      dispatch(
        generateSyncAction(types.common.completeOnBoardingSteps.success, data)
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.common.completeOnBoardingSteps.failed, error)
      );
    }
  };
}
