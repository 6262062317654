const roles = [
  {
    id: 1,
    name: "admin",
    title: "Admin",
  },
  {
    id: 2,
    name: "manager",
    title: "Manager",
  },
  {
    id: 3,
    name: "candidate",
    title: "Candidate",
  },
  {
    id: 4,
    name: "tempUser",
    title: "Temporary User",
  },
];

export default roles;
