import React from "react";
import classNames from "./Logo.module.scss";
import { Link } from "react-router-dom";
import logoSrc from "../../../images/logo.svg";

/**
 * Function representing Component props.
 * @param {Object} param0 Component props.
 */
function Logo({ minimal, onClick }) {
  return (
    <div
      className={`${classNames.wrapper} ${minimal ? classNames.crop : ""}`}
      onClick={onClick}
    >
      <Link to="/">
        <img alt={"Molly Logo"} src={logoSrc} className={`${classNames.img}`} />
      </Link>
    </div>
  );
}

export default Logo;
