import types from "../../types";
import { generateSyncAction } from "../../utils.redux";

export default function cleanupTempQuestionInTest() {
  return function (dispatch) {
    dispatch(
      generateSyncAction(types.tempQuestions.cleanupTempQuestionsInTest.success)
    );
  };
}
