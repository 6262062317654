import { AiFillCheckCircle, AiTwotoneCiCircle } from "react-icons/ai";
import classNames from "./Sidebar.module.scss";

export default function Question({ index, answer, onClick, obj, currentQue }) {
  return (
    <div
      className={
        currentQue.id === obj.id
          ? `${classNames.question} ${classNames.active}`
          : `${classNames.question}`
      }
      onClick={() => {
        onClick(obj);
      }}
    >
      <span>
        {!!answer ? (
          <AiFillCheckCircle style={{ color: "#32cb76" }} />
        ) : (
          <AiTwotoneCiCircle style={{ color: "#E6E8F1" }} />
        )}
      </span>
      <span> Question {index}</span>
    </div>
  );
}
