import types from "../../../types";
import { generateSyncAction } from "../../../utils.redux";

export default function customizeExercise(payload) {
  return async function (dispatch) {
    dispatch(
      generateSyncAction(types.exercise.customizeExercise.success, payload)
    );
  };
}
