/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

export default function useDraggable(el, disable = false) {
  const [{ dx, dy }, setOffset] = useState({ dx: 0, dy: 0 });

  useEffect(() => {
    const handleMouseDown = (event) => {
      const startX = event.pageX - dx;
      const startY = event.pageY - dy;

      const handleMouseMove = (event) => {
        const newDx = event.pageX - startX;
        const newDy = event.pageY - startY;
        setOffset({ dx: newDx, dy: newDy });
      };

      document.addEventListener("mousemove", handleMouseMove);

      document.addEventListener(
        "mouseup",
        () => {
          document.removeEventListener("mousemove", handleMouseMove);
        },
        { once: true }
      );
    };
    if (el.current) {
      el.current.addEventListener("mousedown", handleMouseDown);
    }
    return () => {
      if (el.current) {
        el.current.removeEventListener("mousedown", handleMouseDown);
      }
    };
  }, [dx, dy, el]);

  useEffect(() => {
    el.current.style.transform = disable
      ? `translate3d(0px, 0px, 0)`
      : `translate3d(${dx}px, ${dy}px, 0)`;
  }, [dx, dy, el]);
}
