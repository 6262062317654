import classNames from "./EditorOutput.module.scss";
import { PrimaryButton } from "../../index";
import { Spin } from "antd";

import { SettingOutlined } from "@ant-design/icons";

const { output, header, ioButtons, complieOptions } = classNames;

export default function EditorOutput({ data, runCode }) {
  return (
    <>
      <div className={header}>
        <div className={ioButtons}>
          <span>INPUT</span>
          <span>OUTPUT</span>
        </div>
        <div className={complieOptions}>
          <span>
            <SettingOutlined /> Run Settings
          </span>
          <PrimaryButton
            type="primary"
            style={{
              width: "auto",
              fontWeight: "bold",
              height: "35px",
              margin: "10px 10px 0 35px",
            }}
            onClick={() => runCode()}
          >
            Run Code
          </PrimaryButton>
        </div>
      </div>
      <div className={output}>
        {data?.fetching && <Spin />}
        <p>
          {data ? data?.data?.data?.stdout || data?.data?.data?.stderr : null}
        </p>
      </div>
    </>
  );
}
