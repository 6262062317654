import endpoints from "../config";

export default async function listSkillTestsByCompanyId(id) {
  const url = `${
    endpoints.SKILL_TEST[process.env.REACT_APP_ENV]
  }/tests?id=${id}`;
  return await fetch(url, {
    method: "GET",
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((error) => console.log(error));
}
