import api from "../../../../api";
import types from "../../../types";
import { generateSyncAction } from "../../../utils.redux";

export default function filterExercise(payload) {
  return async function (dispatch) {
    try {
      dispatch(generateSyncAction(types.exercise.filterExercise.started));
      const result = await api.exercise.filterQuesitons(payload);
      dispatch(
        generateSyncAction(types.exercise.filterExercise.success, result)
      );
    } catch (error) {
      dispatch(generateSyncAction(types.exercise.filterExercise.failed, error));
    }
  };
}
