import { ReactNode } from "react";
import classNames from "./Footer.module.scss";

type Props = {
  children: ReactNode;
  className?: string;
  as: "div" | "section";
};

const Item = ({ children, className, as: El = "div" }: Props): ReactNode => {
  return (
    <El className={`${classNames.footerItem} ${className}`}>{children}</El>
  );
};

export default Item;
