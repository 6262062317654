import api from "../../../../api";
import types from "../../../types";
import { generateSyncAction } from "../../../utils.redux";

export default function startCandidateTest(values) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.candidateTest.sendManagerEmail.started));

    try {
      const data = await api.candidateTest.sendManagerEmail(values);
      dispatch(
        generateSyncAction(types.candidateTest.sendManagerEmail.success, data)
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.candidateTest.sendManagerEmail.failed, error)
      );
    }
  };
}
