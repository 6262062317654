const matchNotifyEmail = (env) => {
  switch (env) {
    case "dev":
      return "devs@smashtaps.com";
    case "qa":
      return "qas@smashtaps.com";
    case "prod":
      return "hello@smashtaps.com";
    default:
      break;
  }
};

export default matchNotifyEmail;

/**
 * New molly signups will notify to below email addresses
 *
 * "dev" environment :- devs@smashtaps.com
 * "next" environment :- qas@smashtaps.com
 * "prod" environment :- hello@smashtaps.com
 */
