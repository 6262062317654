import types from "../../types";

export default function send(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.messaging.send.started:
      return Object.assign({}, { fetching: true, success: false });

    case types.messaging.send.success:
      return Object.assign(
        {},
        { success: true, fetching: false, data: action.data }
      );

    case types.reset.sendMessage:
      return Object.assign(
        {},
        { fetching: false, success: false, error: false }
      );

    case types.messaging.send.failed:
      return Object.assign(
        {},
        { failed: true, fetching: false, error: action.data }
      );

    default:
      return state;
  }
}
