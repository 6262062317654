// import { Skeleton } from "antd";
import classNames from "./CalendarSkeleton.module.scss";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

const CalendarSkeleton = () => {
  return (
    <div className={classNames.wrapper}>
      {/* <div className={classNames.header}>
        <Skeleton.Avatar
          active={true}
          shape="square"
          style={{ width: 300, height: 40, borderRadius: 5 }}
        />
        <div>
          <Skeleton.Avatar
            active={true}
            shape="square"
            style={{
              borderRadius: 5,
              width: 60,
              height: 30,
              marginBottom: 10,
              marginLeft: 30,
            }}
          />
          <Skeleton.Avatar
            active={true}
            shape="square"
            style={{
              borderRadius: 5,
              width: 80,
              height: 30,
              marginBottom: 10,
              marginLeft: 10,
            }}
          />
        </div>
      </div> */}
      <div className={classNames.content}>
        <FullCalendar defaultView="dayGridMonth" plugins={[dayGridPlugin]} />
      </div>
    </div>
  );
};

export default CalendarSkeleton;
