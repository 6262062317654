import types from "../../types";

export default function updateCompanyHandler(
  state = { fetching: false, error: false, updated: false },
  action
) {
  switch (action.type) {
    case types.company.updateCompanyHandler.started:
      return Object.assign({}, { fetching: true, updated: false });

    case types.company.updateCompanyHandler.success:
      return Object.assign(
        {},
        {
          fetching: false,
          updated: true,
          data: { ...action.data.data.updateCompanyHandler },
        }
      );

    case types.company.updateCompanyHandler.failed:
      return Object.assign(
        {},
        { fetching: false, updated: false, error: action.data.data }
      );

    default:
      return state;
  }
}
