import { Skeleton, Row } from "antd";
import classNames from "./SettingsSkeleton.module.scss";

const SettingsSkeleton = () => {
  const options = [1, 2, 3, 4, 5, 6];
  const inputs = [1, 2, 3, 4, 5, 6];
  return (
    <div className={classNames.wrapper}>
      <div className={classNames.sidebar}>
        {options.map((key) => (
          <Skeleton.Button
            key={key}
            active={true}
            shape="square"
            style={{
              borderRadius: 5,
              width: 243,
              height: 30,
              marginBottom: 15,
            }}
          />
        ))}
      </div>
      <div className={classNames.body}>
        <div className={classNames.bodyHeader}>
          <Skeleton.Avatar active={true} size={100} />
          <Skeleton.Avatar
            active={true}
            shape="square"
            style={{ width: 300, height: 40, borderRadius: 5 }}
          />
        </div>
        <div className={classNames.bodyContent}>
          {inputs.map((key) => (
            <div className={classNames.formItem}>
              <Row>
                <Skeleton.Avatar
                  active={true}
                  shape="square"
                  style={{ width: 150, height: 25, borderRadius: 5 }}
                />
              </Row>
              <Skeleton.Input
                key={key}
                active={true}
                shape="square"
                style={{
                  borderRadius: 5,
                  width: 538,
                  height: 52,
                  marginBottom: 28,
                }}
              />
            </div>
          ))}
        </div>
        <div className={classNames.bodyFooter}>
          <Skeleton.Button
            active={true}
            shape="square"
            style={{
              borderRadius: 5,
              width: 162,
              height: 52,
              marginBottom: 28,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsSkeleton;
