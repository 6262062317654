import { API } from "aws-amplify";
import { getSubscriptionByCompanyId } from "../../graphql/queries";

export default async function fetchSubcrptionPlanByCompanyId(companyId) {
  return await API.graphql({
    query: getSubscriptionByCompanyId,
    variables: {
      companyId,
    },
  });
}
