import userSignup from "../actions/signup.ac";
import confirmAuth from "../actions/confirmAuth.ac";
import userSignin from "../actions/signin.ac";
import getCurrentUser from "../actions/getCurrentUser.ac";
import {
  createOnBoardingProfiles,
  getUserById,
  updateProfileCompletionCognito,
  inviteMember,
  resetMemberPassword,
  updateUserAvatar,
  globalSignOut,
  deactivateUserProfile,
  activateMemberAccount,
  resetDeactivateProfile,
  removeMember,
} from "./user";
import {
  getCompanyProfile,
  updateCompanyProfile,
  updateCompanyHandler,
  updateCompanyLogo,
  getCompanyLogo,
} from "./company";
import {
  createJob,
  listJobs,
  setJobInView,
  listJobTitles,
  getJob,
  updateJob,
  resetGetJob,
} from "./jobs";
import {
  createFeedback,
  updateFeedback,
  deleteFeedback,
  getFeedbackByCandidate,
} from "./feedback";
import { sendMessage, listMessages, resetMessageState } from "./messaging";
import {
  updateStage,
  changeManagerInCharge,
  scheduleInterview,
  fetchInterviewsByCandidate,
  listCandidatesByCompanyId,
  listInterviewsByCompanyId,
  getCandidatesByCompanyId,
  recordSentSkillTests,
  resetUpdateStage,
  resetCandidateManagerInCharge,
  resetChangeManager,
  reseScheduleInterview,
} from "./candidates";
import { resetPassword } from "./userManagement";
import { getSubscriptionPlanByCompanyId } from "./subcriptionPlan";
import { completeOnBoardingSteps } from "./common";
import {
  submitAnswers,
  getQuestionById,
  listTestsByCompanyId,
  getSKBySkillTestId,
  getAllAnswers,
  listDifficulty,
  getAllIndustry,
  getAllTestDuration,
  listQuestions,
  listQuestionsTypes,
  listDesignation,
  createAnswer,
  createQuestion,
  createSkillTest,
  listQuestionsByIds,
  getCandidateQuestionById,
  assignDefaultSkillTest,
  updateSkillTest,
  listTestsTemplates,
  getTempSkillTestData,
  filterExercise,
  customizeExercise,
  resetQuestion,
  getSignedURL,
  resetGetSignedURL,
} from "./skillTest";
import { mapAnswersWithQuestionTemp } from "./temporyAnswers";
import {
  getCandidateTestById,
  startCandidateTest,
  createCandidateTest,
  listCandidateTestsByCandidateId,
  updateCandidateTestResult,
  rateCandidateTestResult,
  sendManagerEmail,
  resetRateState,
  resetCandidateTest,
} from "./candidateTest";
import {
  getLanguages,
  runCode,
  createExercise,
  listExercises,
  resetExercise,
  resetEditor,
} from "./codeEditor";
import { tempAddQuestionToTest } from "./temporaryAddQuestionsToTest";
import { tempRemoveQuestionFromTest } from "./temporaryRemoveQuestionsFromTest";
import { cleanupTempQuestionInTest } from "./cleanupTemporaryQuestionsInTest";
import resetErrors from "./resetErrors.ac";

const actions = {
  userSignup,
  confirmAuth,
  userSignin,
  getCurrentUser,
  createOnBoardingProfiles,
  getUserById,
  updateProfileCompletionCognito,
  getCompanyProfile,
  createJob,
  listJobs,
  setJobInView,
  listJobTitles,
  getJob,
  updateJob,
  inviteMember,
  resetMemberPassword,
  createFeedback,
  updateFeedback,
  deleteFeedback,
  getFeedbackByCandidate,
  sendMessage,
  listMessages,
  updateStage,
  resetUpdateStage,
  changeManagerInCharge,
  scheduleInterview,
  fetchInterviewsByCandidate,
  listCandidatesByCompanyId,
  listInterviewsByCompanyId,
  resetPassword,
  updateCompanyProfile,
  getSubscriptionPlanByCompanyId,
  updateCompanyHandler,
  getCandidatesByCompanyId,
  updateUserAvatar,
  updateCompanyLogo,
  globalSignOut,
  completeOnBoardingSteps,
  submitAnswers,
  listTestsByCompanyId,
  mapAnswersWithQuestionTemp,
  getQuestionById,
  getSKBySkillTestId,
  getAllAnswers,
  getCompanyLogo,
  startCandidateTest,
  getCandidateTestById,
  listDifficulty,
  getAllIndustry,
  getAllTestDuration,
  tempAddQuestionToTest,
  listQuestions,
  listQuestionsTypes,
  listDesignation,
  tempRemoveQuestionFromTest,
  createAnswer,
  createQuestion,
  createSkillTest,
  listQuestionsByIds,
  assignDefaultSkillTest,
  createCandidateTest,
  recordSentSkillTests,
  listCandidateTestsByCandidateId,
  updateCandidateTestResult,
  cleanupTempQuestionInTest,
  updateSkillTest,
  listTestsTemplates,
  resetMessageState,
  rateCandidateTestResult,
  getTempSkillTestData,
  sendManagerEmail,
  deactivateUserProfile,
  resetRateState,
  resetCandidateManagerInCharge,
  resetChangeManager,
  resetGetJob,
  reseScheduleInterview,
  runCode,
  getLanguages,
  resetCandidateTest,
  filterExercise,
  createExercise,
  listExercises,
  customizeExercise,
  resetExercise,
  resetQuestion,
  getSignedURL,
  resetGetSignedURL,
  getCandidateQuestionById,
  resetErrors,
  activateMemberAccount,
  resetEditor,
  resetDeactivateProfile,
  removeMember,
};

export default actions;
