import AceEditor from "react-ace";

// import classNames from "./EditorInput.module.scss";

import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-vibrant_ink";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-typescript";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-php";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-ruby";
import "ace-builds/src-noconflict/mode-swift";

function EditorInput({
  language,
  setInputCode,
  label,
  inputKey,
  value = "",
  isExpand,
}) {
  return (
    <AceEditor
      mode={language ? "javascript" : language}
      theme="vibrant_ink"
      onChange={(e) => setInputCode(label, e, inputKey)}
      name="questions_editor"
      editorProps={{ $blockScrolling: true }}
      highlightActiveLine
      wrapEnabled={true}
      height={isExpand ? "55vh" : 300}
      value={value}
      width={isExpand ? "100wh" : "100%"}
      showGutter={false}
      showLineNumbers={true}
    />
  );
}

export default EditorInput;
