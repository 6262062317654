import types from "../../../types";

export default function filterExercise(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.exercise.filterExercise.started:
      return {
        ...state,
        fetching: true,
      };
    case types.exercise.filterExercise.success:
      return {
        ...state,
        fetching: false,
        data: action?.data?.data ?? null,
      };
    case types.exercise.filterExercise.failed:
      return {
        ...state,
        fetching: false,
        data: null,
      };
    case types.exercise.customizeExercise.success:
      return {
        ...state,
        data: action?.data?.data ?? null,
      };
    default:
      return state;
  }
}
