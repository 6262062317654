import types from "../../types";

export default function job(state = { fetching: false, error: false }, action) {
  switch (action.type) {
    case types.jobs.get.started:
      return Object.assign({}, { fetching: true });

    case types.jobs.get.success:
      return Object.assign(
        {},
        { fetching: false, data: action.data.data.getJob }
      );

    case types.candidates.updateStage.started: {
      const candidate = state.data?.candidates?.items?.find(
        (candidate) => candidate.id === action.data?.candidateId
      );

      const newCandidateObj = {
        ...candidate,
        stage: action.data.stage,
        prev: candidate.stage,
      };

      const filteredCandidates = state.data?.candidates?.items.filter(
        (candidate) => candidate.id !== action?.data?.candidateId
      );

      return Object.assign(
        {},
        state,
        { fetching: false },
        {
          data: {
            ...state.data,
            candidates: {
              items: [...filteredCandidates, newCandidateObj],
            },
          },
        }
      );
    }

    case types.candidates.updateStage.failed: {
      const filteredCandidates = state.data?.candidates?.items?.filter(
        (candidate) => candidate.id !== action.data?.data?.candidateId
      );

      const candidate = state.data?.candidates?.items?.find(
        (candidate) => candidate.id === action.data?.data?.candidateId
      );

      const newCandidateObj = {
        ...candidate,
        stage: candidate.prev,
      };

      return Object.assign(
        {},
        { fetching: false },
        { error: action.data.error },
        {
          data: {
            ...state.data,
            candidates: {
              items: [...filteredCandidates, newCandidateObj],
            },
          },
        }
      );
    }

    case types.reset.resetGetJob:
      return Object.assign({}, { fetching: false, error: false });

    case types.jobs.get.failed:
      if (action?.data?.data?.getJob?.id) {
        return Object.assign(
          {},
          {
            fetching: false,
            data: action.data.data.getJob,
            error: action.data.errors,
            partialError: true,
          }
        );
      }
      return Object.assign({}, { fetching: false, error: action.data.errors });

    default:
      return state;
  }
}
