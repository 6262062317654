import endpoints from "../config";

export default async function getApiById(testId) {
  return await (
    await fetch(
      endpoints.SKILL_TEST[process.env.REACT_APP_ENV] + `?id=${testId}`,
      {
        method: "GET",
      }
    )
  ).json();
}
