import endpoints from "../../config";

export default async function getCandidateTestById(id) {
  const url = `${endpoints.CANDIDATE_TEST[process.env.REACT_APP_ENV]}?id=${id}`;
  return await fetch(url, {
    method: "GET",
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((error) => console.log(error));
}
