import types from "../../types";

export default function company(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.company.fetchCompanyProfile.started:
      return Object.assign({}, { fetching: true });

    case types.company.fetchCompanyProfile.success:
      return Object.assign(
        {},
        { fetching: false, data: { ...action.data.data.getCompany } }
      );
    case types.company.updateCompanyProfile.success:
      return Object.assign(
        {},
        {
          fetching: false,
          data: { ...state.data, ...action.data.data.updateCompanyProfile },
        }
      );

    case types.company.updateCompanyHandler.success:
      return Object.assign(
        {},
        { ...state },
        {
          data: {
            ...state.data,
            companyHandler: action.data.data.updateCompanyHandler.handler,
          },
        }
      );

    case types.common.completeOnBoardingSteps.success:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          prevSteps: state.data.steps,
          steps: action.data.data.completeSteps.steps,
        },
      });

    case types.company.fetchCompanyProfile.failed:
      return Object.assign(
        {},
        {
          fetching: false,
          error: action.data.errors,
          data: action.data?.data?.getCompany,
        }
      );

    default:
      return state;
  }
}
