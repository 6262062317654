import { Skeleton } from "antd";
import classNames from "./DashboardSkeleton.module.scss";
import { JobSkeleton } from "../index";

const DashboardSkeleton = () => {
  const menu = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  return (
    <div className={classNames.wrapper}>
      <div className={classNames.sidebar}>
        <Skeleton.Avatar
          active={true}
          shape="square"
          style={{
            borderRadius: 5,
            width: 190,
            height: 50,
            marginBottom: 35,
          }}
        />
        {menu.map((key) => (
          <Skeleton.Button
            key={key}
            active={true}
            shape="square"
            style={{
              borderRadius: 5,
              width: 190,
              height: 30,
              marginBottom: 15,
            }}
          />
        ))}
      </div>
      <div className={classNames.body}>
        <div className={classNames.search}>
          <Skeleton.Input
            active={true}
            shape="square"
            style={{
              borderRadius: 5,
              width: 300,
              height: 30,
              margin: 30,
            }}
          />
          <Skeleton.Avatar
            active={true}
            size={50}
            style={{ marginRight: 20 }}
          />
        </div>
        <div className={classNames.content}>
          <JobSkeleton />
        </div>
      </div>
    </div>
  );
};

export default DashboardSkeleton;
