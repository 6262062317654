const CODE_EDITOR_TYPES = {
  ADMIN: "admin",
  LANGUAGE_LOCKED: "languageLocked",
  LANGUAGE_UNLOCKED: "languageUnlocked",
  INDIVIDUAL: "individual",
};

Object.freeze(CODE_EDITOR_TYPES);

export default CODE_EDITOR_TYPES;
