import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Input, Button, Popconfirm } from "antd";
import {
  FullscreenOutlined,
  FullscreenExitOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import classNames from "./Editor.module.scss";
import { Title } from "../../common";
import * as fileDownload from "js-file-download";

import EditorInput from "./EditorInput";
import EditorOutput from "./EditorOutput";
import ac from "../../../redux/actions";
import CODE_EDITOR_TYPES from "./editor.constants";
import { deepClone } from "../../../helpers/utils";

const TabTitle = ({ index, handleTabTitleChange, defaultValue, items }) => {
  const [isTitleEditEnabled, setTitleEditEnabled] = useState(false);

  const handleDoubleClick = () => setTitleEditEnabled(true);
  const handleBlur = () => setTitleEditEnabled(false);
  return (
    <div onDoubleClick={handleDoubleClick}>
      {!isTitleEditEnabled ? (
        <span>{items[index]?.label}</span>
      ) : (
        <Input
          style={{
            opacity: 1,
            cursor: "pointer",
            backgroundColor: "transparent",
            border: "none",
            boxShadow: "none",
            width: "100%",
            height: "18px",
            color: "#878b92",
          }}
          onBlur={handleBlur}
          disabled={!isTitleEditEnabled}
          onChange={(e) => handleTabTitleChange(index, e.target.value)}
          value={defaultValue}
        />
      )}
    </div>
  );
};

function Editor({
  type = CODE_EDITOR_TYPES.ADMIN,
  codingLanguage,
  codingQuestion,
  setCodingQuestion,
  runCmd,
  setRunCmd,
  stdIn,
  setStdIn,
  subHeader = "Your Answer", // check dependencies when changing default values
  subHeaderCss = { fontSize: "22px", fontWeight: "700" },
  rateTestComponent = false,
  customQuestion = true,
  hideAddButton = false,
  questionId,
  toggleEditorDisplay,
}) {
  const dispatch = useDispatch();

  const result = useSelector(({ runCode }) => runCode);

  const question = useSelector(({ listQuestionsByIds }) => {
    return listQuestionsByIds
      ? listQuestionsByIds?.data?.find(
          (question) => question?.id === questionId
        )
      : [];
  });

  const [showPopup, setShowPopup] = useState(false);
  const [targetTabKey, setTargetTabKey] = useState();

  const getFileType = (language) => {
    let suffix;
    switch (language) {
      case "c":
        suffix = ".c";
        break;
      case "cpp":
        suffix = ".cpp";
        break;
      case "csharp":
        suffix = ".cs";
        break;
      case "java":
        suffix = ".java";
        break;
      case "javascript":
        suffix = ".js";
        break;
      case "kotlin":
        suffix = ".kt";
        break;
      case "php":
        suffix = ".php";
        break;
      case "python":
        suffix = ".py";
        break;
      case "ruby":
        suffix = ".rb";
        break;
      case "swift":
        suffix = ".swift";
        break;
      case "typescript":
        suffix = ".ts";
        break;
      default:
        suffix = ".js";
    }
    return suffix;
  };
  const [isEditorOpen, setIsEditorOpen] = useState(false);

  const tabs = useMemo(
    () => [
      {
        label: `${codingLanguage === "java" ? "Main" : "main"}${getFileType(
          codingLanguage
        )}`,
        key: "0",
      },
    ],
    [codingLanguage]
  );

  const [activeKey, setActiveKey] = useState("0");
  const [items, setItems] = useState(tabs);

  const onChange = (newActiveKey) => setActiveKey(newActiveKey);

  const setInputCodeOnChange = (name, content, key) => {
    const fileList = codingQuestion.files;
    if (Array.isArray(fileList) && !fileList.length) {
      setCodingQuestion({
        files: [
          ...items
            .filter((o) => o.label !== name)
            .map((o) => {
              return {
                name: o.label,
                content: "",
                key,
              };
            }),
          {
            name,
            content,
            key,
          },
        ],
      });
    } else {
      const existingFile = fileList.findIndex((file) => file.name === name);
      if (existingFile === -1) {
        return setCodingQuestion({
          files: fileList.map((file) =>
            file.name === name ? { ...file, content, key } : file
          ),
        });
      }

      const _items = codingQuestion.files;
      _items[existingFile] = {
        ..._items[existingFile],
        content,
      };
      setCodingQuestion({
        files: _items,
      });
    }
  };

  const runCode = () =>
    dispatch(
      ac.runCode({ codingLanguage, codingQuestion, runCmd, stdIn, questionId })
    );

  const add = () => {
    const newActiveKey = `${Math.max(...items.map((e) => e.key)) + 1}`;
    const name = `file-${newActiveKey}${getFileType(codingLanguage)}`;
    setCodingQuestion({
      files: codingQuestion?.files?.length
        ? [
            ...codingQuestion?.files?.map((e, i) => {
              if (e && !e.hasOwnProperty("key")) {
                return {
                  ...e,
                  key: String(i),
                };
              }
              return e;
            }),
            {
              name,
              content: "",
              key: String(newActiveKey),
            },
          ]
        : [
            {
              name: tabs[0].label,
              content: "",
              key: "0",
            },
            {
              name,
              content: "",
              key: String(newActiveKey),
            },
          ],
    });
    setActiveKey(String(newActiveKey));
  };

  const remove = (targetKey) => {
    let newActiveKey = activeKey;
    const updatedItems = items;
    const activeItemIndex = updatedItems.findIndex(
      (o) => Number(o.key) === Number(targetKey)
    );
    if (Number(targetKey)) {
      if (Number(activeKey) === Number(targetKey)) {
        const _key = activeItemIndex - 1;
        activeItemIndex < -1
          ? (newActiveKey = "0")
          : (newActiveKey = String(items[_key].key));
      }
    }
    const _codingQuestion = codingQuestion?.files?.filter(
      (o) => o.name !== updatedItems[activeItemIndex].label
    );
    setCodingQuestion({
      files: _codingQuestion,
    });
    setActiveKey(String(newActiveKey));
    closePopUp();
  };

  const onEdit = (targetKey, action) => {
    if (action === "add") {
      add();
    } else {
      // We check if there is content on tab
      // If no we remove tab
      // else get users response and then remove tab.
      const selectedItem = codingQuestion?.files?.find(
        (item) => item.key === targetKey
      );
      if (selectedItem.content) {
        setTargetTabKey(targetKey);
        setShowPopup(true);
      } else {
        remove(targetKey);
      }
    }
  };

  const closePopUp = () => {
    setShowPopup(false);
    setTargetTabKey("");
  };

  const handleTabTitleChange = (index, value) => {
    setItems(
      items.map((item, i) => (i === index ? { ...item, label: value } : item))
    );
    setCodingQuestion((prev) => {
      return {
        files: prev.files.map((file) =>
          file?.key === String(index) ? { ...file, name: value } : file
        ),
      };
    });
  };

  const handleDownload = () => {
    codingQuestion.files.forEach((file) => {
      fileDownload(file.content, file.name);
    });
  };

  useEffect(() => {
    if (Array.isArray(codingQuestion?.files) && codingQuestion?.files?.length) {
      setItems(
        codingQuestion.files.map((o, i) => {
          return {
            label: o?.name,
            key: String(o?.key ?? i),
          };
        })
      );
    } else {
      setItems(tabs);
      setActiveKey("0");
    }
  }, [codingQuestion, tabs]);

  useEffect(() => {
    setActiveKey("0");
  }, [question?.id]);

  const screenSizeAction = !isEditorOpen ? (
    <Button
      className={classNames.expandBtn}
      type="text"
      block
      icon={<FullscreenOutlined />}
      onClick={() => setIsEditorOpen(!isEditorOpen)}
    >
      Enter full screen
    </Button>
  ) : (
    <Button
      className={classNames.expandBtn}
      type="text"
      block
      icon={<FullscreenExitOutlined />}
      onClick={() => setIsEditorOpen(!isEditorOpen)}
    >
      Exit full screen
    </Button>
  );

  const codeEditorTabs = (
    <div
      style={
        isEditorOpen
          ? {
              top: "80px",
              left: 0,
              zIndex: 999,
              position: "fixed",
              backgroundColor: "#fff",
              marginLeft: "5px",
            }
          : null
      }
    >
      <Popconfirm
        title="This will remove the tab and tab content will be lost. Are you sure you want to continue?"
        okText="Yes"
        cancelText="No"
        open={showPopup}
        onConfirm={() => remove(targetTabKey)}
        onCancel={closePopUp}
      ></Popconfirm>
      <Tabs
        type="editable-card"
        onChange={onChange}
        activeKey={activeKey}
        onEdit={onEdit}
        tabBarGutter={0}
        tabBarExtraContent={customQuestion ? screenSizeAction : null}
        style={isEditorOpen ? { width: "98.5vw" } : null}
        hideAdd={hideAddButton}
      >
        {items.map((item, index) => (
          <Tabs.TabPane
            key={item.key}
            closable={!!index}
            tab={
              !index ? (
                item.label
              ) : (
                <TabTitle
                  index={index}
                  handleTabTitleChange={handleTabTitleChange}
                  defaultValue={item.label}
                  items={items}
                />
              )
            }
          >
            <>
              <EditorInput
                setInputCode={setInputCodeOnChange}
                language={codingLanguage}
                label={item.label}
                key={item.key}
                inputKey={item.key}
                value={
                  codingQuestion?.files[
                    items.findIndex((o) => Number(o.key) === Number(item.key))
                  ]?.content ?? ""
                }
                isExpand={isEditorOpen}
              />
            </>
          </Tabs.TabPane>
        ))}
      </Tabs>
      <EditorOutput
        data={result}
        runCode={runCode}
        language={codingLanguage}
        runCmd={runCmd}
        setRunCmd={setRunCmd}
        stdIn={stdIn}
        setStdIn={setStdIn}
        questionId={questionId}
        type={type}
      />
    </div>
  );

  const ConfirmPop = (
    <Popconfirm
      title="This will reset the code editor and your changes will be lost. Are you sure you want to continue?"
      okText="Yes"
      cancelText="No"
      onConfirm={() => {
        setActiveKey("0");
        type === CODE_EDITOR_TYPES.LANGUAGE_LOCKED
          ? setCodingQuestion(deepClone(question?.files))
          : toggleEditorDisplay();
      }}
    >
      <Button type="link">Start over?</Button>
    </Popconfirm>
  );

  return (
    <div className="code-editor-tabs">
      {/* TODO: Styling changes */}{" "}
      <div className={classNames.ansSectionHeader}>
        {customQuestion ? (
          <div>
            <Title as="h3" style={subHeaderCss}>
              {subHeader}
            </Title>
          </div>
        ) : null}

        {type === CODE_EDITOR_TYPES.LANGUAGE_LOCKED && rateTestComponent ? (
          <>
            {" "}
            <div>
              <Title as="h3" style={subHeaderCss}>
                {subHeader}
              </Title>
            </div>
            <Button
              type="link"
              onClick={handleDownload}
              icon={<DownloadOutlined />}
            >
              Download sources
            </Button>
          </>
        ) : type === CODE_EDITOR_TYPES.LANGUAGE_LOCKED && codingLanguage ? (
          <>
            <div>
              You selected{" "}
              {codingLanguage.charAt(0).toUpperCase() + codingLanguage.slice(1)}
              .{ConfirmPop}
            </div>
          </>
        ) : type === CODE_EDITOR_TYPES.LANGUAGE_UNLOCKED && codingLanguage ? (
          <>
            <div>
              You selected{" "}
              {codingLanguage.charAt(0).toUpperCase() + codingLanguage.slice(1)}
              .{ConfirmPop}
            </div>
          </>
        ) : null}
      </div>
      {codeEditorTabs}
    </div>
  );
}

export default Editor;
