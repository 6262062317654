import React from "react";
import classNames from "./Warning.module.scss";
import { Wrapper } from "..";
import { Alert } from "antd";

function Warning({ message, children, type = "info" }) {
  return (
    <Wrapper classes={classNames.wrapper}>
      {children && children}
      {message && <Alert message={message} type={type} />}
    </Wrapper>
  );
}

export default Warning;
