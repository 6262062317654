import types from "../../types";

export default function userProfileById(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.user.fetchUserProfileById.started:
      return Object.assign({}, { fetching: true });

    case types.user.fetchUserProfileById.success:
      return Object.assign(
        {},
        { fetching: false, data: { ...action.data?.data?.getUser } }
      );

    case types.user.fetchUserProfileById.failed:
      return Object.assign(
        {},
        {
          fetching: false,
          error: { ...action.data.errors },
          data: { ...action.data.data.getUser },
        }
      );

    default:
      return state;
  }
}
