import types from "../../types";
import api from "../../../api";
import { generateSyncAction } from "../../utils.redux";

export default function updateStage(input) {
  return async function (dispatch) {
    dispatch(
      generateSyncAction(types.candidates.changeManagerInCharge.started)
    );

    try {
      const data = await api.candidates.changeManagerInCharge(input);
      dispatch(
        generateSyncAction(types.candidates.changeManagerInCharge.success, data)
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.candidates.changeManagerInCharge.failed, error)
      );
    }
  };
}
