import Header from "./Header";
import Footer from "./Footer";
import Body from "./Body";
import { Modal as AntModal } from "antd";
// import findChildByTypeName from "../utils/findChildByTypeName";
// import { isValidElement, cloneElement } from "react";
import classNames from "./Modal.module.scss";

type Props = {
  children?: React.ReactNode;
  maxHeight?: string;
  visible?: boolean;
  size?: "large" | "small";
  footer?: string;
  closeIcon?: () => void;
  closable?: boolean;
  style?: React.CSSProperties;
  onCloseClick: () => void;
  maskClosable?: boolean;
};

const Modal = ({
  children,
  maxHeight,
  size,
  visible,
  footer,
  closable,
  style,
  closeIcon,
  onCloseClick,
  maskClosable,
}: Props): React.ReactNode => {
  // const Header = findChildByTypeName(children, "Header");
  // const Footer = findChildByTypeName(children, "Footer");
  // const Body = findChildByTypeName(children, "Body");

  // const AlteredBody = isValidElement(Body)
  //   ? cloneElement(Body, {
  //       style: Object.assign({}, Body?.props?.style, {
  //         maxHeight,
  //         overflow: "auto",
  //       }),
  //     })
  //   : null;

  return (
    <AntModal
      style={{ maxHeight: "90vh" }}
      closeIcon={closeIcon}
      onCancel={onCloseClick}
      // footer={footer === null ? null : Footer}
      footer={null}
      closable={closable === true ? true : false}
      visible={visible}
      centered
      width={size === "large" ? 1160 : 500}
      // title={Header}
      maskClosable={maskClosable}
      className={classNames.modal}
      bodyStyle={{
        padding: 0,
        margin: 0,
        backgroundColor: "#f5f6fa",
        // overflow: "auto",
        // maxHeight: "90vh"
      }}
    >
      {children}
    </AntModal>
  );
};

Modal.Header = Header;
Modal.Footer = Footer;
Modal.Body = Body;

export default Modal;
