import { Result } from "../../common";
import { FiWifiOff } from "react-icons/fi";

export default function offlineNotice() {
  return (
    <Result
      icon={<FiWifiOff size={120} color="rgba(0, 0, 0, 0.25)" />}
      title="You are offline"
      subTitle="Please check you internet connection"
    />
  );
}
