import types from "../../types";

function sort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export default function jobTitles(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.jobs.listTitles.started:
      return Object.assign({}, { fetching: true });

    case types.jobs.listTitles.success:
      return Object.assign(
        {},
        { fetching: false, data: action.data.sort(sort("createdAt")).reverse() }
      );

    case types.jobs.createJob.success:
      const data = [
        ...state.data,
        {
          id: action.data.data.createJob.id,
          title: action.data.data.createJob.title,
          createdAt: new Date().toISOString(),
          published: true,
        },
      ]
        .sort(sort("createdAt"))
        .reverse();

      return Object.assign(
        {},
        { ...state },
        {
          data: [...data],
        }
      );

    case types.jobs.updateJob.success:
      const updatedData = [...state.data];
      updatedData.find(
        (value) => value.id === action.data.data.updateJob.id
      ).title = action.data.data.updateJob.title;
      return Object.assign(
        {},
        { ...state },
        {
          data: [...updatedData],
        }
      );

    case types.jobs.listTitles.failed:
      return Object.assign({}, { fetching: false, error: action.data });

    default:
      return state;
  }
}
