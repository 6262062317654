export default function VolumeIndicator({ classNames, level }) {
  return (
    <div className={classNames.VolumeIndicator}>
      <div
        className={
          level > 0
            ? classNames.VolumeIndicator__active
            : classNames.VolumeIndicator__notActive
        }
      ></div>
      <div
        className={
          level > 20
            ? classNames.VolumeIndicator__active
            : classNames.VolumeIndicator__notActive
        }
      ></div>
      <div
        className={
          level > 40
            ? classNames.VolumeIndicator__active
            : classNames.VolumeIndicator__notActive
        }
      ></div>
      <div
        className={
          level > 60
            ? classNames.VolumeIndicator__active
            : classNames.VolumeIndicator__notActive
        }
      ></div>
      <div
        className={
          level > 80
            ? classNames.VolumeIndicator__active
            : classNames.VolumeIndicator__notActive
        }
      ></div>
    </div>
  );
}
