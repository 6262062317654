import api from "../../../../api";
import types from "../../../types";
import { generateSyncAction } from "../../../utils.redux";

export default function createCandidateTestAC(data) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.candidateTest.create.started));

    try {
      const result = await api.candidateTest.create(data);
      dispatch(
        generateSyncAction(types.candidateTest.create.success, result.data)
      );
      dispatch(generateSyncAction(types.reset.createCandidateTest));
    } catch (error) {
      dispatch(generateSyncAction(types.candidateTest.create.failed, error));
      dispatch(generateSyncAction(types.reset.createCandidateTest));
    }
  };
}
