import types from "../../types";

export default function candidates(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.candidates.list.started:
      return Object.assign({}, { fetching: true });

    case types.candidates.list.success:
      return Object.assign(
        {},
        { fetching: false, data: { ...action.data.data.getCandidates } }
      );

    case types.candidates.list.failed:
      return Object.assign({}, { fetching: false, error: action.data.data });

    default:
      return state;
  }
}
