import types from "../../types";
import { generateSyncAction } from "../../utils.redux";
import API from "../../../api";

export default function getJob(jobId) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.jobs.get.started));

    try {
      const data = await API.jobs.get(jobId);
      dispatch(generateSyncAction(types.jobs.get.success, data));
    } catch (error) {
      dispatch(generateSyncAction(types.jobs.get.failed, error));
    }
  };
}
