import types from "../../types";
import { generateSyncAction } from "../../utils.redux";
import API from "../../../api";
import matchNotifyEmail from "../../../App/common/utils/matchNotifyEmail";

export default function createOnboardingProfiles(inputs) {
  return async function (dispatch, getState) {
    dispatch(generateSyncAction(types.user.completeUserProfile.started));

    try {
      const company = getState()?.user?.data?.company;

      API.message.send({
        to: `${matchNotifyEmail(process.env.REACT_APP_ENV)}`,
        from: `hello@hellomolly.io`,
        sender: "Molly",
        subject: `We have a new signup`,
        message: `${JSON.stringify(company, " ", "\n")}`,
        html: `${JSON.stringify(company, " ", "\n")}`,
        managerEmail: `${matchNotifyEmail(process.env.REACT_APP_ENV)}`,
      });
      const data = await API.user.createOnboardingProfiles(inputs);
      dispatch(
        generateSyncAction(types.user.completeUserProfile.success, data)
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.user.completeUserProfile.failed, error)
      );
    }
  };
}
