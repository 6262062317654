import { Skeleton, Row } from "antd";
import classNames from "./SkillTestSkeleton.module.scss";

const SkillTestSkeleton = () => {
  const questions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div className={classNames.wrapper}>
      <div className={classNames.header}>
        <div>
          <Skeleton.Avatar
            active={true}
            shape="square"
            style={{
              borderRadius: 5,
              width: 200,
              height: 30,
              marginRight: 15,
            }}
          />
          <Skeleton.Avatar
            active={true}
            shape="square"
            style={{
              borderRadius: 5,
              width: 200,
              height: 30,
              marginRight: 100,
            }}
          />
        </div>
        <div>
          <Skeleton.Avatar
            active={true}
            shape="square"
            style={{
              borderRadius: 5,
              width: 230,
              height: 40,
            }}
          />
        </div>
        <div>
          <Skeleton.Avatar
            active={true}
            shape="square"
            style={{
              borderRadius: 5,
              width: 250,
              height: 30,
              marginLeft: 50,
            }}
          />
          <Skeleton.Button
            active={true}
            shape="square"
            style={{
              borderRadius: 5,
              width: 150,
              height: 50,
              marginLeft: 20,
            }}
          />
        </div>
      </div>
      <div className={classNames.body}>
        <div className={classNames.sidebar}>
          {questions.map((key) => (
            <Row>
              <Skeleton.Button
                key={key}
                active={true}
                shape="square"
                style={{
                  borderRadius: 5,
                  width: 243,
                  height: 30,
                  marginBottom: 15,
                }}
              />
            </Row>
          ))}
        </div>
        <div className={classNames.content}>
          <Row>
            <Skeleton.Avatar
              active={true}
              shape="square"
              style={{
                borderRadius: 5,
                width: 600,
                height: 200,
                marginBottom: 50,
              }}
            />
          </Row>
          <Row>
            <Skeleton.Avatar
              active={true}
              shape="square"
              style={{
                borderRadius: 5,
                width: 600,
                height: 250,
                marginBottom: 50,
              }}
            />
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SkillTestSkeleton;
