import types from "../../types";

export default function resetPasswordReducer(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.userManagement.resetPassword.started:
      return Object.assign({}, { fetching: true });

    case types.userManagement.resetPassword.success:
      return Object.assign({}, { fetching: false, resetted: true });

    case types.userManagement.resetPassword.failed:
      return Object.assign(
        {},
        {
          fetching: false,
          resetted: false,
          failed: true,
          error: [action.data],
        },
        { error: [action.data] }
      );

    default:
      return state;
  }
}
