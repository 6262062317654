import classNames from "./MCQ.module.scss";
import Title from "../../Title";

type Props = {
  hint: string;
  expectedAnswers: string;
  title: string;
  candidateAnswer: string;
  noTitle?: boolean;
};

const MCQ = ({ expectedAnswers, title, noTitle, candidateAnswer }: Props) => {
  return (
    <div>
      <div className={classNames.wrapper}>
        <div>
          <Title as="h4" style={{ fontSize: "13px", opacity: "0.5" }}>
            Expected Answers
          </Title>
          <span>{expectedAnswers}</span>
        </div>
        {candidateAnswer ? (
          <div>
            <Title as="h4" style={{ fontSize: "13px", opacity: "0.5" }}>
              Candidate Answers
            </Title>
            <span>{candidateAnswer}</span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default MCQ;
