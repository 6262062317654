import { AgoraVideoPlayer } from "agora-rtc-react";

function ScreenShareFeed({ classNames, filteredUsers }) {
  return (
    <div className={classNames.screenWrapper}>
      {filteredUsers?.map((user) => {
        if (user.videoTrack) {
          return (
            <AgoraVideoPlayer
              style={{ height: "100%", width: "100%" }}
              className="candidate-screen"
              videoTrack={user.videoTrack}
              key={user.uid}
            />
          );
        } else return null;
      })}
    </div>
  );
}

export default ScreenShareFeed;
