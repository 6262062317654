import React from "react";
import { createAvatar } from "@dicebear/avatars";
import * as avatarStyle from "@dicebear/avatars-initials-sprites";
import classNames from "./Avatar.module.scss";

export default function Avatar({
  className,
  imageUrl,
  name,
  size,
  style,
  ...props
}) {
  let avatarDataUri = createAvatar(avatarStyle, {
    seed: name,
    size,
    dataUri: true,
  });

  return (
    <img
      src={imageUrl || avatarDataUri}
      {...props}
      style={{
        objectFit: "cover",
        ...style,
      }}
      alt={name}
      width={size}
      height={size}
      className={`${className} ${classNames.img}`}
    />
  );
}
