const currencyList = [
  {
    id: "AFN",
    name: "afghani",
    title: "Afghani",
  },
  {
    id: "ALL",
    name: "lek",
    title: "Lek",
  },
  {
    id: "DZD",
    name: "algerian-dinar",
    title: "Algerian Dinar",
  },
  {
    id: "AOA",
    name: "kwanza",
    title: "Kwanza",
  },
  {
    id: "XCD",
    name: "east-caribbean-dollar",
    title: "East Caribbean Dollar",
  },
  {
    id: "ARS",
    name: "argentine-peso",
    title: "Argentine Peso",
  },
  {
    id: "AMD",
    name: "armenian-dram",
    title: "Armenian Dram",
  },
  {
    id: "AWG",
    name: "aruban-florin",
    title: "Aruban Florin",
  },
  {
    id: "AUD",
    name: "australian-dollar",
    title: "Australian Dollar",
  },
  {
    id: "AZN",
    name: "azerbaijanian-manat",
    title: "Azerbaijanian Manat",
  },
  {
    id: "BSD",
    name: "bahamian-dollar",
    title: "Bahamian Dollar",
  },
  {
    id: "BHD",
    name: "bahraini-dinar",
    title: "Bahraini Dinar",
  },
  {
    id: "BDT",
    name: "taka",
    title: "Taka",
  },
  {
    id: "BBD",
    name: "barbados-dollar",
    title: "Barbados Dollar",
  },
  {
    id: "BYN",
    name: "belarussian-ruble",
    title: "Belarussian Ruble",
  },
  {
    id: "BZD",
    name: "belize-dollar",
    title: "Belize Dollar",
  },
  {
    id: "XOF",
    name: "cfa-franc-bceao",
    title: "CFA Franc BCEAO",
  },
  {
    id: "BMD",
    name: "bermudian-dollar",
    title: "Bermudian Dollar",
  },
  {
    id: "BTN",
    name: "ngultrum",
    title: "Ngultrum",
  },
  {
    id: "INR",
    name: "indian-rupee",
    title: "Indian Rupee",
  },
  {
    id: "BOB",
    name: "boliviano",
    title: "Boliviano",
  },
  {
    id: "BOV",
    name: "mvdol",
    title: "Mvdol",
  },
  {
    id: "USD",
    name: "us-dollar",
    title: "US Dollar",
  },
  {
    id: "BAM",
    name: "convertible-mark",
    title: "Convertible Mark",
  },
  {
    id: "BWP",
    name: "pula",
    title: "Pula",
  },
  {
    id: "NOK",
    name: "norwegian-krone",
    title: "Norwegian Krone",
  },
  {
    id: "BRL",
    name: "brazilian-real",
    title: "Brazilian Real",
  },
  {
    id: "BND",
    name: "brunei-dollar",
    title: "Brunei Dollar",
  },
  {
    id: "BGN",
    name: "bulgarian-lev",
    title: "Bulgarian Lev",
  },
  {
    id: "BIF",
    name: "burundi-franc",
    title: "Burundi Franc",
  },
  {
    id: "CVE",
    name: "cabo-verde-escudo",
    title: "Cabo Verde Escudo",
  },
  {
    id: "KHR",
    name: "riel",
    title: "Riel",
  },
  {
    id: "XAF",
    name: "cfa-franc-beac",
    title: "CFA Franc BEAC",
  },
  {
    id: "CAD",
    name: "canadian-dollar",
    title: "Canadian Dollar",
  },
  {
    id: "KYD",
    name: "cayman-islands-dollar",
    title: "Cayman Islands Dollar",
  },
  {
    id: "CLF",
    name: "unidad-de-fomento",
    title: "Unidad de Fomento",
  },
  {
    id: "CLP",
    name: "chilean-peso",
    title: "Chilean Peso",
  },
  {
    id: "CNY",
    name: "yuan-renminbi",
    title: "Yuan Renminbi",
  },
  {
    id: "COP",
    name: "colombian-peso",
    title: "Colombian Peso",
  },
  {
    id: "COU",
    name: "unidad-de-valor-real",
    title: "Unidad de Valor Real",
  },
  {
    id: "KMF",
    name: "comoro-franc",
    title: "Comoro Franc",
  },
  {
    id: "CDF",
    name: "congolese-franc",
    title: "Congolese Franc",
  },
  {
    id: "NZD",
    name: "new-zealand-dollar",
    title: "New Zealand Dollar",
  },
  {
    id: "CRC",
    name: "costa-rican-colon",
    title: "Costa Rican Colon",
  },
  {
    id: "HRK",
    name: "kuna",
    title: "Kuna",
  },
  {
    id: "CUC",
    name: "peso-convertible",
    title: "Peso Convertible",
  },
  {
    id: "CUP",
    name: "cuban-peso",
    title: "Cuban Peso",
  },
  {
    id: "ANG",
    name: "netherlands-antillean-guilder",
    title: "Netherlands Antillean Guilder",
  },
  {
    id: "EUR",
    name: "euro",
    title: "Euro",
  },
  {
    id: "CZK",
    name: "czech-koruna",
    title: "Czech Koruna",
  },
  {
    id: "DKK",
    name: "danish-krone",
    title: "Danish Krone",
  },
  {
    id: "DJF",
    name: "djibouti-franc",
    title: "Djibouti Franc",
  },
  {
    id: "DOP",
    name: "dominican-peso",
    title: "Dominican Peso",
  },
  {
    id: "EGP",
    name: "egyptian-pound",
    title: "Egyptian Pound",
  },
  {
    id: "SVC",
    name: "el-salvador-colon",
    title: "El Salvador Colon",
  },
  {
    id: "ERN",
    name: "nakfa",
    title: "Nakfa",
  },
  {
    id: "ETB",
    name: "ethiopian-birr",
    title: "Ethiopian Birr",
  },
  {
    id: "FKP",
    name: "falkland-islands-pound",
    title: "Falkland Islands Pound",
  },
  {
    id: "FJD",
    name: "fiji-dollar",
    title: "Fiji Dollar",
  },
  {
    id: "XPF",
    name: "cfp-franc",
    title: "CFP Franc",
  },
  {
    id: "GMD",
    name: "dalasi",
    title: "Dalasi",
  },
  {
    id: "GEL",
    name: "lari",
    title: "Lari",
  },
  {
    id: "GHS",
    name: "ghana-cedi",
    title: "Ghana Cedi",
  },
  {
    id: "GIP",
    name: "gibraltar-pound",
    title: "Gibraltar Pound",
  },
  {
    id: "GTQ",
    name: "quetzal",
    title: "Quetzal",
  },
  {
    id: "GBP",
    name: "pound-sterling",
    title: "Pound Sterling",
  },
  {
    id: "GNF",
    name: "guinea-franc",
    title: "Guinea Franc",
  },
  {
    id: "GYD",
    name: "guyana-dollar",
    title: "Guyana Dollar",
  },
  {
    id: "HTG",
    name: "gourde",
    title: "Gourde",
  },
  {
    id: "HNL",
    name: "lempira",
    title: "Lempira",
  },
  {
    id: "HKD",
    name: "hong-kong-dollar",
    title: "Hong Kong Dollar",
  },
  {
    id: "HUF",
    name: "forint",
    title: "Forint",
  },
  {
    id: "ISK",
    name: "iceland-krona",
    title: "Iceland Krona",
  },
  {
    id: "IDR",
    name: "rupiah",
    title: "Rupiah",
  },
  {
    id: "XDR",
    name: "sdr-(special-drawing-right)",
    title: "SDR (Special Drawing Right)",
  },
  {
    id: "IRR",
    name: "iranian-rial",
    title: "Iranian Rial",
  },
  {
    id: "IQD",
    name: "iraqi-dinar",
    title: "Iraqi Dinar",
  },
  {
    id: "ILS",
    name: "new-israeli-sheqel",
    title: "New Israeli Sheqel",
  },
  {
    id: "JMD",
    name: "jamaican-dollar",
    title: "Jamaican Dollar",
  },
  {
    id: "JPY",
    name: "yen",
    title: "Yen",
  },
  {
    id: "JOD",
    name: "jordanian-dinar",
    title: "Jordanian Dinar",
  },
  {
    id: "KZT",
    name: "tenge",
    title: "Tenge",
  },
  {
    id: "KES",
    name: "kenyan-shilling",
    title: "Kenyan Shilling",
  },
  {
    id: "KPW",
    name: "north-korean-won",
    title: "North Korean Won",
  },
  {
    id: "KRW",
    name: "won",
    title: "Won",
  },
  {
    id: "KWD",
    name: "kuwaiti-dinar",
    title: "Kuwaiti Dinar",
  },
  {
    id: "KGS",
    name: "som",
    title: "Som",
  },
  {
    id: "LAK",
    name: "kip",
    title: "Kip",
  },
  {
    id: "LBP",
    name: "lebanese-pound",
    title: "Lebanese Pound",
  },
  {
    id: "LSL",
    name: "loti",
    title: "Loti",
  },
  {
    id: "ZAR",
    name: "rand",
    title: "Rand",
  },
  {
    id: "LRD",
    name: "liberian-dollar",
    title: "Liberian Dollar",
  },
  {
    id: "LYD",
    name: "libyan-dinar",
    title: "Libyan Dinar",
  },
  {
    id: "CHF",
    name: "swiss-franc",
    title: "Swiss Franc",
  },
  {
    id: "MOP",
    name: "pataca",
    title: "Pataca",
  },
  {
    id: "MGA",
    name: "malagasy-ariary",
    title: "Malagasy Ariary",
  },
  {
    id: "MWK",
    name: "kwacha",
    title: "Kwacha",
  },
  {
    id: "MYR",
    name: "malaysian-ringgit",
    title: "Malaysian Ringgit",
  },
  {
    id: "MVR",
    name: "rufiyaa",
    title: "Rufiyaa",
  },
  {
    id: "MRU",
    name: "ouguiya",
    title: "Ouguiya",
  },
  {
    id: "MUR",
    name: "mauritius-rupee",
    title: "Mauritius Rupee",
  },
  {
    id: "XUA",
    name: "adb-unit-of-account",
    title: "ADB Unit of Account",
  },
  {
    id: "MXN",
    name: "mexican-peso",
    title: "Mexican Peso",
  },
  {
    id: "MXV",
    name: "mexican-unidad-de-inversion-(udi)",
    title: "Mexican Unidad de Inversion (UDI)",
  },
  {
    id: "MDL",
    name: "moldovan-leu",
    title: "Moldovan Leu",
  },
  {
    id: "MNT",
    name: "tugrik",
    title: "Tugrik",
  },
  {
    id: "MAD",
    name: "moroccan-dirham",
    title: "Moroccan Dirham",
  },
  {
    id: "MZN",
    name: "mozambique-metical",
    title: "Mozambique Metical",
  },
  {
    id: "MMK",
    name: "kyat",
    title: "Kyat",
  },
  {
    id: "NAD",
    name: "namibia-dollar",
    title: "Namibia Dollar",
  },
  {
    id: "NPR",
    name: "nepalese-rupee",
    title: "Nepalese Rupee",
  },
  {
    id: "NIO",
    name: "cordoba-oro",
    title: "Cordoba Oro",
  },
  {
    id: "NGN",
    name: "naira",
    title: "Naira",
  },
  {
    id: "OMR",
    name: "rial-omani",
    title: "Rial Omani",
  },
  {
    id: "PKR",
    name: "pakistan-rupee",
    title: "Pakistan Rupee",
  },
  {
    id: "PAB",
    name: "balboa",
    title: "Balboa",
  },
  {
    id: "PGK",
    name: "kina",
    title: "Kina",
  },
  {
    id: "PYG",
    name: "guarani",
    title: "Guarani",
  },
  {
    id: "PEN",
    name: "nuevo-sol",
    title: "Nuevo Sol",
  },
  {
    id: "PHP",
    name: "philippine-peso",
    title: "Philippine Peso",
  },
  {
    id: "PLN",
    name: "zloty",
    title: "Zloty",
  },
  {
    id: "QAR",
    name: "qatari-rial",
    title: "Qatari Rial",
  },
  {
    id: "MKD",
    name: "denar",
    title: "Denar",
  },
  {
    id: "RON",
    name: "romanian-leu",
    title: "Romanian Leu",
  },
  {
    id: "RUB",
    name: "russian-ruble",
    title: "Russian Ruble",
  },
  {
    id: "RWF",
    name: "rwanda-franc",
    title: "Rwanda Franc",
  },
  {
    id: "SHP",
    name: "saint-helena-pound",
    title: "Saint Helena Pound",
  },
  {
    id: "WST",
    name: "tala",
    title: "Tala",
  },
  {
    id: "STN",
    name: "dobra",
    title: "Dobra",
  },
  {
    id: "SAR",
    name: "saudi-riyal",
    title: "Saudi Riyal",
  },
  {
    id: "RSD",
    name: "serbian-dinar",
    title: "Serbian Dinar",
  },
  {
    id: "SCR",
    name: "seychelles-rupee",
    title: "Seychelles Rupee",
  },
  {
    id: "SLL",
    name: "leone",
    title: "Leone",
  },
  {
    id: "SGD",
    name: "singapore-dollar",
    title: "Singapore Dollar",
  },
  {
    id: "XSU",
    name: "sucre",
    title: "Sucre",
  },
  {
    id: "SBD",
    name: "solomon-islands-dollar",
    title: "Solomon Islands Dollar",
  },
  {
    id: "SOS",
    name: "somali-shilling",
    title: "Somali Shilling",
  },
  {
    id: "SSP",
    name: "south-sudanese-pound",
    title: "South Sudanese Pound",
  },
  {
    id: "LKR",
    name: "sri-lanka-rupee",
    title: "Sri Lanka Rupee",
  },
  {
    id: "SDG",
    name: "sudanese-pound",
    title: "Sudanese Pound",
  },
  {
    id: "SRD",
    name: "surinam-dollar",
    title: "Surinam Dollar",
  },
  {
    id: "SZL",
    name: "lilangeni",
    title: "Lilangeni",
  },
  {
    id: "SEK",
    name: "swedish-krona",
    title: "Swedish Krona",
  },
  {
    id: "CHW",
    name: "wir-franc",
    title: "WIR Franc",
  },
  {
    id: "SYP",
    name: "syrian-pound",
    title: "Syrian Pound",
  },
  {
    id: "TWD",
    name: "new-taiwan-dollar",
    title: "New Taiwan Dollar",
  },
  {
    id: "TJS",
    name: "somoni",
    title: "Somoni",
  },
  {
    id: "TZS",
    name: "tanzanian-shilling",
    title: "Tanzanian Shilling",
  },
  {
    id: "THB",
    name: "baht",
    title: "Baht",
  },
  {
    id: "TOP",
    name: "pa’anga",
    title: "Pa’anga",
  },
  {
    id: "TTD",
    name: "trinidad-and-tobago-dollar",
    title: "Trinidad and Tobago Dollar",
  },
  {
    id: "TND",
    name: "tunisian-dinar",
    title: "Tunisian Dinar",
  },
  {
    id: "TRY",
    name: "turkish-lira",
    title: "Turkish Lira",
  },
  {
    id: "TMT",
    name: "turkmenistan-new-manat",
    title: "Turkmenistan New Manat",
  },
  {
    id: "UGX",
    name: "uganda-shilling",
    title: "Uganda Shilling",
  },
  {
    id: "UAH",
    name: "hryvnia",
    title: "Hryvnia",
  },
  {
    id: "AED",
    name: "uae-dirham",
    title: "UAE Dirham",
  },
  {
    id: "USN",
    name: "us-dollar-(next-day)",
    title: "US Dollar (Next day)",
  },
  {
    id: "UYI",
    name: "uruguay-peso-en-unidades-indexadas-(uruiurui)",
    title: "Uruguay Peso en Unidades Indexadas (URUIURUI)",
  },
  {
    id: "UYU",
    name: "peso-uruguayo",
    title: "Peso Uruguayo",
  },
  {
    id: "UZS",
    name: "uzbekistan-sum",
    title: "Uzbekistan Sum",
  },
  {
    id: "VUV",
    name: "vatu",
    title: "Vatu",
  },
  {
    id: "VEF",
    name: "bolivar",
    title: "Bolivar",
  },
  {
    id: "VND",
    name: "dong",
    title: "Dong",
  },
  {
    id: "YER",
    name: "yemeni-rial",
    title: "Yemeni Rial",
  },
  {
    id: "ZMW",
    name: "zambian-kwacha",
    title: "Zambian Kwacha",
  },
  {
    id: "ZWL",
    name: "zimbabwe-dollar",
    title: "Zimbabwe Dollar",
  },
];

export default currencyList;
