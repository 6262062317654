import { useState, useEffect } from "react";
import classNames from "./EditorOutput.module.scss";
import { PrimaryButton } from "../../index";
import { Button, Input, Form, Tag, message } from "antd";
import runCommandDefault from "../runCommand.constants";

import { SettingOutlined } from "@ant-design/icons";
import CODE_EDITOR_TYPES from "../editor.constants";

const { output, header, ioButtons, complieOptions, settings } = classNames;

const INPUT = "input",
  OUTPUT = "output",
  SETTINGS = "settings";

const OUTPUT_ERROR = ["stdout", "stderr", "error"];
const OUTPUT_COLOR = {
  stdout: "#43A047",
  error: "#D32F2F",
  stderr: "#FFB300",
  info: "#448AFF",
};

export default function EditorOutput({
  data,
  runCode,
  language,
  runCmd,
  setRunCmd,
  setStdIn,
  questionId,
  type,
}) {
  const [toggle, setToggle] = useState(INPUT);
  const [error, setError] = useState({
    showError: true,
    message: "",
  });

  useEffect(() => {
    if (data?.data) {
      const keys = Object.keys(data?.data?.data);
      const uniqKeys = new Set(keys);
      if (![...uniqKeys].every((e) => OUTPUT_ERROR.includes(e))) {
        const messageValue = uniqKeys.has("message")
          ? data?.data?.data["message"] ?? "Someting went wrong"
          : `Something went wrong`;
        message.error(messageValue);
        return setError({
          showError: true,
          message: messageValue,
        });
      }
      setError({
        showError: false,
        message: "",
      });
    }

    if (data?.fetching) {
      if (
        type === CODE_EDITOR_TYPES.INDIVIDUAL ||
        (questionId && questionId === data?.questionId)
      )
        setToggle(OUTPUT);
    }
  }, [data, questionId, type]);

  useEffect(() => {
    toggle === SETTINGS &&
      runCmd === "" &&
      setRunCmd(runCommandDefault(language));
  }, [toggle, runCmd, language, setRunCmd]);

  useEffect(() => {
    return () => {
      setRunCmd("");
    };
  }, [language, setRunCmd]);

  useEffect(() => {
    return () => {
      setToggle(INPUT);
      window.scrollTo(0, 0);
    };
  }, [questionId]);

  const toggleStyles = (state) => {
    return {
      color: toggle === state ? "#fff" : "#bfbfbf64",
      height: state === SETTINGS ? "45px" : "50px",
      borderTop: toggle === state ? "1px solid #fff" : "0",
    };
  };

  const renderOutputTab = () => {
    return (
      <>
        {data?.fetching && (
          <>
            <Tag color={OUTPUT_COLOR.info} className={classNames.response}>
              Info
            </Tag>

            <p>{data?.fetching && <p>{"Running..."}</p>}</p>
          </>
        )}
        {!data?.fetching &&
          data?.data &&
          !error.showError &&
          OUTPUT_ERROR.map((e) => (
            <>
              {data?.data?.data[e] !== "" && (
                <>
                  <Tag color={OUTPUT_COLOR[e]} className={classNames.response}>
                    {`${e.charAt(0).toUpperCase()}${e.slice(1)}`}
                  </Tag>

                  <div className={classNames.outputResponse}>
                    <pre>{data?.data?.data[e]}</pre>
                  </div>
                </>
              )}
            </>
          ))}
      </>
    );
  };

  return (
    <>
      <div className={header}>
        <div className={ioButtons}>
          <Button
            onClick={() => setToggle(INPUT)}
            type="text"
            style={toggleStyles(INPUT)}
          >
            INPUT
          </Button>
          <Button
            onClick={() => setToggle(OUTPUT)}
            type="text"
            style={toggleStyles(OUTPUT)}
          >
            OUTPUT
          </Button>
        </div>
        <div className={complieOptions}>
          <Button
            onClick={() => setToggle(SETTINGS)}
            type="text"
            style={toggleStyles(SETTINGS)}
          >
            <SettingOutlined /> Run Settings
          </Button>
          <PrimaryButton
            type="primary"
            style={{
              width: "auto",
              fontWeight: "bold",
              height: "35px",
              margin: "10px 10px 0 35px",
            }}
            onClick={() => {
              runCode();
            }}
          >
            Run Code
          </PrimaryButton>
        </div>
      </div>
      <div className={output}>
        {toggle === INPUT ? (
          <Form.Item rules={[{ max: 500, message: "Max 500 characters" }]}>
            <Input.TextArea
              rows={10}
              spellCheck="false"
              placeholder="Text entered here will be sent to stdin..."
              onChange={(e) => setStdIn(e.target.value)}
              style={{
                backgroundColor: "transparent",
                height: "110px",
                width: "90%",
                border: "none",
                color: "#878b92",
                display: "flex",
                flexDirection: "column",
              }}
            />
          </Form.Item>
        ) : toggle === OUTPUT ? (
          type === CODE_EDITOR_TYPES.INDIVIDUAL ? (
            renderOutputTab()
          ) : questionId && questionId === data?.questionId ? (
            renderOutputTab()
          ) : null
        ) : (
          <div className={settings}>
            <div>
              <p>Language</p>
              <p>{language.charAt(0).toUpperCase() + language.slice(1)}</p>
            </div>
            <div>
              <p>Run Command</p>
              <p>
                <Input
                  spellCheck="false"
                  value={runCmd}
                  onChange={(e) => setRunCmd(e.target.value)}
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #878b92",
                    color: "#878b92",
                    width: "300px",
                  }}
                />
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
