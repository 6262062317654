import types from "../../types";
import API from "../../../api";
import { generateSyncAction } from "../../utils.redux";

export default function getSKBySkillTestId(id) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.skillTest.getById.started));

    try {
      const data = await API.skillTest.getById(id);
      dispatch(generateSyncAction(types.skillTest.getById.success, data.data));
    } catch (error) {
      dispatch(generateSyncAction(types.skillTest.getById.failed, error));
    }
  };
}
