import endpoints from "../config";

export default async function createQuestion(data) {
  return await (
    await fetch(endpoints.QUESTION[process.env.REACT_APP_ENV], {
      method: "POST",
      body: JSON.stringify(data),
    })
  ).json();
}
