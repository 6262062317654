import types from "../../types";

export default function createFeedback(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.feedback.createFeedback.started:
      return Object.assign({}, { fetching: true });

    case types.feedback.createFeedback.success:
      return Object.assign(
        {},
        { fetching: false, created: true, data: action.data }
      );

    case types.reset.createFeedback:
      return Object.assign({}, { ...state }, { created: false });

    case types.feedback.createFeedback.failed:
      return Object.assign(
        {},
        { fetching: false, created: false, error: action.data }
      );

    default:
      return state;
  }
}
