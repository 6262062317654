import types from "../../types";
import { generateSyncAction } from "../../utils.redux";
import API from "../../../api";

export default function createOnboardingProfiles(inputs) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.company.updateCompanyProfile.started));

    try {
      const data = await API.company.update(inputs);
      dispatch(
        generateSyncAction(types.company.updateCompanyProfile.success, data)
      );
      dispatch(generateSyncAction(types.reset.updateCompanyprofile));
    } catch (error) {
      dispatch(
        generateSyncAction(types.company.updateCompanyProfile.failed, error)
      );
    }
  };
}
