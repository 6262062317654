const messages = {
  firstName: "First name is required.",
  lastName: "Last name is required.",
  companyName: "Company name is required.",
  companyEmail: "Email is required.",
  companyWebsite: "Website is required.",
  companyPhone: "Phone number is required.",
  companyAddress: "Address is required.",
  country: "Country is required.",
  companyIndutry: "Industry is required.",
  currentStage: "Current stage is required",
  companySize: "Company size is required.",
};

export default messages;
