import types from "../../types";

export default function activateMemberAccount(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.user.activateMemberAccount.started:
      return Object.assign({}, { fetching: true, activated: false });

    case types.user.activateMemberAccount.success:
      return Object.assign(
        {},
        { fetching: false, data: { ...action.data.data }, activated: true }
      );

    case types.user.activateMemberAccount.failed:
      return Object.assign(
        {},
        { fetching: false, error: { ...action.data }, activated: false }
      );

    default:
      return state;
  }
}
