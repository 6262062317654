import ReactStopwatch from "react-stopwatch";
import classNames from "../Header.module.scss";
import { memo } from "react";

const Completed = memo(({ isCompleted }) => {
  return (
    <span>
      <ReactStopwatch
        seconds={0}
        minutes={0}
        hours={0}
        render={({ formatted }) => {
          return (
            <div style={{ display: "flex", color: "red" }}>+ {formatted}</div> //need to test this
          );
        }}
      />
    </span>
  );
});

export default function renderer({ hours, minutes, seconds, completed }) {
  if (completed) {
    return (
      <div className={classNames.timer}>
        <Completed isCompleted={completed} />
      </div>
    );
  } else {
    return (
      <div className={classNames.timer}>
        {String(hours).length === 1 ? `0${hours}` : `${hours}`}:
        {String(minutes).length === 1 ? `0${minutes}` : `${minutes}`}:
        {String(seconds).length === 1 ? `0${seconds}` : `${seconds}`}
      </div>
    );
  }
}
