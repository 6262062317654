import { API } from "aws-amplify";
import { listInterviewsByCandidateId } from "../../graphql/queries";

export default async function fetchInterviewsByCandidate(candidateId) {
  return await API.graphql({
    query: listInterviewsByCandidateId,
    variables: {
      candidateId,
    },
  });
}
