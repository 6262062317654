export default function filterCandidates(
  testId,
  companyId,
  jobId,
  candidateId,
  candidates
) {
  return candidates
    .filter(
      (candidate) =>
        candidate.companyId === companyId &&
        candidate.jobId === jobId &&
        candidate.candidateTests.includes(testId) &&
        candidate.id === candidateId
    )
    .map((candidate) => candidate.firstName + " " + candidate.lastName);
}
