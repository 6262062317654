import types from "../../types";
import { generateSyncAction } from "../../utils.redux";
import api from "../../../api";

export default function listExercises() {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.codeEditor.listExercises.started));
    try {
      const data = await api.codeEditor.list();
      dispatch(
        generateSyncAction(types.codeEditor.listExercises.success, data)
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.codeEditor.listExercises.failed, error)
      );
    }
  };
}
