const config = {
  email: {
    valid: "Please enter a valid email.",
    required: "Email is required",
  },
  password: {
    required: "Password field is required",
    minLength: "Password should contain at least 8 characters",
    regex:
      "Insecure password. Password must contain at least one of simple or capital letters, numbers, and symbols",
  },
  confirmPassword: {
    required: "Please confirm your password.",
    match: "The two passwords that you entered do not match!",
  },
};

export default config;
