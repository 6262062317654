import endpoints from "../../config";

export default async function rateCandidateTestResult(data) {
  const { id, questionId, rate, questionsCount } = data;
  const url = `${
    endpoints.CANDIDATE_TEST[process.env.REACT_APP_ENV]
  }/rateResult?id=${id}`;

  const reqData = {
    questionId: `${questionId}`,
    rate: `${rate}`,
    questionsCount: `${questionsCount}`,
  };
  return await fetch(url, {
    method: "PUT",
    body: JSON.stringify(reqData),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => console.log("error: ", err));
}
