import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "antd";

import Editor from "../../common/CodeEditor";
import { Select, Title } from "../../common";
import ac from "../../../redux/actions";
import CODE_EDITOR_TYPES from "../../common/CodeEditor/editor.constants";
import { progLanguages } from "./programmingLanguages";

function CodingExercise({
  codingLanguage,
  initialLan,
  files,
  onChange,
  formIns,
  originalFiles,
  questionId,
}) {
  const dispatch = useDispatch();

  const [runCmd, setRunCmd] = useState("");
  const [stdIn, setStdIn] = useState("");
  const [language, setLanguage] = useState(codingLanguage);
  const [displayCodeEditor, setDisplayCodeEditor] = useState(false);
  const [candidateSelectedLan, setCandidateSelectedLan] = useState(null);

  const languages = useSelector(({ getLanguages }) =>
    getLanguages?.data ? getLanguages?.data?.data : undefined
  );

  const question = useSelector(({ listQuestionsByIds }) => {
    return listQuestionsByIds
      ? listQuestionsByIds?.data?.find(
          (question) => question?.id === questionId
        )
      : [];
  });
  initialLan = question.initialLan;
  const toggleEditorDisplay = () => {
    /**
     * toggleEditorDisplay() triggers when the coding
     * question doesnt have pre selected langugage
     * or after non-pre selected coding question answered
     * by the candidate
     * This updates redux store when user starts over the
     * respective question
     * initialLan prop will be true for coding questions
     * with pre selected languages
     */
    if (question.initialLan) {
      /**
       * Comment below line as a hotfix
       * for a behavioral issue
       * Issue: User click start over button on coding question
       * with out submitting. But the question in the question panel
       * displays as submitted
       */
      // dispatch(
      //   ac.mapAnswersWithQuestionTemp({
      //     option: [],
      //     questionId,
      //     candidateSelectedLang: question.initialLan,
      //   })
      // );
      formIns.setFieldValue("language", question.initialLan);
      setCandidateSelectedLan(question.initialLan);
      setDisplayCodeEditor(true);
      setLanguage(question.initialLan);
      return;
    }
    /**
     * Below functions trigger when the coding
     * question doesnt have pre selected langugage
     * This updates redux store when user starts over the
     * respective question
     */
    /**
     * Comment below line as a hotfix
     * for a behavioral issue
     * Issue: User click start over button on coding question
     * with out submitting. But the question in the question panel
     * displays as submitted
     */
    // dispatch(
    //   ac.mapAnswersWithQuestionTemp({
    //     option: [],
    //     questionId,
    //     candidateSelectedLang: initialLan,
    //   })
    // );
    formIns.setFieldValue("language", initialLan);
    setCandidateSelectedLan(null);
    setDisplayCodeEditor(false);
    setLanguage(initialLan);
    formIns.resetFields();
    if (!originalFiles || originalFiles.files?.length === 0) {
      /**
       * Comment below line as a hotfix
       * for a behavioral issue
       * Issue: User click start over button on coding question with
       * non pre selected language before submitting the answer.
       * After submits the displayCodeEditor variable was
       * true due to the below setter
       */
      // setDisplayCodeEditor((prev) => !prev);
      setCandidateSelectedLan(null);
      formIns.resetFields(["language"]);
    }
    onChange(originalFiles);
  };

  useEffect(() => {
    dispatch(ac.getLanguages());
  }, [dispatch]);

  useEffect(() => {
    setCandidateSelectedLan(null);
    setDisplayCodeEditor(false);
    setLanguage(codingLanguage);
    formIns.resetFields();
  }, [codingLanguage, questionId, formIns]);
  return (
    <>
      {language && !candidateSelectedLan ? (
        <>
          <Editor
            type={
              initialLan
                ? CODE_EDITOR_TYPES.LANGUAGE_LOCKED
                : CODE_EDITOR_TYPES.LANGUAGE_UNLOCKED
            }
            codingLanguage={codingLanguage ?? language}
            codingQuestion={files}
            setCodingQuestion={onChange}
            runCmd={runCmd}
            setRunCmd={setRunCmd}
            stdIn={stdIn}
            setStdIn={setStdIn}
            toggleEditorDisplay={toggleEditorDisplay}
            setCandidateSelectedLan={!initialLan && setCandidateSelectedLan}
            setDisplayCodeEditor={!initialLan && setDisplayCodeEditor}
            questionId={questionId}
          />
        </>
      ) : !candidateSelectedLan && !displayCodeEditor ? (
        <Form
          form={formIns}
          layout="vertical"
          requiredMark={false}
          style={{ marginTop: "15px" }}
        >
          <Form.Item
            name="language"
            rules={[
              {
                required: true,
                message:
                  "You cannot submit without selecting a programming language",
              },
            ]}
            label={
              <Title
                as="h5"
                style={{
                  fontSize: "14px",
                  fontWeight: 700,
                  marginBottom: 0,
                }}
              >
                Please select a Coding Language
              </Title>
            }
          >
            <Select
              placeholder="Select..."
              onChange={(val) => {
                setCandidateSelectedLan(val);
                setDisplayCodeEditor(true);
              }}
              disabled={displayCodeEditor}
            >
              {languages &&
                languages.map((value) => (
                  <Select.Option value={value.name} key={value.name}>
                    {progLanguages[value.name]}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      ) : candidateSelectedLan && displayCodeEditor ? (
        <Editor
          type={
            !initialLan
              ? CODE_EDITOR_TYPES.LANGUAGE_UNLOCKED
              : CODE_EDITOR_TYPES.LANGUAGE_LOCKED
          }
          codingLanguage={candidateSelectedLan}
          codingQuestion={files}
          setCodingQuestion={onChange}
          runCmd={runCmd}
          setRunCmd={setRunCmd}
          stdIn={stdIn}
          setStdIn={setStdIn}
          toggleEditorDisplay={toggleEditorDisplay}
          setCandidateSelectedLan={setCandidateSelectedLan}
          setDisplayCodeEditor={setDisplayCodeEditor}
          questionId={questionId}
        />
      ) : null}
    </>
  );
}
export default CodingExercise;
