import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import classNames from "./Link.module.scss";

function Link({
  order,
  activeClasses,
  icon,
  iconClasses,
  to,
  linkClasses,
  linkText,
  onClick,
  isActive,
  released,
}) {
  return (
    <RouterLink
      style={{ display: released && released === "no" ? "none" : null }}
      className={
        !isActive
          ? `${classNames.link} ${linkClasses}`
          : `${classNames.link} ${linkClasses} ${classNames.active} ${activeClasses}`
      }
      activeClassName={`${classNames.active} ${activeClasses}`}
      to={`/${to}`}
      onClick={onClick}
    >
      {icon && (
        <span className={`${classNames.icon} ${iconClasses}`}>{icon()}</span>
      )}
      {typeof linkText === "string" ? (
        <span>{linkText}</span>
      ) : (
        <span>{linkText()}</span>
      )}
    </RouterLink>
  );
}

export default Link;
