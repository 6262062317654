import { Skeleton, Row, Col } from "antd";
import classNames from "./JobSkeleton.module.scss";

const JobSkeleton = () => {
  const options = [1, 2, 3, 4];
  const cols = [1, 2, 3, 4];
  const candidates = [1, 2, 3, 4];
  return (
    <div className={classNames.wrapper}>
      <div className={classNames.header}>
        <div>
          <Skeleton.Avatar
            active={true}
            shape="square"
            style={{ width: 300, height: 40, borderRadius: 5 }}
          />
          <div>
            {options.map((key) => (
              <Skeleton.Avatar
                key={key}
                active={true}
                shape="square"
                style={{
                  borderRadius: 5,
                  width: 60,
                  height: 30,
                  marginBottom: 10,
                  marginLeft: 30,
                }}
              />
            ))}
          </div>
        </div>
        <div>
          {cols.map((key) => (
            <Skeleton.Avatar
              key={key}
              active={true}
              shape="square"
              style={{
                borderRadius: 5,
                width: 200,
                height: 30,
                marginBottom: 10,
              }}
            />
          ))}
        </div>
      </div>
      <div className={classNames.content}>
        <Col>
          {candidates.map((key) => (
            <Row>
              <Skeleton.Avatar
                key={key}
                active={true}
                shape="square"
                style={{
                  borderRadius: 5,
                  width: 250,
                  height: 92,
                  marginBottom: 20,
                  marginRight: 15,
                  marginLeft: 15,
                }}
              />
            </Row>
          ))}
        </Col>
        <Col>
          {candidates.map((key) => (
            <Row>
              <Skeleton.Avatar
                key={key}
                active={true}
                shape="square"
                style={{
                  borderRadius: 5,
                  width: 250,
                  height: 92,
                  marginBottom: 20,
                  marginRight: 15,
                  marginLeft: 15,
                }}
              />
            </Row>
          ))}
        </Col>
        <Col>
          {candidates.map((key) => (
            <Row>
              <Skeleton.Avatar
                key={key}
                active={true}
                shape="square"
                style={{
                  borderRadius: 5,
                  width: 250,
                  height: 92,
                  marginBottom: 20,
                  marginRight: 15,
                  marginLeft: 15,
                }}
              />
            </Row>
          ))}
        </Col>
        <Col>
          {candidates.map((key) => (
            <Row>
              <Skeleton.Avatar
                key={key}
                active={true}
                shape="square"
                style={{
                  borderRadius: 5,
                  width: 250,
                  height: 92,
                  marginBottom: 20,
                  marginRight: 15,
                  marginLeft: 15,
                }}
              />
            </Row>
          ))}
        </Col>
      </div>
    </div>
  );
};

export default JobSkeleton;
