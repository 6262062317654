import api from "../../../../api";
import types from "../../../types";
import { generateSyncAction } from "../../../utils.redux";

export default function getCandidateTestById(id) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.candidateTest.getById.started));
    try {
      const data = await api.candidateTest.get(id);
      dispatch(
        generateSyncAction(types.candidateTest.getById.success, data.data)
      );
    } catch (error) {
      dispatch(generateSyncAction(types.candidateTest.getById.failed, error));
    }
  };
}
