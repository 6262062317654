import types from "../../types";

export default function runCode(
  state = { fetching: false, error: false, questionId: "" },
  action
) {
  switch (action.type) {
    case types.codeEditor.runCode.started:
      return Object.assign(
        {},
        { fetching: true, questionId: action?.data?.questionId }
      );

    case types.codeEditor.runCode.success:
      return Object.assign(
        {},
        {
          fetching: false,
          success: true,
          data: action.data?.data,
          questionId: action?.data?.questionId,
        }
      );

    case types.codeEditor.runCode.failed:
      return Object.assign(
        {},
        {
          fetching: false,
          success: false,
          data: action.data?.data,
          questionId: action?.data?.questionId,
        }
      );

    case types.codeEditor.runCode.reset:
      return Object.assign(
        { ...state },
        { data: null, questionId: action?.data?.data?.questionId }
      );
    default:
      return state;
  }
}
