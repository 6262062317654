import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "./common";
import { Suspense, lazy } from "react";
import { DashboardSkeleton } from "./Dashboard/Home/Skeletons";
import SupervisorTestPage from "./SkillTest/SupervisorTestPage";

const AddNewJob = lazy(() => import("./Dashboard/Jobs/AddNewJob/AddNewJob"));
const CompanyNotApproved = lazy(() =>
  import("./Dashboard/Home/CompanyNotApproved/CompanyNotApproved")
);
const Home = lazy(() => import("./Dashboard/Home/Home"));
const EditJob = lazy(() => import("./Dashboard/Jobs/EditJob/EditJob"));
const Signin = lazy(() => import("./Auth/Signin/Signin"));
const Signup = lazy(() => import("./Auth/Signup/Signup"));
const ConfirmAuth = lazy(() => import("./Auth/ConfirmAuth/ConfirmAuth"));
const ForgotPassword = lazy(() =>
  import("./Auth/ForgotPassword/ForgotPassword")
);
const SkillTest = lazy(() => import("./SkillTest/SkillTest"));
const TestStartPage = lazy(() =>
  import("./SkillTest/TestStartPage/TestStartPage")
);

export default function Routes({
  companyApproved,
  userId,
  jobTitles,
  signedIn,
}) {
  const pathname = window.location.pathname;
  return (
    <Router>
      <Suspense
        fallback={
          pathname.includes("/skill-test/") || pathname.includes("/t/") ? (
            ""
          ) : (
            <DashboardSkeleton />
          )
        }
      >
        <Switch>
          <Route
            exact
            path="/t/:testId/:candidateTestId/:candidateId/:jobId/:companyId/:candidateName"
            component={SkillTest}
          />
          <Route
            exact
            path="/skill-test/:testId/:candidateTestId/:jobId/:companyId/:userId/:candidateId/:supervisor/:candidateName"
            component={SupervisorTestPage}
          />

          <Route
            exact
            path="/skill-test/:testId/:candidateTestId/:jobId/:companyId/:userId/:candidateId/:candidateName"
            component={TestStartPage}
          />

          <Route exact path="/signin" component={Signin} />

          <Route exact path="/signup" component={Signup} />

          <Route
            exact
            path="/forgot-password"
            component={ForgotPassword}
            isAuthenticated={true}
          />

          <Route
            exact
            path="/confirm-auth"
            component={ConfirmAuth}
            isAuthenticated={true}
          />

          <ProtectedRoute
            exact
            path="/add-new-job"
            userId={userId}
            signedIn={signedIn}
          >
            <AddNewJob />
          </ProtectedRoute>

          <Route
            exact
            path="/edit-job/:jobId"
            component={EditJob}
            isAuthenticated={false}
          />

          <Route
            exact
            path="/profile-created"
            component={(props) => (
              <CompanyNotApproved
                companyApproved={companyApproved}
                {...props}
              />
            )}
            isAuthenticated={false}
          />

          <ProtectedRoute path="/" userId={userId} signedIn={signedIn}>
            <Home jobTitles={jobTitles} companyApproved={companyApproved} />
          </ProtectedRoute>
        </Switch>
      </Suspense>
    </Router>
  );
}
