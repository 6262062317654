import { API } from "aws-amplify";
import { getJob } from "../../graphql/queries";

export default async function fetchJob(jobId) {
  return await API.graphql({
    query: getJob,
    variables: {
      id: jobId,
    },
  });
}
