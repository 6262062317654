import endpoints from "../config";

export default async function createAnswerApi(data) {
  return await (
    await fetch(endpoints.ANSWERS[process.env.REACT_APP_ENV], {
      method: "POST",
      body: JSON.stringify(data),
    })
  ).json();
}
