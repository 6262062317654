export default function runCommandDefault(language) {
  switch (language) {
    case "c":
      return "clang main.c && ./a.out";

    case "cpp":
      return "clang++ main.cpp && ./a.out";

    case "csharp":
      return "mcs -out:a.exe main.cs && mono a.exe";

    case "java":
      return "javac Main.java && java Main";

    case "javascript":
      return "node main.js";

    case "typescript":
      return "tsc main.ts && node main.js";

    case "kotlin":
      return "kotlinc main.kt && kotlin MainKt";

    case "php":
      return "php main.php";

    case "ruby":
      return "ruby main.rb";

    case "swift":
      return "swift main.swift";

    case "python":
      return "python main.py";

    default:
      return "";
  }
}
