import { generateSyncAction } from "../../utils.redux";
import { Auth } from "aws-amplify";
import types from "../../types";

export default function resetPassword(oldPassword, newPassword) {
  return async (dispatch) => {
    dispatch(generateSyncAction(types.userManagement.resetPassword.started));

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      await Auth.signOut({ global: true });
      dispatch(generateSyncAction(types.userManagement.resetPassword.success));
    } catch (error) {
      dispatch(
        generateSyncAction(types.userManagement.resetPassword.failed, error)
      );
    }
  };
}
