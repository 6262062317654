import { useState, useEffect } from "react";
import { Form, Alert, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { alerts, validation } from "./configs";
import { Cache } from "aws-amplify";
import ac from "../../../redux/actions";
import classNames from "./ConfirmAuth.module.scss";
import mollyAvatar from "../../../assets/molly-avatar.svg";
import mollyText from "../../../assets/molly-text.svg";
import { Link, useHistory } from "react-router-dom";
import Drift from "react-driftjs";
import {
  Title,
  Input,
  PrimaryButton,
  AuthLinkWrapper,
} from "../../common/index.js";
import { BsDot } from "react-icons/bs";

export default function ConfirmAuth(props) {
  const [username, setUsername] = useState(undefined);
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(true);

  const fetching = useSelector(function ({ confirmAuth }) {
    return confirmAuth.fetching;
  });

  const errorMessage = useSelector(function ({ confirmAuth }) {
    return confirmAuth.error
      ? "Invalid verification code provided, please try again"
      : undefined;
  });

  const { data: isSuccess } = useSelector(function ({ confirmAuth }) {
    return confirmAuth;
  });

  function handleSubmit({ verificationCode, username }) {
    dispatch(ac.confirmAuth(username, verificationCode));
  }

  useEffect(
    function () {
      const username = Cache.getItem("molly-signup");

      if (username) {
        form.setFieldsValue({ username });
        setUsername(username);
      }
    },
    [form]
  );

  useEffect(
    function () {
      if (!!isSuccess) {
        message.success("Email verified successfully!").then(() => {
          history.replace("/signin");
          Cache.removeItem("molly-signup");
        });
      }
    },
    [isSuccess, history]
  );

  return (
    <div className={classNames.wrapper}>
      <img src={mollyAvatar} alt="Molly logo" className={classNames.logo} />
      <Title as="h1" style={{ fontSize: "36px" }}>
        Verify your email
      </Title>
      <Form
        onFinish={handleSubmit}
        form={form}
        layout="vertical"
        initialValues={{ username }}
        hideRequiredMark
      >
        <Alert
          message={alerts.checkEmailAlert}
          type="info"
          style={{ marginBottom: 20 }}
        />
        {/* Errors */}
        {!!errorMessage && (
          <div>
            <Alert
              message={errorMessage}
              type="error"
              style={{ marginBottom: 10 }}
            />
          </div>
        )}

        <Form.Item
          label="Email"
          rules={[{ required: true, message: validation.enterEmail }]}
          name="username"
        >
          <Input
            placeholder=""
            className={classNames.input}
            disabled={!!username}
          />
        </Form.Item>

        <Form.Item
          label="Verification code"
          rules={[{ required: true, message: validation.confirmCode }]}
          name="verificationCode"
        >
          <Input
            placeholder=""
            className={classNames.input}
            onClick={() => {
              setDisable(false);
            }}
          />
        </Form.Item>

        <PrimaryButton
          htmlType="submit"
          type="primary"
          loading={fetching}
          disabled={disable}
          style={{ width: "100%", fontWeight: "bold" }}
        >
          Verify
        </PrimaryButton>
      </Form>

      <AuthLinkWrapper>
        <AuthLinkWrapper.Link to="/signup" el={Link}>
          I don't have an account
        </AuthLinkWrapper.Link>

        <BsDot />

        <AuthLinkWrapper.Link to="/forgot-password" el={Link}>
          Forgot password
        </AuthLinkWrapper.Link>
      </AuthLinkWrapper>

      <img
        src={mollyText}
        alt="Molly logo"
        className={classNames.mollyLogoText}
      />
      <Drift appId="txw66twatn49" />
    </div>
  );
}
