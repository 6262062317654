import types from "../../types";
import { generateSyncAction } from "../../utils.redux";
import api from "../../../api";

export default function runCode(input) {
  return async function (dispatch) {
    dispatch(
      generateSyncAction(types.codeEditor.runCode.started, {
        questionId: input?.questionId,
      })
    );
    try {
      const data = await api.codeEditor.runCode(input);
      dispatch(
        generateSyncAction(types.codeEditor.runCode.success, {
          data,
          questionId: input?.questionId,
        })
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.codeEditor.runCode.failed, {
          error,
          questionId: input?.questionId,
        })
      );
    }
  };
}
