import api from "../../../api";
import types from "../../types";
import { generateSyncAction } from "../../utils.redux";

export default function updateSkillTest(input, id) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.skillTest.updateById.started));

    try {
      const data = await api.skillTest.updateById(input, id);
      dispatch(generateSyncAction(types.skillTest.updateById.success, data));
    } catch (error) {
      dispatch(generateSyncAction(types.skillTest.updateById.failed, error));
    }
  };
}
