import classNames from "./Card.module.scss";
import Heading from "./Heading";
import Description from "./Description";
import Body from "./Body";
import Footer from "./Footer";
import findChildByTypeName from "../utils/findChildByTypeName";
import { Card as ACard } from "antd";
import React, { Children, ReactNode } from "react";

const { Meta } = ACard;

type Props = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  variant?: "small" | "medium" | "large";
  shadow?: "soft" | "softer" | "softest" | "no-shadow";
  noPadding?: boolean;
  noBorder?: boolean;
  noMargin?: boolean;
  noBoxProps?: boolean;
  rest?: [];
};

export default function Card({
  children,
  className,
  style,
  variant = "small",
  shadow = "soft",
  noBorder = false,
  noPadding = false,
  noMargin = false,
  noBoxProps = false,
  ...rest
}: Props): JSX.Element | null {
  const ChildrenArray = Children.toArray(children);

  const Description = findChildByTypeName(children, "Description");

  const Heading = findChildByTypeName(children, "Heading");

  const Other = ChildrenArray.filter((Child): ReactNode => {
    if (React.isValidElement(Child) && typeof Child.type === "function") {
      return (
        Child.type.name !== "Description" && Child.type?.name !== "Heading"
      );
    } else if (React.isValidElement(Child)) {
      return Child;
    }

    return null;
  });

  return (
    <ACard
      style={{ ...style }}
      className={`
      ${classNames.card}
      ${classNames[variant]}
      ${classNames[shadow]}
      ${noBorder ? classNames["no-border"] : ""}
      ${noPadding ? classNames["no-padding"] : ""}
      ${noMargin ? classNames["no-margin"] : ""}
      ${noBoxProps ? classNames.noBoxProps : " "}
      ${className}
    }`}
      {...rest}
    >
      {(Heading || Description) && (
        <Meta title={Heading} description={Description} />
      )}
      {Other}
    </ACard>
  );
}

Card.Body = Body;
Card.Heading = Heading;
Card.Description = Description;
Card.Footer = Footer;
