import classNames from "./Body.module.scss";

type Props = {
  children: React.ReactNode;
  style: React.CSSProperties;
};

const Body = ({ children, style }: Props): React.ReactNode => {
  return (
    <div
      style={{ overflow: "auto", maxHeight: "70vh", ...style }}
      className={classNames.wrapper}
    >
      {children}
    </div>
  );
};

export default Body;
