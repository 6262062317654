import React from "react";
import classNames from "./Warning.module.scss";
import { Wrapper } from "..";
import { Alert, Button } from "antd";

function ErrorWarning({
  message,
  button,
  buttonTitle,
  type,
  onClick,
  description,
}) {
  return (
    <Wrapper classes={classNames.wrapper}>
      {message && (
        <Alert
          className={classNames.alert}
          message={message}
          type={type}
          description={description}
          action={button && <Button onClick={onClick}>{buttonTitle}</Button>}
        />
      )}
    </Wrapper>
  );
}

export default ErrorWarning;
