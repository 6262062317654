export const progLanguages = {
  c: "C",
  cpp: "C++",
  csharp: "C#",
  java: "Java",
  javascript: "JavaScript",
  kotlin: "Kotlin",
  php: "PHP",
  python: "Python",
  ruby: "Ruby",
  swift: "Swift",
  typescript: "TypeScript",
};
