import types from "../../types";

export default function candidates(
  state = { fetching: false, error: false, success: false },
  action
) {
  switch (action.type) {
    case types.candidates.updateStage.started:
      return Object.assign({}, { success: false, fetching: true });

    case types.candidates.updateStage.success:
      return Object.assign(
        {},
        {
          success: true,
          fetching: false,
          data: { ...action.data.data.updateCandidateStage },
        }
      );

    case types.candidates.resetUpdateStage.success:
      return Object.assign(
        {},
        { success: false, fetching: false, error: false }
      );

    case types.candidates.updateStage.failed:
      return Object.assign(
        {},
        { success: false, fetching: false, error: action.data.data }
      );

    default:
      return state;
  }
}
