import React from "react";
import classNames from "./Notification.module.scss";
import { Button } from "antd";

function Notification({ message, buttonContent, handleBtnClick }) {
  return (
    <div className={classNames.wrapper} data-test="onbording-notification">
      {typeof message === "string" ? message : message()}
      <Button onClick={handleBtnClick}>
        {typeof buttonContent === "string" ? buttonContent : buttonContent()}
      </Button>
    </div>
  );
}

export default Notification;
