import endpoints from "../config";

export default async function updateTest(data, id) {
  return await (
    await fetch(`${endpoints.SKILL_TEST[process.env.REACT_APP_ENV]}?id=${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
    })
  ).json();
}
