import api from "../../../../api";
import types from "../../../types";
import { generateSyncAction } from "../../../utils.redux";

export default function listCandidateTestsByCandidateId(id) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.candidateTest.list.started));
    try {
      const data = await api.candidateTest.list(id);
      dispatch(generateSyncAction(types.candidateTest.list.success, data.data));
    } catch (error) {
      dispatch(generateSyncAction(types.candidateTest.list.failed, error));
    }
  };
}
