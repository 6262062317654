import React, { useEffect, useState } from "react";
import ac from "../../../redux/actions";
import { Avatar } from "../../common";
import { Menu, Dropdown } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Storage } from "aws-amplify";

export default function ProfileDropdown() {
  const [hide, setHide] = useState();
  const fullName = useSelector(function ({ user }) {
    return user.data ? `${user.data.firstName} ${user.data.lastName}` : {};
  });

  const role = useSelector(function ({ user }) {
    return user.data ? user.data.role : {};
  });

  const avatar = useSelector(function ({ user }) {
    return user.data ? user.data.avatar : {};
  });

  const [avatarUrl, setAvatarUrl] = useState("");

  useEffect(() => {
    if (avatar) {
      Storage.get(avatar, {
        level: "public",
      }).then((data) => {
        setAvatarUrl(data);
      });
    }
  }, [avatar]);

  useEffect(
    function () {
      if (role !== "admin") {
        setHide(true);
      }
    },
    [role]
  );

  const dispatch = useDispatch();

  const username = useSelector(function ({ signin }) {
    return signin.data ? signin.data.username : {};
  });

  const handleSignout = () => {
    dispatch(ac.globalSignOut(username));
    localStorage.clear();
    window.location.reload();
  };

  const MenuList = () => (
    <Menu
      style={{
        width: 150,
        boxShadow: "rgb(226 226 226) -2px 3px 2px 2px!important",
      }}
    >
      <Menu.Item>
        <Link to="/settings/user-profile">Profile</Link>
      </Menu.Item>
      <Menu.Item style={hide ? { display: "none" } : {}}>
        <Link to="/settings/company-profile">Settings</Link>
      </Menu.Item>
      <Menu.Item onClick={handleSignout}>Logout</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={<MenuList />}>
      <Avatar imageUrl={avatarUrl} name={fullName} size={40} />
    </Dropdown>
  );
}
