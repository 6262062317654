import { GrClose } from "react-icons/gr";
import { PrimaryButton } from "../index";
import React, { useState } from "react";
import Modal from "../Modal";

export default function CloseButton({
  onClick,
  history,
  style,
  confirmBeforeClose,
}) {
  const [showConfirm, setShowConfirm] = useState(false);

  function handleClick(confirmed = false) {
    if (confirmBeforeClose && !confirmed) {
      setShowConfirm(true);
      return;
    }
    onClick();

    if (history) {
      history.goBack();
    }
  }
  return (
    <>
      <Modal
        visible={showConfirm}
        onCancel={() => {
          setShowConfirm(false);
        }}
      >
        <Modal.Body
          style={{ padding: "28px 30px 18px 32px", background: "#fff" }}
        >
          <p>Changes you made will not be saved.</p>
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", justifyContent: "right" }}>
          <PrimaryButton
            style={{ marginRight: 10 }}
            variant="outline"
            onClick={() => {
              setShowConfirm(false);
            }}
          >
            Cancel
          </PrimaryButton>
          <PrimaryButton onClick={handleClick}>OK</PrimaryButton>
        </Modal.Footer>
      </Modal>
      <GrClose
        onClick={() => {
          handleClick();
        }}
        style={{
          borderRadius: "50%",
          boxSizing: "content-box",
          padding: 10,
          background: "#e2e2e2",
          cursor: "pointer",
          marginRight: 20,

          ...style,
        }}
      />
    </>
  );
}
