import endpoints from "../../config";

export default async function updateCandidateTestResult(data) {
  const { id, candidateId, testResult, reviewedManager } = data;
  const url = `${
    endpoints.CANDIDATE_TEST[process.env.REACT_APP_ENV]
  }/updateResult?id=${id}`;

  const reqData = {
    candidateId: `${candidateId}`,
    testResult: `${testResult}`,
    reviewedManager: `${reviewedManager}`,
  };
  return fetch(url, {
    method: "PUT",
    // headers: {
    //     "X-API-KEY": "",
    // },
    body: JSON.stringify(reqData),
  }).then((res) => res.json());
}
