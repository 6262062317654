import types from "../../types";
import { generateSyncAction } from "../../utils.redux";
import API from "../../../api";

export default function inviteMember(input) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.user.inviteMember.started));

    try {
      const data = await API.user.inviteMember(input);
      dispatch(generateSyncAction(types.user.inviteMember.success, data));
      dispatch(generateSyncAction(types.reset.inviteMember));
    } catch (error) {
      dispatch(generateSyncAction(types.user.inviteMember.failed, error));
      dispatch(generateSyncAction(types.reset.inviteMember));
    }
  };
}
