import classNames from "./Select.module.scss";
import { Select as AntSelect } from "antd";
import React from "react";

type Props = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  size?: "default" | "small";
};

export default function Select({
  className,
  children,
  size = "default",
  ...props
}: Props): JSX.Element {
  return (
    <AntSelect
      {...props}
      className={`${classNames.select} ${classNames.removeBorder} ${classNames[size]} ${className} `}
    >
      {children}
    </AntSelect>
  );
}

Select.Option = Option;
