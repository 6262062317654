import types from "../../types";
import { generateSyncAction } from "../../utils.redux";
import API from "../../../api";

export default function updateCompanylogo(inputs) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.company.updateLogo.started));

    try {
      const data = await API.company.updateCompanyLogo(inputs);
      const allData = {
        data,
        inputs,
      };
      dispatch(generateSyncAction(types.company.updateLogo.success, allData));
    } catch (error) {
      dispatch(generateSyncAction(types.company.updateLogo.failed, error));
    }
  };
}
