import types from "../../types";

export default function listTestsByCompanyId(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.tests.listByCompanyId.started:
      return Object.assign({}, { fetching: true });

    case types.tests.listByCompanyId.success:
      return Object.assign(
        {},
        { fetching: false, success: true, data: action.data }
      );

    case types.tests.listByCompanyId.failed:
      return Object.assign(
        {},
        { fetching: false, error: JSON.stringify(action.data) }
      );

    default:
      return state;
  }
}
