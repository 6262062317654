import endpoints from "../config";

export default async function createExercise(data) {
  return await (
    await fetch(`${endpoints.EXERCISES[process.env.REACT_APP_ENV]}exercise`, {
      method: "POST",
      body: JSON.stringify(data),
    })
  ).json();
}
