import classNames from "./TestLobbyPage.module.scss";
import AudioVideo from "../common/Av/SupervisorAudioVideo";
import { AiOutlineFileDone } from "react-icons/ai";

const TestLobbyPage = ({
  candidate,
  testCompleted,
  testExpired,
  tracks,
  ready,
}) => {
  if (testCompleted) {
    return (
      <div className={classNames.submitSuccess}>
        <AiOutlineFileDone style={{ fontSize: 80, color: "green" }} />
        <div style={{ fontSize: 26 }}>
          The test has been submitted successfully!
        </div>
      </div>
    );
  }
  if (testExpired) {
    return (
      <div className={classNames.submitSuccess}>
        <AiOutlineFileDone style={{ fontSize: 80, color: "orange" }} />
        <div style={{ fontSize: 26 }}>The test has expired!</div>
      </div>
    );
  }

  return (
    <div className={classNames.container}>
      <div>
        <div className={classNames.waitingText}>
          Waiting for {`${candidate}`} to join the test...
        </div>
        <div>
          <p className={classNames.instructionsText}>
            Please grant access to webcam and microphone
          </p>
          <AudioVideo
            classNames={classNames}
            supervisor
            camMicInitialState={false}
            tracks={tracks}
            ready={ready}
          />
        </div>
      </div>
    </div>
  );
};

export default TestLobbyPage;
