import api from "../../../../api";
import types from "../../../types";
import { generateSyncAction } from "../../../utils.redux";

export default function startCandidateTest(values) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.candidateTest.start.started));

    try {
      const data = await api.candidateTest.start(values);
      dispatch(generateSyncAction(types.candidateTest.start.success, data));
    } catch (error) {
      dispatch(generateSyncAction(types.candidateTest.start.failed, error));
    }
  };
}
