import types from "../../../types";

export default function getQuestionById(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.question.getQuestionById.started:
      return {
        ...state,
        fetching: true,
      };

    case types.question.getQuestionById.success:
      return Object.assign(
        {},
        { fetching: false, success: true, data: action.data }
      );

    case types.question.createQuestion.success:
      let stateCopy = { ...state.data };
      return Object.assign(
        {},
        {
          fetching: false,
          success: true,
          data: {
            ...stateCopy,
            data: {
              ...stateCopy.data,
              Items: [...stateCopy.data.Items, action.data.data],
            },
          },
        }
      );

    case types.question.getQuestionById.failed:
      return Object.assign({}, { fetching: false, success: false });

    default:
      return state;
  }
}
