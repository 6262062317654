import types from "../../types";

export default function globalSignOut(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.user.globalSignOut.started:
      return Object.assign({}, { fetching: true });

    case types.user.globalSignOut.success:
      return Object.assign(
        {},
        { fetching: false, data: action.data, signOut: true }
      );

    case types.user.globalSignOut.failed:
      return Object.assign({}, { fetching: true, error: action.data });

    default:
      return state;
  }
}
