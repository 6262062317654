import React from "react";
import classNames from "./AuthLink.module.scss";

export default function Link({
  el: El = "div",
  children,
  className,
  ...props
}) {
  return (
    <El className={classNames.link + " " + className} {...props}>
      {children}
    </El>
  );
}
