import types from "../../types";
import { generateSyncAction } from "../../utils.redux";
import API from "../../../api";

export default function send(msg) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.messaging.send.started));

    try {
      const data = await API.message.send(msg);
      dispatch(generateSyncAction(types.messaging.send.success, data));
    } catch (error) {
      dispatch(generateSyncAction(types.messaging.send.failed, error));
    }
  };
}
