import endpoints from "../config";

export default async function sendManagerEmail(data) {
  return await (
    await fetch(endpoints.MESSAGING.SEND[process.env.REACT_APP_ENV], {
      method: "POST",
      body: JSON.stringify(data),
    })
  ).json();
}
