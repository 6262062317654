import { Auth } from "aws-amplify";

export default function signup(username, password) {
  return Auth.signUp({
    username,
    password,
    // attributes: {
    //   "custom:userProfileCompleted": "false",
    // },
  });
}
