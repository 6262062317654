import types from "../../types";

export default function removeMember(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.user.removeMember.started:
      return Object.assign({}, { fetching: true });

    case types.user.removeMember.success:
      return Object.assign(
        {},
        { fetching: false, data: { ...action.data.data } }
      );

    case types.user.removeMember.failed:
      return Object.assign({}, { fetching: false, error: { ...action.data } });

    case types.user.removeMember.reset:
      return Object.assign({}, { fetching: false, error: false });

    default:
      return state;
  }
}
