import classNames from "./Heading.module.scss";
import { ReactElement, isValidElement, Children, cloneElement } from "react";
import findChildByTypeName from "../utils/findChildByTypeName";

type Props = {
  children?: React.ReactNode;
  style: React.CSSProperties;
  className: string;
};

const PreTitle = ({ children, style }: Props): ReactElement => {
  const propsForIsValidEle = {
    style: Object.assign({}, { marginRight: 10 }, { ...style }),
  };
  const alteredPreText = isValidElement(children)
    ? cloneElement(children, propsForIsValidEle)
    : null;
  return <>{alteredPreText}</>;
};

const Title = ({ children }: Props): ReactElement => {
  return <>{children}</>;
};

const Heading = ({
  children,
  className,
  style,
}: Props): ReactElement | null => {
  const Title = findChildByTypeName(children, "Title");
  const PreTitle = findChildByTypeName(children, "PreTitle");

  const Other = Children.toArray(children).filter((Child): React.ReactNode => {
    if (isValidElement(Child) && typeof Child.type === "function") {
      return Child.type.name !== "Title" && Child.type.name !== "PreTitle";
    } else if (isValidElement(Child)) {
      return Child;
    }

    return null;
  });

  return (
    <div style={style} className={`${className} ${classNames.wrapper}`}>
      {PreTitle}
      {Title}
      {Other}
    </div>
  );
};

Heading.PreTitle = PreTitle;
Heading.Title = Title;

export default Heading;
