import { Result as AntResult } from "antd";
import React from "react";
import classNames from "./Result.module.scss";
import { FiFileText } from "react-icons/fi";

type Props = {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  extra?: React.ReactNode;
  status?: "success" | "error" | "info" | "warning" | 404 | 403 | 500;
  subTitle?: React.ReactNode;
  isDefault?: boolean;
};

export default function Result({
  icon,
  title,
  extra,
  subTitle,
  status,
  isDefault = true,
}: Props): JSX.Element {
  return (
    <div className={classNames.wrapper}>
      <AntResult
        icon={
          isDefault ? (
            <FiFileText size={120} color="rgba(0, 0, 0, 0.25)" />
          ) : (
            icon
          )
        }
        title={title}
        status={status}
        subTitle={subTitle}
        extra={extra}
      />
    </div>
  );
}
