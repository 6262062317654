import Wrapper from "../StrictWrapper/Wrapper";
import classNames from "./Sidebar.module.scss";
import Logo from "../Logo/Logo";
import Link from "../Link/Link";
import { bottomSectionItems } from "./settings";
import { FiPlusCircle, FiShoppingBag } from "react-icons/fi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Spin } from "antd";
import { useHistory } from "react-router";

function Sidebar({ jobTitles, isFetching }) {
  const history = useHistory();

  const modifiedList = jobTitles
    ? jobTitles.map((job, index) => ({
        id: job.id,
        order: index,
        text: job.title,
        icon: () => <FiShoppingBag />,
        to: `jobs/${job.id}`,
        published:
          job.hasOwnProperty("published") && job?.published !== null
            ? job?.published
            : true,
      }))
    : [];

  modifiedList.push({
    id: 1,
    order: "last",
    text: "Add new job",
    icon: () => <FiPlusCircle size={18} />,
    to: "add-new-job",
  });

  function onLogoClick() {
    if (jobTitles[0]) {
      history.push("/jobs/" + jobTitles[0].id);
    }
  }

  return (
    <Wrapper el="aside" classes={classNames.sidebar}>
      <div className={classNames.logoWrapper}>
        <Logo onClick={onLogoClick} />
      </div>
      <div className={classNames.itemsWrapper}>
        <Wrapper el="ul" classes={`${classNames.jobsListWraper}`}>
          <Spin spinning={isFetching}>
            {modifiedList
              .sort((a, b) => !!b.published - !!a.published)
              .map((item) => {
                return (
                  <Link
                    order={item.order}
                    linkText={item.text}
                    to={item.to}
                    key={item.order}
                    icon={item.icon}
                    linkClasses={
                      !item.published &&
                      item.id !== 1 &&
                      classNames.unpublishedJobLink
                    }
                  />
                );
              })}
          </Spin>
        </Wrapper>
        {/* ===== End of top part of the sidebar ===== */}
        <div className={classNames.separator}>
          <BsThreeDotsVertical />
        </div>
        {/* ===== Bottom part of the sidebar ===== */}
        <Wrapper el="ul" classes={classNames.sidebarSection}>
          {bottomSectionItems.map((item) => {
            return (
              <Link
                activeClasses={classNames.active}
                order={item.order}
                iconClasses={classNames.icon}
                linkClasses={classNames.link}
                linkText={item.text}
                to={item.to}
                key={item.order}
                icon={item.icon}
              />
            );
          })}
        </Wrapper>
      </div>
    </Wrapper>
  );
}

export default Sidebar;
