import types from "../../../types";

export default function customizeExercise(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.exercise.customizeExercise.success: {
      return {
        ...state,
        data: action?.data ?? null,
      };
    }
    default:
      return state;
  }
}
