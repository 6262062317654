import api from "../../../../api";
import types from "../../../types";
import { generateSyncAction } from "../../../utils.redux";

export default function getQuestionById(questionId) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.question.getQuestionById.started));

    try {
      const data = await api.question.get(questionId);
      dispatch(
        generateSyncAction(types.question.getQuestionById.success, data)
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.question.getQuestionById.failed, error)
      );
    }
  };
}
