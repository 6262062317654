import classNames from "./TimePicker.module.scss";
import { TimePicker as AntTimePicker } from "antd";
import React from "react";

type Props = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  size?: "default" | "small";
};

export default function TimePicker({
  className,
  children,
  size = "default",
  ...props
}: Props): JSX.Element {
  return (
    <AntTimePicker
      {...props}
      className={`${classNames.select} ${classNames.removeBorder} ${classNames[size]} ${className} `}
    />
  );
}
