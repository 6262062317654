import { useRef } from "react";
import useDraggable from "../../../hooks/useDraggable";
import { AgoraVideoPlayer } from "agora-rtc-react";

function VideoFeed({ users, classNames, candidate, candidateName }) {
  const hasScreenSharingUser = users.some(
    (user) => user.uid === +process.env.REACT_APP_CANDIDATE_SCREEN_UID
  );
  const firstName = candidateName?.split(" ")[0];

  const Draggable = (props) => {
    const cardRef = useRef(null);
    useDraggable(cardRef, true);

    return (
      <div className={classNames.videoSzSupervisor} ref={cardRef}>
        {props.children}
      </div>
    );
  };

  if (hasScreenSharingUser && candidate && users.length === 1) {
    return (
      <Draggable>
        <div className={classNames.videoWrapperSupervisor}>
          <span className={classNames.circle}>
            {candidateName[0]?.charAt(0)}
          </span>
          <div className={classNames.presenterSupervisor}>
            {`${firstName} is presenting`}
          </div>
          <h6 className={classNames.videoName}>{candidateName}</h6>
        </div>
      </Draggable>
    );
  }

  return (
    hasScreenSharingUser &&
    users
      .filter(
        (user) => user.uid !== +process.env.REACT_APP_CANDIDATE_SCREEN_UID
      )
      .map((user) => {
        if (user.videoTrack) {
          return (
            <Draggable key={user.uid}>
              <AgoraVideoPlayer
                style={{ height: "100%", width: "100%" }}
                className="video-feed-supervisor"
                videoTrack={user.videoTrack}
              />
              <div className={classNames.presenterSupervisor}>
                {`${firstName} is presenting`}
              </div>
              <h6 className={classNames.videoName}>{candidateName}</h6>
            </Draggable>
          );
        } else return null;
      })
  );
}

export default VideoFeed;
