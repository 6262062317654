import types from "../../types";
import api from "../../../api";
import { generateSyncAction } from "../../utils.redux";

export default function deactivateUserProfile(inputs) {
  const input = inputs;
  return async function (dispatch) {
    dispatch(generateSyncAction(types.user.deactivateUserProfile.started));

    try {
      const data = await api.user.deactivateUserProfile(input);
      dispatch(
        generateSyncAction(types.user.deactivateUserProfile.success, data)
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.user.deactivateUserProfile.failed, error)
      );
    }
  };
}
