import api from "../../../../api";
import types from "../../../types";
import { generateSyncAction } from "../../../utils.redux";

export default function getAllAnswers() {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.answer.getAllAnswers.started));
    try {
      const data = await api.answer.getAll();
      dispatch(generateSyncAction(types.answer.getAllAnswers.success, data));
    } catch (error) {
      dispatch(generateSyncAction(types.answer.getAllAnswers.failed, error));
    }
  };
}
