import api from "../../../../api";
import types from "../../../types";
import { generateSyncAction } from "../../../utils.redux";

export default function updateCandidateTestResult(data) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.candidateTest.updateResult.started));

    try {
      const res = await api.candidateTest.updateResult(data);

      dispatch(
        generateSyncAction(types.candidateTest.updateResult.success, res)
      );

      dispatch(generateSyncAction(types.reset.updateCandidateTestResult));
    } catch (error) {
      dispatch(
        generateSyncAction(types.candidateTest.updateResult.failed, {
          error,
          message: "Failed to update the test result. Please try again!",
        })
      );
      dispatch(generateSyncAction(types.reset.updateCandidateTestResult));
    }
  };
}
