import endpoints from "../../config";

const appendParams = (data) => {
  const url = endpoints.EXERCISE[process.env.REACT_APP_ENV];
  if (!data) {
    return `${url}/exercise`;
  }
  const keys = Object.keys(data);
  const params = keys.map((o) => {
    return `${o}=${data[o]}`;
  });
  if (!params.length) {
    return url;
  }
  return `${url}/exercise?${params.join("&")}`;
};

const filterQuesitons = async (data) => {
  try {
    const url = appendParams(data);
    return await (await fetch(url)).json();
  } catch (error) {
    throw new Error(error);
  }
};

export default filterQuesitons;
