import classNames from "./ConnectionStatus.module.scss";
import useOnlineStatus from "../../../hooks/useOnlineStatus";

const ConnectionStatus = () => {
  const { onlineStatus, label } = useOnlineStatus();

  const className =
    onlineStatus === true ? classNames.online : classNames.offline;

  if (typeof onlineStatus === "string") return null;

  return <div className={`${classNames.wrapper} ${className}`}>{label}</div>;
};

export default ConnectionStatus;
