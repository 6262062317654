import types from "../../types";
import api from "../../../api";
import { generateSyncAction } from "../../utils.redux";

export default function getCandidatesByCompany(companyId) {
  return async function (dispatch) {
    dispatch(
      generateSyncAction(types.candidates.candidatesByCompanyId.started)
    );

    try {
      const data = await api.candidates.getCandidatesByCompanyId(companyId);
      dispatch(
        generateSyncAction(types.candidates.candidatesByCompanyId.success, data)
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.candidates.candidatesByCompanyId.failed, error)
      );
    }
  };
}
