import api from "../../../api";
import types from "../../types";
import { generateSyncAction } from "../../utils.redux";

export default function assignToAJob(input) {
  return async function (dispatch) {
    dispatch(
      generateSyncAction(types.skillTest.assignDefaultSkillTest.started)
    );

    try {
      const data = await api.skillTest.assignToAJob(input);
      dispatch(
        generateSyncAction(types.skillTest.assignDefaultSkillTest.success, data)
      );
      dispatch(generateSyncAction(types.reset.assignDefaultSkillTest));
    } catch (error) {
      dispatch(
        generateSyncAction(types.skillTest.assignDefaultSkillTest.failed, error)
      );
      dispatch(generateSyncAction(types.reset.assignDefaultSkillTest));
    }
  };
}
