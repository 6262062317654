import { Skeleton, Row } from "antd";
import classNames from "./TestLibSkeleton.module.scss";

const TestLibSkeleton = () => {
  const cards = [1, 2, 3, 4, 5, 6];
  const inputs = [1, 2];
  const buttons = [1, 2];
  return (
    <div className={classNames.wrapper}>
      <div className={classNames.header}>
        <div className={classNames.headerTop}>
          <Skeleton.Avatar
            active={true}
            shape="square"
            style={{ width: 300, height: 40, borderRadius: 5 }}
          />
          <Skeleton.Button
            active={true}
            shape="square"
            style={{ width: 160, height: 52, borderRadius: 5 }}
          />
        </div>
        <div className={classNames.headerBottom}>
          <div>
            {buttons.map((key) => (
              <Skeleton.Button
                key={key}
                active={true}
                shape="square"
                style={{ borderRadius: 5, width: 100, height: 30 }}
              />
            ))}
          </div>
          <div>
            {inputs.map((key) => (
              <Skeleton.Input
                key={key}
                active={true}
                shape="square"
                style={{ borderRadius: 5, width: 200, height: 45 }}
              />
            ))}
            <Skeleton.Input
              active={true}
              shape="square"
              style={{ borderRadius: 5, width: 300, height: 45 }}
            />
          </div>
        </div>
      </div>
      <div className={classNames.body}>
        <Row gutter={14}>
          {cards.map((key) => (
            <Skeleton.Avatar
              key={key}
              active={true}
              shape="square"
              className={classNames.card}
              style={{ width: 350, height: 272 }}
            />
          ))}
        </Row>
      </div>
    </div>
  );
};

export default TestLibSkeleton;
