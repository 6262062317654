import { Checkbox } from "antd";
import classNames from "./QuestionTypes.module.scss";

export default function MCQ({
  questionId,
  onChange,
  selectedAnswer,
  allAnswers,
}) {
  return (
    <div className={classNames.wrapper}>
      {allAnswers &&
        allAnswers.map((option, index) => {
          let isChecked = selectedAnswer
            ? option ===
              selectedAnswer.find((value) => value.option === option)?.option
            : false;
          return (
            <Checkbox
              key={index + questionId}
              onClick={() => onChange(option, index)}
              defaultChecked={isChecked}
            >
              {option}
            </Checkbox>
          );
        })}
    </div>
  );
}
