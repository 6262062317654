import classNames from "./Footer.module.scss";
import logo from "../../../../images/logo.svg";

export default function Footer() {
  return (
    <div className={classNames.wrapper}>
      <div className={classNames.footerLogo}>
        <small>Powered by Molly</small>
        <img alt="" src={logo} />
      </div>
    </div>
  );
}
