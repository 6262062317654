import { useState, useEffect } from "react";
import { notification, Spin, Modal } from "antd";
import Storage from "@aws-amplify/storage";
import { useSelector } from "react-redux";
import classNames from "./Header.module.scss";
import Countdown from "react-countdown";
import renderer from "./CountDown";
import { Title, PrimaryButton } from "../../../common";
import { ExclamationCircleFilled } from "@ant-design/icons";

export default function Header({
  numberOfMS,
  testStartTime,
  testTitle,
  job,
  submit,
  answeredQuestions,
  noOfQuestions,
  isSupervisor = false,
}) {
  const { confirm } = Modal;
  const [logoLoading, setLogoLoading] = useState(false);
  const [logoLink, setLogoLink] = useState(0);

  const company = useSelector(function ({ skillTestTempData }) {
    return skillTestTempData?.data?.company;
  });

  const handleCountdownComplete = () => {
    notification.warning({
      placement: "bottomRight",
      message: isSupervisor
        ? "Time's up for the test"
        : "Your time's up! Please submit the test soon to avoid disqualification.",
    });
  };

  const duration = () => {
    if (typeof numberOfMS === "number" && numberOfMS > 1) {
      return numberOfMS;
    } else if (typeof numberOfMS === "string") {
      return "TIME_UP";
    }
  };
  //   testStartTime.data && testStartTime.data.Item?.startTime;

  useEffect(() => {
    if (company && company.logo) {
      Storage.get(company.logo, { level: "public" })
        .then((src) => {
          setLogoLoading(false);
          setLogoLink(src);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setLogoLoading(false);
    }
  }, [company]);

  const showConfirm = () => {
    confirm({
      title: "Do you want to submit the test?",
      icon: <ExclamationCircleFilled />,
      width: 350,
      okButtonProps: {
        style: {
          backgroundColor: "#5d38db",
          borderRadius: "4px",
          borderColor: "#5d38db",
        },
      },
      cancelButtonProps: { style: { color: "#5d38db", borderRadius: "4px" } },
      onOk() {
        submit();
      },
      onCancel() {},
    });
  };

  return (
    <Spin spinning={logoLoading}>
      <div className={classNames.wrapper}>
        <div className={classNames.titleWrapper}>
          <span>{job && job.title}</span>
          <span>{testTitle}</span>
        </div>
        <div className={classNames.logo}>
          {company && company.logo ? (
            <div>
              <img src={logoLink} alt="company logo" />
            </div>
          ) : (
            <Title
              as="h3"
              style={{
                cursor: "pointer",
                opacity: " .6",
                letterSpacing: "-0.5px",
              }}
            >
              {company && company.companyName}
            </Title>
          )}
        </div>
        <div className={classNames.headerRight}>
          {/* <div className={classNames.support}>
            <a
              href={`mailto:hello+${candidateId}+${userId}@${matchEmailDomain(
                process.env.REACT_APP_ENV
              )}`}
            >
              Support
            </a>
          </div> */}
          {/* <div className={classNames.line}></div> */}
          <div>
            {testStartTime && (
              <div className={classNames.time}>
                <Countdown
                  onComplete={handleCountdownComplete}
                  date={Date.parse(testStartTime) + +duration()}
                  renderer={renderer}
                />
              </div>
            )}
          </div>
          <div>
            {!isSupervisor && (
              <PrimaryButton
                htmlType="primary"
                onClick={showConfirm}
                style={{ width: "124px" }}
                disabled={answeredQuestions?.length !== noOfQuestions}
              >
                Submit
              </PrimaryButton>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
}
