import types from "../../types";
import { generateSyncAction } from "../../utils.redux";
import API from "../../../api";

export default function updateCompanyHandler(inputs) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.company.updateCompanyHandler.started));

    try {
      const data = await API.company.updateHandler(inputs);
      dispatch(
        generateSyncAction(types.company.updateCompanyHandler.success, data)
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.company.updateCompanyHandler.failed, error)
      );
    }
  };
}
