import endpoints from "../../config";

export default async function startCandidateTest(values) {
  const url = `${endpoints.CANDIDATE_TEST[process.env.REACT_APP_ENV]}/start/`;

  const data = {
    id: `${values.id}`,
    testStatus: "started",
    expirationDate: values.expirationDate,
  };
  return await fetch(url, {
    method: "PUT",
    // headers: {
    //     "X-API-KEY": "",
    // },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => console.log("error: ", err));
}
