import { API } from "aws-amplify";
import { getCompany } from "../../graphql/queries";

export default async function fetchCompanyProfile(companyProfileId) {
  return await API.graphql({
    query: getCompany,
    variables: {
      id: companyProfileId,
    },
  });
}
