import { API } from "aws-amplify";
import { getUser } from "../../graphql/queries";

export default async function fetchUserProfile(userProfileId) {
  window.api = API;
  return await API.graphql({
    query: getUser,
    variables: {
      id: userProfileId,
    },
  });
}
