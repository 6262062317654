import classNames from "./Button.module.scss";
import { Button } from "antd";
import React from "react";

type Props = {
  children?: React.ReactNode;
  className?: string;
  onClick?: any;
  style?: React.CSSProperties;
  variant?: "primary" | "outline" | "secondary" | "ghost";
  size?: "large" | "medium" | "small" | "xsmall";
};

export default function DefaultButton({
  children,
  className,
  onClick,
  variant = "primary",
  size = "large",
  ...props
}: Props): JSX.Element {
  return (
    <Button
      {...props}
      className={`${classNames.button} ${classNames[variant]} ${classNames[size]} ${className} `}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
