import endpoints from "../config";

export default async function runCode(data) {
  let files = data?.codingQuestion?.files ?? [];
  files =
    Array.isArray(files) &&
    files
      .filter((o) => o?.content !== "")
      .map((o) => {
        const { name, content } = o;
        return {
          name,
          content,
        };
      });

  let requestBody = {
    files,
    stdin: data.stdIn ?? "",
    command: data.runCmd ?? "",
  };

  return await (
    await fetch(
      `${
        endpoints.CODE_EDITOR[process.env.REACT_APP_ENV]
      }/code/compile?language=${data?.codingLanguage}`,
      {
        method: "POST",

        body: JSON.stringify(requestBody),
      }
    )
  ).json();
}
