const endpoints = {
  ANSWERS: {
    dev: "https://r3chcv8sbg.execute-api.us-west-2.amazonaws.com/prod",
    qa: "https://zv2cww9hh3.execute-api.us-west-2.amazonaws.com/prod",
    prod: "https://1j7wi2b18f.execute-api.us-west-2.amazonaws.com/prod",
  },
  CANDIDATE_TEST: {
    dev: "https://ifs3uiknw2.execute-api.us-west-2.amazonaws.com/prod",
    qa: "https://l7ikbrbow8.execute-api.us-west-2.amazonaws.com/prod",
    prod: "https://8o03c4qqd9.execute-api.us-west-2.amazonaws.com/prod",
  },
  CODE_EDITOR: {
    dev: "https://gq5lo949sf.execute-api.us-west-2.amazonaws.com/prod/",
    qa: "https://6baq9tv2c2.execute-api.us-west-2.amazonaws.com/prod/",
    prod: "https://s2kgmlmuw6.execute-api.us-west-2.amazonaws.com/prod/",
  },
  EXERCISES: {
    dev: "https://npg2zsh1wg.execute-api.us-west-2.amazonaws.com/prod/",
    qa: "https://5utvdu533h.execute-api.us-west-2.amazonaws.com/prod/",
    prod: "https://z7fpfdyx9g.execute-api.us-west-2.amazonaws.com/prod/",
  },
  DESIGNATION: {
    dev: "https://3dasgerbel.execute-api.us-west-2.amazonaws.com/prod",
    qa: "https://uu27zqpqv1.execute-api.us-west-2.amazonaws.com/prod",
    prod: "https://7rvy27dqu9.execute-api.us-west-2.amazonaws.com/prod",
  },
  DIFFICULTY: {
    dev: "https://h2fuztvbse.execute-api.us-west-2.amazonaws.com/prod",
    qa: "https://zr0occpd86.execute-api.us-west-2.amazonaws.com/prod",
    prod: "https://v0pwnt0dh3.execute-api.us-west-2.amazonaws.com/prod",
  },
  INDUSTRY: {
    dev: "https://a1ckh8c128.execute-api.us-west-2.amazonaws.com/prod",
    qa: "https://3u062fyckb.execute-api.us-west-2.amazonaws.com/prod",
    prod: "https://5txdrt7l2d.execute-api.us-west-2.amazonaws.com/prod",
  },
  MESSAGING: {
    SEND: {
      dev: "https://i3lcbyqz7c.execute-api.us-west-2.amazonaws.com/prod/",
      qa: "https://uq4yqglzd4.execute-api.us-west-2.amazonaws.com/prod/",
      prod: "https://bwam68ae47.execute-api.us-west-2.amazonaws.com/prod/",
    },
  },
  QUESTION: {
    dev: "https://7wpq2gx8x9.execute-api.us-west-2.amazonaws.com/prod",
    qa: "https://8qtsgurkpd.execute-api.us-west-2.amazonaws.com/prod",
    prod: "https://ren6vgv2h6.execute-api.us-west-2.amazonaws.com/prod",
  },
  QUESTION_TYPE: {
    dev: "https://430qy5hxwe.execute-api.us-west-2.amazonaws.com/prod",
    qa: "https://pfjw1q9rod.execute-api.us-west-2.amazonaws.com/prod",
    prod: "https://prozz5fo88.execute-api.us-west-2.amazonaws.com/prod",
  },
  SKILL_TEST: {
    dev: "https://d85sgp2ipk.execute-api.us-west-2.amazonaws.com/prod",
    qa: "https://0pzynf2xf6.execute-api.us-west-2.amazonaws.com/prod",
    prod: "https://vpxyn4fuai.execute-api.us-west-2.amazonaws.com/prod",
  },
  TEMP_DATA: {
    dev: "https://md4dq04crd.execute-api.us-west-2.amazonaws.com/prod/",
    qa: "https://8c73lbiz28.execute-api.us-west-2.amazonaws.com/prod/",
    prod: "https://d0r3w6vrx5.execute-api.us-west-2.amazonaws.com/prod/",
  },
  EXERCISE: {
    dev: "https://npg2zsh1wg.execute-api.us-west-2.amazonaws.com/prod",
    qa: " https://5utvdu533h.execute-api.us-west-2.amazonaws.com/prod", // TODO: need to change API endpoint
    prod: "https://z7fpfdyx9g.execute-api.us-west-2.amazonaws.com/prod",
  },
  LIVE_TEST_RECORDING: {
    dev: "https://7zmqt621ud.execute-api.us-west-2.amazonaws.com/prod",
    qa: "https://5ck3gsx8ff.execute-api.us-west-2.amazonaws.com/prod",
    prod: "https://kep5t3dwn8.execute-api.us-west-2.amazonaws.com/prod",
  },
};

export default endpoints;
