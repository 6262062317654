const industries = [
  {
    id: "ae63b5fe-4681-4d8e-9a4b-8774967c7267",
    name: "biotechnology",
    title: "Biotechnology",
  },
  {
    id: "b16beaac-3e44-485d-b369-da5d4264d5cf",
    name: "computer-software",
    title: "Computer Software",
  },
  {
    id: "e4f060a1-216c-4cf2-9c94-2589d285c845",
    name: "financial-services",
    title: "Financial Services",
  },
  {
    id: "8a049355-302e-40bc-b140-9a9432c27070",
    name: "hospital-and-health-care",
    title: "Hospital and Health Care",
  },
  {
    id: "78eeb7c7-bef7-4cf5-ae71-bf21ec62dcbc",
    name: "information-technology-and-services",
    title: "Information Technology and Services",
  },
  {
    id: "b8434f13-d9b6-42dd-b420-a0e77cc8cd7b",
    name: "internet",
    title: "Internet",
  },
  {
    id: "7b65a171-c05e-4b6f-8e2e-c63983a38b22",
    name: "management-consulting",
    title: "Management Consulting",
  },
  {
    id: "ec4e88a8-ab3f-43ff-837a-b4766cdc0e01",
    name: "marketing-and-advertising",
    title: "Marketing and Advertising",
  },
  {
    id: "f0edcb47-b3bf-46cf-8ebf-5f6a7b74b909",
    name: "real-estate",
    title: "Real Estate",
  },
  {
    id: "64b5e3b4-4b75-4548-bff0-4e2cd6c3984b",
    name: "retail",
    title: "Retail",
  },
  {
    id: "c3608560-904c-4572-be8c-f000562a666e",
    name: "staffing-and-recruiting",
    title: "Staffing and Recruiting",
  },
  {
    id: "d3e242ca-169b-49e1-96ac-011101552c44",
    name: "other",
    title: "Other",
  },
];

export default industries;
