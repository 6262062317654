import FreeText from "./QTypes/FreeText";
import MCQ from "./QTypes/MCQ";
import MultiSelect from "./QTypes/MultiSelect";

type Props = {
  children: React.ReactNode;
};
const Body = ({ children }: Props) => <div>{children}</div>;

Body.FreeText = FreeText;
Body.MCQ = MCQ;
Body.MultiSelect = MultiSelect;

export default Body;
