/*
Search example by Algolia
https://codesandbox.io/p/sandbox/github/algolia/autocomplete/tree/next/examples/react-instantsearch?file=%2Fsrc%2FApp.js%3A107%2C11-107%2C17
https://codesandbox.io/p/sandbox/github/algolia/instantsearch/tree/master/examples/react/default-theme?file=%2Fsrc%2FApp.tsx
*/

import React, { useState, useEffect } from "react";
import Wrapper from "../StrictWrapper/Wrapper";
import classNames from "./SearchBar.module.scss";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { IoMdSearch } from "react-icons/io";
import { Modal } from "../../common";

import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Hits,
  SearchBox,
  Highlight,
  Configure,
  useInstantSearch,
} from "react-instantsearch";

const algoliaClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
);

// disables search by default, when the modal is opened
const searchClient = {
  ...algoliaClient,
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: "",
          params: "",
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};
// TODO: use this component when implementing auto focus for the search input when the modal is opened
// function CustomSearchBox(props) {
//   const { query, refine } = useSearchBox(props);
//   const { status } = useInstantSearch();
//   const [inputValue, setInputValue] = useState(query);
//   const inputRef = useRef(null);

//   const isSearchStalled = status === 'stalled';

//   function setQuery(newQuery) {
//     setInputValue(newQuery);

//     refine(newQuery);
//   }

//   return (
//     <div className={classNames.searchBox}>
//       <form
//         action=""
//         role="search"
//         noValidate
//         onSubmit={(event) => {
//           event.preventDefault();
//           event.stopPropagation();

//           if (inputRef.current) {
//             inputRef.current.blur();
//           }
//         }}
//         onReset={(event) => {
//           event.preventDefault();
//           event.stopPropagation();

//           setQuery('');

//           if (inputRef.current) {
//             inputRef.current.focus();
//           }
//         }}
//       >
//         <Input
//           ref={inputRef}
//           autoComplete="off"
//           autoCorrect="off"
//           autoCapitalize="off"
//           placeholder="Enter your search..."
//           spellCheck={false}
//           maxLength={512}
//           type="search"
//           value={inputValue}
//           onChange={(event) => {
//             setQuery(event.currentTarget.value);
//           }}
//           autoFocus
//         />
//         {/* <button type="submit">Submit</button> */}
//         <button
//           type="reset"
//           hidden={inputValue.length === 0 || isSearchStalled}
//         >
//           Reset
//         </button>
//         <span hidden={!isSearchStalled}>Searching…</span>
//       </form>
//     </div>
//   );
// }

function NoResults() {
  const { results } = useInstantSearch();

  if (results.query.length === 0) {
    return null;
  }

  return (
    <div className={classNames.noResults}>
      <span>No results.</span>
    </div>
  );
}

function NoResultsBoundary({ children, fallback }) {
  const { results } = useInstantSearch();

  if (!results.__isArtificial && results.nbHits === 0) {
    return fallback;
  }

  return children;
}

function Hit({ hit }) {
  return (
    <div className="hit-name" key={hit.objectID}>
      <Link to={`/candidate/${hit.objectID}/${hit.jobId}`}>
        <Highlight attribute="firstName" hit={hit} />{" "}
        <Highlight attribute="lastName" hit={hit} />
      </Link>
    </div>
  );
}
function SearchBar() {
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      setVisible(false);
    });
  }, [history]);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleKeyPress = (event) => {
    if (
      (event.key === "k" && event.metaKey) ||
      (event.key === "k" && event.ctrlKey)
    ) {
      setVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  // TODO: filter from algolia side
  const companyId = useSelector(({ company }) => company?.data?.id);

  const Placeholder = () => {
    const os = window.navigator.platform;
    const isMac = os.indexOf("Mac") !== -1;
    const icon = isMac ? <kbd>⌘</kbd> : <kbd>Ctrl</kbd>;

    return (
      <div
        className={classNames.searchPlaceholder}
        onClick={() => setVisible(true)}
      >
        <div style={{ width: "220px", opacity: "0.5" }}>
          <IoMdSearch size={22} /> Search for Candidates
        </div>
        <span className="ant-input-suffix">
          <span className="keybindings">
            <span className="ant-typography keybinding">{icon}</span>
            <span className="ant-typography keybinding">
              <kbd>K</kbd>
            </span>
          </span>
        </span>
      </div>
    );
  };

  return (
    <Wrapper classes={`${classNames.wrapper} molly__search-header`}>
      <Placeholder />
      <Modal
        getContainer={document.getElementById("root")}
        visible={visible}
        closable={false}
        footer={true}
        onCancel={handleCancel}
        onCloseClick={handleCancel}
      >
        <Modal.Body
          className={classNames.modalBody}
          style={{ padding: "10px", background: "#f5f6fa" }}
        >
          <InstantSearch
            searchClient={searchClient}
            indexName={process.env.REACT_APP_ALGOLIA_INDEX}
          >
            {/* TODO: filter from algolia side */}
            <Configure filters={`companyId:${companyId}`} />
            <SearchBox
              className={classNames.searchBox}
              autoFocus
              placeholder="Enter your search..."
            />
            {/* TODO: use this when implementing auto focus for the search input when the modal is opened */}
            {/* <CustomSearchBox visible={visible} /> */}
            <NoResultsBoundary fallback={<NoResults />}>
              <Hits className={classNames.hits} hitComponent={Hit} />
            </NoResultsBoundary>
          </InstantSearch>
        </Modal.Body>
        <Modal.Footer>
          <small>Press ESC to close</small>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  );
}

export default SearchBar;
