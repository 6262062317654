import classNames from "./Description.module.scss";

type Props = {
  children: React.ReactChildren;
  as: "div" | "section";
  style: React.CSSProperties;
};
const Description = ({
  children,
  as: El = "div",
  style,
}: Props): React.ReactNode => {
  return (
    <El className={classNames.description} style={style}>
      {children}
    </El>
  );
};

export default Description;
