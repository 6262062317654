import api from "../../../../api";
import types from "../../../types";
import { generateSyncAction } from "../../../utils.redux";

export default function getSignedURL(key) {
  return async function (dispatch) {
    dispatch(
      generateSyncAction(types.skillTest.liveRecording.getSignedURL.started)
    );
    try {
      const data = await api.liveRecording.getURL(key);
      dispatch(
        generateSyncAction(
          types.skillTest.liveRecording.getSignedURL.success,
          data
        )
      );
    } catch (error) {
      dispatch(
        generateSyncAction(
          types.skillTest.liveRecording.getSignedURL.failed,
          error
        )
      );
    }
  };
}
