import types from "../../types";
import { generateSyncAction } from "../../utils.redux";
import API from "../../../api";

export default function removeMember(input) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.user.removeMember.started));

    try {
      const data = await API.user.removeMember(input);
      dispatch(generateSyncAction(types.user.removeMember.success, data));
    } catch (error) {
      dispatch(generateSyncAction(types.user.removeMember.failed, error));
    }
  };
}
