import types from "../../types";
import { generateSyncAction } from "../../utils.redux";
import API from "../../../api";

export default function updateFeedback(inputs) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.feedback.updateFeedback.started));

    try {
      const data = await API.feedback.update(inputs);
      dispatch(generateSyncAction(types.feedback.updateFeedback.success, data));
      dispatch(generateSyncAction(types.reset.updateFeedback));
    } catch (error) {
      dispatch(generateSyncAction(types.feedback.updateFeedback.failed, error));
    }
  };
}
