import types from "../../types";

export default function changeManagerInCharge(
  state = { success: false, fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.candidates.changeManagerInCharge.started:
      return Object.assign({}, { success: false, fetching: true });

    case types.candidates.changeManagerInCharge.success:
      return Object.assign(
        {},
        {
          success: true,
          fetching: false,
          data: { ...action.data.data.updateCandidateManager },
        }
      );

    case types.candidates.changeManagerInCharge.failed:
      return Object.assign(
        {},
        { success: false, fetching: false, error: action.data.data }
      );

    case types.reset.changeManagerInCharge:
      return Object.assign(
        {},
        { success: false, fetching: false, error: false }
      );

    default:
      return state;
  }
}
