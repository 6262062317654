import classNames from "./DisabledInput.module.scss";
import React from "react";

type Props = {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  size?: "default" | "small";
};

export default function DisabledInput({
  className,
  children,
  size = "default",
  ...props
}: Props): JSX.Element {
  return (
    <div
      {...props}
      className={`${classNames.disabledInput} ${classNames[size]} ${className} `}
    >
      {children}
    </div>
  );
}
