import types from "../../types";

export default function subscriptionPlan(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.subscriptionPlan.get.started:
      return Object.assign({}, { fetching: true });

    case types.subscriptionPlan.get.success:
      return Object.assign(
        {},
        {
          fetching: false,
          data: action.data.data.getSubscriptionByCompanyId.items[0],
        }
      );

    case types.subscriptionPlan.get.failed:
      return Object.assign({}, { fetching: false, error: action.data });

    default:
      return state;
  }
}
