import types from "../types";
import api from "../../api";
import { generateSyncAction } from "../utils.redux";

export default function userSignin(username, password) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.user.signin.started));

    try {
      const data = await api.user.signin(username, password);

      dispatch(generateSyncAction(types.user.signin.success, data));
    } catch (error) {
      dispatch(generateSyncAction(types.user.signin.failed, error));
    }
  };
}
