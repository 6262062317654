const countryListAlpha3 = {
  AFG: "Afghanistan",
  ALB: "Albania",
  DZA: "Algeria",
  ASM: "American Samoa",
  AND: "Andorra",
  AGO: "Angola",
  AIA: "Anguilla",
  ATA: "Antarctica",
  ATG: "Antigua and Barbuda",
  ARG: "Argentina",
  ARM: "Armenia",
  ABW: "Aruba",
  AUS: "Australia",
  AUT: "Austria",
  AZE: "Azerbaijan",
  BHS: "Bahamas (the)",
  BHR: "Bahrain",
  BGD: "Bangladesh",
  BRB: "Barbados",
  BLR: "Belarus",
  BEL: "Belgium",
  BLZ: "Belize",
  BEN: "Benin",
  BMU: "Bermuda",
  BTN: "Bhutan",
  BOL: "Bolivia (Plurinational State of)",
  BES: "Bonaire, Sint Eustatius and Saba",
  BIH: "Bosnia and Herzegovina",
  BWA: "Botswana",
  BVT: "Bouvet Island",
  BRA: "Brazil",
  IOT: "British Indian Ocean Territory (the)",
  BRN: "Brunei Darussalam",
  BGR: "Bulgaria",
  BFA: "Burkina Faso",
  BDI: "Burundi",
  CPV: "Cabo Verde",
  KHM: "Cambodia",
  CMR: "Cameroon",
  CAN: "Canada",
  CYM: "Cayman Islands (the)",
  CAF: "Central African Republic (the)",
  TCD: "Chad",
  CHL: "Chile",
  CHN: "China",
  CXR: "Christmas Island",
  CCK: "Cocos (Keeling) Islands (the)",
  COL: "Colombia",
  COM: "Comoros (the)",
  COD: "Congo (the Democratic Republic of the)",
  COG: "Congo (the)",
  COK: "Cook Islands (the)",
  CRI: "Costa Rica",
  HRV: "Croatia",
  CUB: "Cuba",
  CUW: "Curaçao",
  CYP: "Cyprus",
  CZE: "Czechia",
  CIV: "Côte d'Ivoire",
  DNK: "Denmark",
  DJI: "Djibouti",
  DMA: "Dominica",
  DOM: "Dominican Republic (the)",
  ECU: "Ecuador",
  EGY: "Egypt",
  SLV: "El Salvador",
  GNQ: "Equatorial Guinea",
  ERI: "Eritrea",
  EST: "Estonia",
  SWZ: "Eswatini",
  ETH: "Ethiopia",
  FLK: "Falkland Islands (the) [Malvinas]",
  FRO: "Faroe Islands (the)",
  FJI: "Fiji",
  FIN: "Finland",
  FRA: "France",
  GUF: "French Guiana",
  PYF: "French Polynesia",
  ATF: "French Southern Territories (the)",
  GAB: "Gabon",
  GMB: "Gambia (the)",
  GEO: "Georgia",
  DEU: "Germany",
  GHA: "Ghana",
  GIB: "Gibraltar",
  GRC: "Greece",
  GRL: "Greenland",
  GRD: "Grenada",
  GLP: "Guadeloupe",
  GUM: "Guam",
  GTM: "Guatemala",
  GGY: "Guernsey",
  GIN: "Guinea",
  GNB: "Guinea-Bissau",
  GUY: "Guyana",
  HTI: "Haiti",
  HMD: "Heard Island and McDonald Islands",
  VAT: "Holy See (the)",
  HND: "Honduras",
  HKG: "Hong Kong",
  HUN: "Hungary",
  ISL: "Iceland",
  IND: "India",
  IDN: "Indonesia",
  IRN: "Iran (Islamic Republic of)",
  IRQ: "Iraq",
  IRL: "Ireland",
  IMN: "Isle of Man",
  ISR: "Israel",
  ITA: "Italy",
  JAM: "Jamaica",
  JPN: "Japan",
  JEY: "Jersey",
  JOR: "Jordan",
  KAZ: "Kazakhstan",
  KEN: "Kenya",
  KIR: "Kiribati",
  PRK: "Korea (the Democratic People's Republic of)",
  KOR: "Korea (the Republic of)",
  KWT: "Kuwait",
  KGZ: "Kyrgyzstan",
  LAO: "Lao People's Democratic Republic (the)",
  LVA: "Latvia",
  LBN: "Lebanon",
  LSO: "Lesotho",
  LBR: "Liberia",
  LBY: "Libya",
  LIE: "Liechtenstein",
  LTU: "Lithuania",
  LUX: "Luxembourg",
  MAC: "Macao",
  MDG: "Madagascar",
  MWI: "Malawi",
  MYS: "Malaysia",
  MDV: "Maldives",
  MLI: "Mali",
  MLT: "Malta",
  MHL: "Marshall Islands (the)",
  MTQ: "Martinique",
  MRT: "Mauritania",
  MUS: "Mauritius",
  MYT: "Mayotte",
  MEX: "Mexico",
  FSM: "Micronesia (Federated States of)",
  MDA: "Moldova (the Republic of)",
  MCO: "Monaco",
  MNG: "Mongolia",
  MNE: "Montenegro",
  MSR: "Montserrat",
  MAR: "Morocco",
  MOZ: "Mozambique",
  MMR: "Myanmar",
  NAM: "Namibia",
  NRU: "Nauru",
  NPL: "Nepal",
  NLD: "Netherlands (the)",
  NCL: "New Caledonia",
  NZL: "New Zealand",
  NIC: "Nicaragua",
  NER: "Niger (the)",
  NGA: "Nigeria",
  NIU: "Niue",
  NFK: "Norfolk Island",
  MNP: "Northern Mariana Islands (the)",
  NOR: "Norway",
  OMN: "Oman",
  PAK: "Pakistan",
  PLW: "Palau",
  PSE: "Palestine, State of",
  PAN: "Panama",
  PNG: "Papua New Guinea",
  PRY: "Paraguay",
  PER: "Peru",
  PHL: "Philippines (the)",
  PCN: "Pitcairn",
  POL: "Poland",
  PRT: "Portugal",
  PRI: "Puerto Rico",
  QAT: "Qatar",
  MKD: "Republic of North Macedonia",
  ROU: "Romania",
  RUS: "Russian Federation (the)",
  RWA: "Rwanda",
  REU: "Réunion",
  BLM: "Saint Barthélemy",
  SHN: "Saint Helena, Ascension and Tristan da Cunha",
  KNA: "Saint Kitts and Nevis",
  LCA: "Saint Lucia",
  MAF: "Saint Martin (French part)",
  SPM: "Saint Pierre and Miquelon",
  VCT: "Saint Vincent and the Grenadines",
  WSM: "Samoa",
  SMR: "San Marino",
  STP: "Sao Tome and Principe",
  SAU: "Saudi Arabia",
  SEN: "Senegal",
  SRB: "Serbia",
  SYC: "Seychelles",
  SLE: "Sierra Leone",
  SGP: "Singapore",
  SXM: "Sint Maarten (Dutch part)",
  SVK: "Slovakia",
  SVN: "Slovenia",
  SLB: "Solomon Islands",
  SOM: "Somalia",
  ZAF: "South Africa",
  SGS: "South Georgia and the South Sandwich Islands",
  SSD: "South Sudan",
  ESP: "Spain",
  LKA: "Sri Lanka",
  SDN: "Sudan (the)",
  SUR: "Suriname",
  SJM: "Svalbard and Jan Mayen",
  SWE: "Sweden",
  CHE: "Switzerland",
  SYR: "Syrian Arab Republic",
  TWN: "Taiwan (Province of China)",
  TJK: "Tajikistan",
  TZA: "Tanzania, United Republic of",
  THA: "Thailand",
  TLS: "Timor-Leste",
  TGO: "Togo",
  TKL: "Tokelau",
  TON: "Tonga",
  TTO: "Trinidad and Tobago",
  TUN: "Tunisia",
  TUR: "Turkey",
  TKM: "Turkmenistan",
  TCA: "Turks and Caicos Islands (the)",
  TUV: "Tuvalu",
  UGA: "Uganda",
  UKR: "Ukraine",
  ARE: "United Arab Emirates (the)",
  GBR: "United Kingdom of Great Britain and Northern Ireland (the)",
  UMI: "United States Minor Outlying Islands (the)",
  USA: "United States of America (the)",
  URY: "Uruguay",
  UZB: "Uzbekistan",
  VUT: "Vanuatu",
  VEN: "Venezuela (Bolivarian Republic of)",
  VNM: "Viet Nam",
  VGB: "Virgin Islands (British)",
  VIR: "Virgin Islands (U.S.)",
  WLF: "Wallis and Futuna",
  ESH: "Western Sahara",
  YEM: "Yemen",
  ZMB: "Zambia",
  ZWE: "Zimbabwe",
  ALA: "Åland Islands",
};

// const countryListAlpha3 = [
//   {
//     id: "AFG",
//     name: "afghanistan",
//     title: "Afghanistan"
//     },
//     {
//     id: "ALB",
//     name: "albania",
//     title: "Albania"
//     },
//     {
//     id: "DZA",
//     name: "algeria",
//     title: "Algeria"
//     },
//     {
//     id: "ASM",
//     name: "american-samoa",
//     title: "American Samoa"
//     },
//     {
//     id: "AND",
//     name: "andorra",
//     title: "Andorra"
//     },
//     {
//     id: "AGO",
//     name: "angola",
//     title: "Angola"
//     },
//     {
//     id: "AIA",
//     name: "anguilla",
//     title: "Anguilla"
//     },
//     {
//     id: "ATA",
//     name: "antarctica",
//     title: "Antarctica"
//     },
//     {
//     id: "ATG",
//     name: "antigua-and-barbuda",
//     title: "Antigua and Barbuda"
//     },
//     {
//     id: "ARG",
//     name: "argentina",
//     title: "Argentina"
//     },
//     {
//     id: "ARM",
//     name: "armenia",
//     title: "Armenia"
//     },
//     {
//     id: "ABW",
//     name: "aruba",
//     title: "Aruba"
//     },
//     {
//     id: "AUS",
//     name: "australia",
//     title: "Australia"
//     },
//     {
//     id: "AUT",
//     name: "austria",
//     title: "Austria"
//     },
//     {
//     id: "AZE",
//     name: "azerbaijan",
//     title: "Azerbaijan"
//     },
//     {
//     id: "BHS",
//     name: "bahamas-(the)",
//     title: "Bahamas (the)"
//     },
//     {
//     id: "BHR",
//     name: "bahrain",
//     title: "Bahrain"
//     },
//     {
//     id: "BGD",
//     name: "bangladesh",
//     title: "Bangladesh"
//     },
//     {
//     id: "BRB",
//     name: "barbados",
//     title: "Barbados"
//     },
//     {
//     id: "BLR",
//     name: "belarus",
//     title: "Belarus"
//     },
//     {
//     id: "BEL",
//     name: "belgium",
//     title: "Belgium"
//     },
//     {
//     id: "BLZ",
//     name: "belize",
//     title: "Belize"
//     },
//     {
//     id: "BEN",
//     name: "benin",
//     title: "Benin"
//     },
//     {
//     id: "BMU",
//     name: "bermuda",
//     title: "Bermuda"
//     },
//     {
//     id: "BTN",
//     name: "bhutan",
//     title: "Bhutan"
//     },
//     {
//     id: "BOL",
//     name: "bolivia-(plurinational-state-of)",
//     title: "Bolivia (Plurinational State of)"
//     },
//     {
//     id: "BES",
//     name: "bonaire,-sint-eustatius-and-saba",
//     title: "Bonaire, Sint Eustatius and Saba"
//     },
//     {
//     id: "BIH",
//     name: "bosnia-and-herzegovina",
//     title: "Bosnia and Herzegovina"
//     },
//     {
//     id: "BWA",
//     name: "botswana",
//     title: "Botswana"
//     },
//     {
//     id: "BVT",
//     name: "bouvet-island",
//     title: "Bouvet Island"
//     },
//     {
//     id: "BRA",
//     name: "brazil",
//     title: "Brazil"
//     },
//     {
//     id: "IOT",
//     name: "british-indian-ocean-territory-(the)",
//     title: "British Indian Ocean Territory (the)"
//     },
//     {
//     id: "BRN",
//     name: "brunei-darussalam",
//     title: "Brunei Darussalam"
//     },
//     {
//     id: "BGR",
//     name: "bulgaria",
//     title: "Bulgaria"
//     },
//     {
//     id: "BFA",
//     name: "burkina-faso",
//     title: "Burkina Faso"
//     },
//     {
//     id: "BDI",
//     name: "burundi",
//     title: "Burundi"
//     },
//     {
//     id: "CPV",
//     name: "cabo-verde",
//     title: "Cabo Verde"
//     },
//     {
//     id: "KHM",
//     name: "cambodia",
//     title: "Cambodia"
//     },
//     {
//     id: "CMR",
//     name: "cameroon",
//     title: "Cameroon"
//     },
//     {
//     id: "CAN",
//     name: "canada",
//     title: "Canada"
//     },
//     {
//     id: "CYM",
//     name: "cayman-islands-(the)",
//     title: "Cayman Islands (the)"
//     },
//     {
//     id: "CAF",
//     name: "central-african-republic-(the)",
//     title: "Central African Republic (the)"
//     },
//     {
//     id: "TCD",
//     name: "chad",
//     title: "Chad"
//     },
//     {
//     id: "CHL",
//     name: "chile",
//     title: "Chile"
//     },
//     {
//     id: "CHN",
//     name: "china",
//     title: "China"
//     },
//     {
//     id: "CXR",
//     name: "christmas-island",
//     title: "Christmas Island"
//     },
//     {
//     id: "CCK",
//     name: "cocos-(keeling)-islands-(the)",
//     title: "Cocos (Keeling) Islands (the)"
//     },
//     {
//     id: "COL",
//     name: "colombia",
//     title: "Colombia"
//     },
//     {
//     id: "COM",
//     name: "comoros-(the)",
//     title: "Comoros (the)"
//     },
//     {
//     id: "COD",
//     name: "congo-(the-democratic-republic-of-the)",
//     title: "Congo (the Democratic Republic of the)"
//     },
//     {
//     id: "COG",
//     name: "congo-(the)",
//     title: "Congo (the)"
//     },
//     {
//     id: "COK",
//     name: "cook-islands-(the)",
//     title: "Cook Islands (the)"
//     },
//     {
//     id: "CRI",
//     name: "costa-rica",
//     title: "Costa Rica"
//     },
//     {
//     id: "HRV",
//     name: "croatia",
//     title: "Croatia"
//     },
//     {
//     id: "CUB",
//     name: "cuba",
//     title: "Cuba"
//     },
//     {
//     id: "CUW",
//     name: "curaçao",
//     title: "Curaçao"
//     },
//     {
//     id: "CYP",
//     name: "cyprus",
//     title: "Cyprus"
//     },
//     {
//     id: "CZE",
//     name: "czechia",
//     title: "Czechia"
//     },
//     {
//     id: "CIV",
//     name: "côte-d'ivoire",
//     title: "Côte d'Ivoire"
//     },
//     {
//     id: "DNK",
//     name: "denmark",
//     title: "Denmark"
//     },
//     {
//     id: "DJI",
//     name: "djibouti",
//     title: "Djibouti"
//     },
//     {
//     id: "DMA",
//     name: "dominica",
//     title: "Dominica"
//     },
//     {
//     id: "DOM",
//     name: "dominican-republic-(the)",
//     title: "Dominican Republic (the)"
//     },
//     {
//     id: "ECU",
//     name: "ecuador",
//     title: "Ecuador"
//     },
//     {
//     id: "EGY",
//     name: "egypt",
//     title: "Egypt"
//     },
//     {
//     id: "SLV",
//     name: "el-salvador",
//     title: "El Salvador"
//     },
//     {
//     id: "GNQ",
//     name: "equatorial-guinea",
//     title: "Equatorial Guinea"
//     },
//     {
//     id: "ERI",
//     name: "eritrea",
//     title: "Eritrea"
//     },
//     {
//     id: "EST",
//     name: "estonia",
//     title: "Estonia"
//     },
//     {
//     id: "SWZ",
//     name: "eswatini",
//     title: "Eswatini"
//     },
//     {
//     id: "ETH",
//     name: "ethiopia",
//     title: "Ethiopia"
//     },
//     {
//     id: "FLK",
//     name: "falkland-islands-(the)-[malvinas]",
//     title: "Falkland Islands (the) [Malvinas]"
//     },
//     {
//     id: "FRO",
//     name: "faroe-islands-(the)",
//     title: "Faroe Islands (the)"
//     },
//     {
//     id: "FJI",
//     name: "fiji",
//     title: "Fiji"
//     },
//     {
//     id: "FIN",
//     name: "finland",
//     title: "Finland"
//     },
//     {
//     id: "FRA",
//     name: "france",
//     title: "France"
//     },
//     {
//     id: "GUF",
//     name: "french-guiana",
//     title: "French Guiana"
//     },
//     {
//     id: "PYF",
//     name: "french-polynesia",
//     title: "French Polynesia"
//     },
//     {
//     id: "ATF",
//     name: "french-southern-territories-(the)",
//     title: "French Southern Territories (the)"
//     },
//     {
//     id: "GAB",
//     name: "gabon",
//     title: "Gabon"
//     },
//     {
//     id: "GMB",
//     name: "gambia-(the)",
//     title: "Gambia (the)"
//     },
//     {
//     id: "GEO",
//     name: "georgia",
//     title: "Georgia"
//     },
//     {
//     id: "DEU",
//     name: "germany",
//     title: "Germany"
//     },
//     {
//     id: "GHA",
//     name: "ghana",
//     title: "Ghana"
//     },
//     {
//     id: "GIB",
//     name: "gibraltar",
//     title: "Gibraltar"
//     },
//     {
//     id: "GRC",
//     name: "greece",
//     title: "Greece"
//     },
//     {
//     id: "GRL",
//     name: "greenland",
//     title: "Greenland"
//     },
//     {
//     id: "GRD",
//     name: "grenada",
//     title: "Grenada"
//     },
//     {
//     id: "GLP",
//     name: "guadeloupe",
//     title: "Guadeloupe"
//     },
//     {
//     id: "GUM",
//     name: "guam",
//     title: "Guam"
//     },
//     {
//     id: "GTM",
//     name: "guatemala",
//     title: "Guatemala"
//     },
//     {
//     id: "GGY",
//     name: "guernsey",
//     title: "Guernsey"
//     },
//     {
//     id: "GIN",
//     name: "guinea",
//     title: "Guinea"
//     },
//     {
//     id: "GNB",
//     name: "guinea-bissau",
//     title: "Guinea-Bissau"
//     },
//     {
//     id: "GUY",
//     name: "guyana",
//     title: "Guyana"
//     },
//     {
//     id: "HTI",
//     name: "haiti",
//     title: "Haiti"
//     },
//     {
//     id: "HMD",
//     name: "heard-island-and-mcdonald-islands",
//     title: "Heard Island and McDonald Islands"
//     },
//     {
//     id: "VAT",
//     name: "holy-see-(the)",
//     title: "Holy See (the)"
//     },
//     {
//     id: "HND",
//     name: "honduras",
//     title: "Honduras"
//     },
//     {
//     id: "HKG",
//     name: "hong-kong",
//     title: "Hong Kong"
//     },
//     {
//     id: "HUN",
//     name: "hungary",
//     title: "Hungary"
//     },
//     {
//     id: "ISL",
//     name: "iceland",
//     title: "Iceland"
//     },
//     {
//     id: "IND",
//     name: "india",
//     title: "India"
//     },
//     {
//     id: "IDN",
//     name: "indonesia",
//     title: "Indonesia"
//     },
//     {
//     id: "IRN",
//     name: "iran-(islamic-republic-of)",
//     title: "Iran (Islamic Republic of)"
//     },
//     {
//     id: "IRQ",
//     name: "iraq",
//     title: "Iraq"
//     },
//     {
//     id: "IRL",
//     name: "ireland",
//     title: "Ireland"
//     },
//     {
//     id: "IMN",
//     name: "isle-of-man",
//     title: "Isle of Man"
//     },
//     {
//     id: "ISR",
//     name: "israel",
//     title: "Israel"
//     },
//     {
//     id: "ITA",
//     name: "italy",
//     title: "Italy"
//     },
//     {
//     id: "JAM",
//     name: "jamaica",
//     title: "Jamaica"
//     },
//     {
//     id: "JPN",
//     name: "japan",
//     title: "Japan"
//     },
//     {
//     id: "JEY",
//     name: "jersey",
//     title: "Jersey"
//     },
//     {
//     id: "JOR",
//     name: "jordan",
//     title: "Jordan"
//     },
//     {
//     id: "KAZ",
//     name: "kazakhstan",
//     title: "Kazakhstan"
//     },
//     {
//     id: "KEN",
//     name: "kenya",
//     title: "Kenya"
//     },
//     {
//     id: "KIR",
//     name: "kiribati",
//     title: "Kiribati"
//     },
//     {
//     id: "PRK",
//     name: "korea-(the-democratic-people's-republic-of)",
//     title: "Korea (the Democratic People's Republic of)"
//     },
//     {
//     id: "KOR",
//     name: "korea-(the-republic-of)",
//     title: "Korea (the Republic of)"
//     },
//     {
//     id: "KWT",
//     name: "kuwait",
//     title: "Kuwait"
//     },
//     {
//     id: "KGZ",
//     name: "kyrgyzstan",
//     title: "Kyrgyzstan"
//     },
//     {
//     id: "LAO",
//     name: "lao-people's-democratic-republic-(the)",
//     title: "Lao People's Democratic Republic (the)"
//     },
//     {
//     id: "LVA",
//     name: "latvia",
//     title: "Latvia"
//     },
//     {
//     id: "LBN",
//     name: "lebanon",
//     title: "Lebanon"
//     },
//     {
//     id: "LSO",
//     name: "lesotho",
//     title: "Lesotho"
//     },
//     {
//     id: "LBR",
//     name: "liberia",
//     title: "Liberia"
//     },
//     {
//     id: "LBY",
//     name: "libya",
//     title: "Libya"
//     },
//     {
//     id: "LIE",
//     name: "liechtenstein",
//     title: "Liechtenstein"
//     },
//     {
//     id: "LTU",
//     name: "lithuania",
//     title: "Lithuania"
//     },
//     {
//     id: "LUX",
//     name: "luxembourg",
//     title: "Luxembourg"
//     },
//     {
//     id: "MAC",
//     name: "macao",
//     title: "Macao"
//     },
//     {
//     id: "MDG",
//     name: "madagascar",
//     title: "Madagascar"
//     },
//     {
//     id: "MWI",
//     name: "malawi",
//     title: "Malawi"
//     },
//     {
//     id: "MYS",
//     name: "malaysia",
//     title: "Malaysia"
//     },
//     {
//     id: "MDV",
//     name: "maldives",
//     title: "Maldives"
//     },
//     {
//     id: "MLI",
//     name: "mali",
//     title: "Mali"
//     },
//     {
//     id: "MLT",
//     name: "malta",
//     title: "Malta"
//     },
//     {
//     id: "MHL",
//     name: "marshall-islands-(the)",
//     title: "Marshall Islands (the)"
//     },
//     {
//     id: "MTQ",
//     name: "martinique",
//     title: "Martinique"
//     },
//     {
//     id: "MRT",
//     name: "mauritania",
//     title: "Mauritania"
//     },
//     {
//     id: "MUS",
//     name: "mauritius",
//     title: "Mauritius"
//     },
//     {
//     id: "MYT",
//     name: "mayotte",
//     title: "Mayotte"
//     },
//     {
//     id: "MEX",
//     name: "mexico",
//     title: "Mexico"
//     },
//     {
//     id: "FSM",
//     name: "micronesia-(federated-states-of)",
//     title: "Micronesia (Federated States of)"
//     },
//     {
//     id: "MDA",
//     name: "moldova-(the-republic-of)",
//     title: "Moldova (the Republic of)"
//     },
//     {
//     id: "MCO",
//     name: "monaco",
//     title: "Monaco"
//     },
//     {
//     id: "MNG",
//     name: "mongolia",
//     title: "Mongolia"
//     },
//     {
//     id: "MNE",
//     name: "montenegro",
//     title: "Montenegro"
//     },
//     {
//     id: "MSR",
//     name: "montserrat",
//     title: "Montserrat"
//     },
//     {
//     id: "MAR",
//     name: "morocco",
//     title: "Morocco"
//     },
//     {
//     id: "MOZ",
//     name: "mozambique",
//     title: "Mozambique"
//     },
//     {
//     id: "MMR",
//     name: "myanmar",
//     title: "Myanmar"
//     },
//     {
//     id: "NAM",
//     name: "namibia",
//     title: "Namibia"
//     },
//     {
//     id: "NRU",
//     name: "nauru",
//     title: "Nauru"
//     },
//     {
//     id: "NPL",
//     name: "nepal",
//     title: "Nepal"
//     },
//     {
//     id: "NLD",
//     name: "netherlands-(the)",
//     title: "Netherlands (the)"
//     },
//     {
//     id: "NCL",
//     name: "new-caledonia",
//     title: "New Caledonia"
//     },
//     {
//     id: "NZL",
//     name: "new-zealand",
//     title: "New Zealand"
//     },
//     {
//     id: "NIC",
//     name: "nicaragua",
//     title: "Nicaragua"
//     },
//     {
//     id: "NER",
//     name: "niger-(the)",
//     title: "Niger (the)"
//     },
//     {
//     id: "NGA",
//     name: "nigeria",
//     title: "Nigeria"
//     },
//     {
//     id: "NIU",
//     name: "niue",
//     title: "Niue"
//     },
//     {
//     id: "NFK",
//     name: "norfolk-island",
//     title: "Norfolk Island"
//     },
//     {
//     id: "MNP",
//     name: "northern-mariana-islands-(the)",
//     title: "Northern Mariana Islands (the)"
//     },
//     {
//     id: "NOR",
//     name: "norway",
//     title: "Norway"
//     },
//     {
//     id: "OMN",
//     name: "oman",
//     title: "Oman"
//     },
//     {
//     id: "PAK",
//     name: "pakistan",
//     title: "Pakistan"
//     },
//     {
//     id: "PLW",
//     name: "palau",
//     title: "Palau"
//     },
//     {
//     id: "PSE",
//     name: "palestine,-state-of",
//     title: "Palestine, State of"
//     },
//     {
//     id: "PAN",
//     name: "panama",
//     title: "Panama"
//     },
//     {
//     id: "PNG",
//     name: "papua-new-guinea",
//     title: "Papua New Guinea"
//     },
//     {
//     id: "PRY",
//     name: "paraguay",
//     title: "Paraguay"
//     },
//     {
//     id: "PER",
//     name: "peru",
//     title: "Peru"
//     },
//     {
//     id: "PHL",
//     name: "philippines-(the)",
//     title: "Philippines (the)"
//     },
//     {
//     id: "PCN",
//     name: "pitcairn",
//     title: "Pitcairn"
//     },
//     {
//     id: "POL",
//     name: "poland",
//     title: "Poland"
//     },
//     {
//     id: "PRT",
//     name: "portugal",
//     title: "Portugal"
//     },
//     {
//     id: "PRI",
//     name: "puerto-rico",
//     title: "Puerto Rico"
//     },
//     {
//     id: "QAT",
//     name: "qatar",
//     title: "Qatar"
//     },
//     {
//     id: "MKD",
//     name: "republic-of-north-macedonia",
//     title: "Republic of North Macedonia"
//     },
//     {
//     id: "ROU",
//     name: "romania",
//     title: "Romania"
//     },
//     {
//     id: "RUS",
//     name: "russian-federation-(the)",
//     title: "Russian Federation (the)"
//     },
//     {
//     id: "RWA",
//     name: "rwanda",
//     title: "Rwanda"
//     },
//     {
//     id: "REU",
//     name: "réunion",
//     title: "Réunion"
//     },
//     {
//     id: "BLM",
//     name: "saint-barthélemy",
//     title: "Saint Barthélemy"
//     },
//     {
//     id: "SHN",
//     name: "saint-helena,-ascension-and-tristan-da-cunha",
//     title: "Saint Helena, Ascension and Tristan da Cunha"
//     },
//     {
//     id: "KNA",
//     name: "saint-kitts-and-nevis",
//     title: "Saint Kitts and Nevis"
//     },
//     {
//     id: "LCA",
//     name: "saint-lucia",
//     title: "Saint Lucia"
//     },
//     {
//     id: "MAF",
//     name: "saint-martin-(french-part)",
//     title: "Saint Martin (French part)"
//     },
//     {
//     id: "SPM",
//     name: "saint-pierre-and-miquelon",
//     title: "Saint Pierre and Miquelon"
//     },
//     {
//     id: "VCT",
//     name: "saint-vincent-and-the-grenadines",
//     title: "Saint Vincent and the Grenadines"
//     },
//     {
//     id: "WSM",
//     name: "samoa",
//     title: "Samoa"
//     },
//     {
//     id: "SMR",
//     name: "san-marino",
//     title: "San Marino"
//     },
//     {
//     id: "STP",
//     name: "sao-tome-and-principe",
//     title: "Sao Tome and Principe"
//     },
//     {
//     id: "SAU",
//     name: "saudi-arabia",
//     title: "Saudi Arabia"
//     },
//     {
//     id: "SEN",
//     name: "senegal",
//     title: "Senegal"
//     },
//     {
//     id: "SRB",
//     name: "serbia",
//     title: "Serbia"
//     },
//     {
//     id: "SYC",
//     name: "seychelles",
//     title: "Seychelles"
//     },
//     {
//     id: "SLE",
//     name: "sierra-leone",
//     title: "Sierra Leone"
//     },
//     {
//     id: "SGP",
//     name: "singapore",
//     title: "Singapore"
//     },
//     {
//     id: "SXM",
//     name: "sint-maarten-(dutch-part)",
//     title: "Sint Maarten (Dutch part)"
//     },
//     {
//     id: "SVK",
//     name: "slovakia",
//     title: "Slovakia"
//     },
//     {
//     id: "SVN",
//     name: "slovenia",
//     title: "Slovenia"
//     },
//     {
//     id: "SLB",
//     name: "solomon-islands",
//     title: "Solomon Islands"
//     },
//     {
//     id: "SOM",
//     name: "somalia",
//     title: "Somalia"
//     },
//     {
//     id: "ZAF",
//     name: "south-africa",
//     title: "South Africa"
//     },
//     {
//     id: "SGS",
//     name: "south-georgia-and-the-south-sandwich-islands",
//     title: "South Georgia and the South Sandwich Islands"
//     },
//     {
//     id: "SSD",
//     name: "south-sudan",
//     title: "South Sudan"
//     },
//     {
//     id: "ESP",
//     name: "spain",
//     title: "Spain"
//     },
//     {
//     id: "LKA",
//     name: "sri-lanka",
//     title: "Sri Lanka"
//     },
//     {
//     id: "SDN",
//     name: "sudan-(the)",
//     title: "Sudan (the)"
//     },
//     {
//     id: "SUR",
//     name: "suriname",
//     title: "Suriname"
//     },
//     {
//     id: "SJM",
//     name: "svalbard-and-jan-mayen",
//     title: "Svalbard and Jan Mayen"
//     },
//     {
//     id: "SWE",
//     name: "sweden",
//     title: "Sweden"
//     },
//     {
//     id: "CHE",
//     name: "switzerland",
//     title: "Switzerland"
//     },
//     {
//     id: "SYR",
//     name: "syrian-arab-republic",
//     title: "Syrian Arab Republic"
//     },
//     {
//     id: "TWN",
//     name: "taiwan-(province-of-china)",
//     title: "Taiwan (Province of China)"
//     },
//     {
//     id: "TJK",
//     name: "tajikistan",
//     title: "Tajikistan"
//     },
//     {
//     id: "TZA",
//     name: "tanzania,-united-republic-of",
//     title: "Tanzania, United Republic of"
//     },
//     {
//     id: "THA",
//     name: "thailand",
//     title: "Thailand"
//     },
//     {
//     id: "TLS",
//     name: "timor-leste",
//     title: "Timor-Leste"
//     },
//     {
//     id: "TGO",
//     name: "togo",
//     title: "Togo"
//     },
//     {
//     id: "TKL",
//     name: "tokelau",
//     title: "Tokelau"
//     },
//     {
//     id: "TON",
//     name: "tonga",
//     title: "Tonga"
//     },
//     {
//     id: "TTO",
//     name: "trinidad-and-tobago",
//     title: "Trinidad and Tobago"
//     },
//     {
//     id: "TUN",
//     name: "tunisia",
//     title: "Tunisia"
//     },
//     {
//     id: "TUR",
//     name: "turkey",
//     title: "Turkey"
//     },
//     {
//     id: "TKM",
//     name: "turkmenistan",
//     title: "Turkmenistan"
//     },
//     {
//     id: "TCA",
//     name: "turks-and-caicos-islands-(the)",
//     title: "Turks and Caicos Islands (the)"
//     },
//     {
//     id: "TUV",
//     name: "tuvalu",
//     title: "Tuvalu"
//     },
//     {
//     id: "UGA",
//     name: "uganda",
//     title: "Uganda"
//     },
//     {
//     id: "UKR",
//     name: "ukraine",
//     title: "Ukraine"
//     },
//     {
//     id: "ARE",
//     name: "united-arab-emirates-(the)",
//     title: "United Arab Emirates (the)"
//     },
//     {
//     id: "GBR",
//     name: "united-kingdom-of-great-britain-and-northern-ireland-(the)",
//     title: "United Kingdom of Great Britain and Northern Ireland (the)"
//     },
//     {
//     id: "UMI",
//     name: "united-states-minor-outlying-islands-(the)",
//     title: "United States Minor Outlying Islands (the)"
//     },
//     {
//     id: "USA",
//     name: "united-states-of-america-(the)",
//     title: "United States of America (the)"
//     },
//     {
//     id: "URY",
//     name: "uruguay",
//     title: "Uruguay"
//     },
//     {
//     id: "UZB",
//     name: "uzbekistan",
//     title: "Uzbekistan"
//     },
//     {
//     id: "VUT",
//     name: "vanuatu",
//     title: "Vanuatu"
//     },
//     {
//     id: "VEN",
//     name: "venezuela-(bolivarian-republic-of)",
//     title: "Venezuela (Bolivarian Republic of)"
//     },
//     {
//     id: "VNM",
//     name: "viet-nam",
//     title: "Viet Nam"
//     },
//     {
//     id: "VGB",
//     name: "virgin-islands-(british)",
//     title: "Virgin Islands (British)"
//     },
//     {
//     id: "VIR",
//     name: "virgin-islands-(u.s.)",
//     title: "Virgin Islands (U.S.)"
//     },
//     {
//     id: "WLF",
//     name: "wallis-and-futuna",
//     title: "Wallis and Futuna"
//     },
//     {
//     id: "ESH",
//     name: "western-sahara",
//     title: "Western Sahara"
//     },
//     {
//     id: "YEM",
//     name: "yemen",
//     title: "Yemen"
//     },
//     {
//     id: "ZMB",
//     name: "zambia",
//     title: "Zambia"
//     },
//     {
//     id: "ZWE",
//     name: "zimbabwe",
//     title: "Zimbabwe"
//   },
//     {
//     id: "ALA",
//     name: "åland-islands",
//     title: "Åland Islands"
//     }
// ]

// function print(){

//   Object.entries(countryListAlpha3).map(function([key, value]){
//     let name = value.replace(/\s/g,"-").toLowerCase()
//     let matchKey = "{\nid: " + '"'+[key]+'",' + "\nname: " + '"'+[name]+'",' + "\ntitle: " + '"'+[value]+'"\n},'
//     console.log(matchKey)
//   })
// }

// print()

export default countryListAlpha3;
