import { Input } from "antd";
import classNames from "./QuestionTypes.module.scss";

export default function FreeText({ questionId, onChange, selectedAnswer }) {
  return (
    <div>
      <Input.TextArea
        name={`answerFor${questionId}`}
        key={questionId}
        onChange={(e) => onChange(e.target.value)}
        defaultValue={
          selectedAnswer && typeof selectedAnswer[0] === "string"
            ? selectedAnswer[0]
            : ""
        }
        className={classNames.textarea}
        autoSize={{ minRows: 5, maxRows: 12 }}
        maxLength={5000}
      />
    </div>
  );
}
