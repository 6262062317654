import React from "react";
import Wrapper from "../StrictWrapper/Wrapper";
import classNames from "./Header.module.scss";

/**
 * Function representing Header component.
 * @param {Object} param0 Component props.
 */
function Header({ children }) {
  return <Wrapper classes={classNames.wrapper}>{children}</Wrapper>;
}

export default Header;
