import classNames from "./Header.module.scss";

type Props = {
  children: React.ReactNode;
  style: React.CSSProperties;
};

const Header = ({ children, style }: Props) => {
  return (
    <div style={style} className={classNames.wrapper}>
      {children}
    </div>
  );
};

export default Header;
