import classNames from "./Sidebar.module.scss";
import Question from "./Question";

export default function Sidebar({
  changeCurrentQuestion,
  allQ,
  currentQuestion,
}) {
  function handleClick(question) {
    changeCurrentQuestion(question);
  }

  return (
    <div className={classNames.wrapper}>
      {allQ &&
        allQ.map((question, i) => {
          return (
            <Question
              key={question && question.id}
              index={i + 1}
              answer={question && question.answer}
              onClick={handleClick}
              obj={question}
              currentQue={currentQuestion}
            />
          );
        })}
    </div>
  );
}
