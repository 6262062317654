/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, useReducer } from "react";
import { useSelector } from "react-redux";
import classNames from "./AV.module.scss";
import { FiVideo, FiVideoOff } from "react-icons/fi";
import { BsMic, BsMicMute } from "react-icons/bs";
import {
  AgoraVideoPlayer,
  createClient,
  createMicrophoneAndCameraTracks,
} from "agora-rtc-react";
import moment from "moment";
import "moment-timezone";
import ScreenShareFeed from "./ScreenShareFeed";
import VideoFeed from "./VideoFeed";
import TestLobbyPage from "../TestLobbyPage";
import { Loader, ConnectionStatus } from "../../common";
import { types } from "../../../redux";
import api from "../../../api";
import camStateReducer from "./reducers/camState.reducer";
import axios from "axios";
import endpoints from "../../../api/config";

const config = {
  mode: "rtc",
  codec: "vp8",
};

const appId = process.env.REACT_APP_AGORA_APP_ID;
const token = null;
/**
 * Commenting for future use
const apiUrl = endpoints.LIVE_TEST_RECORDING[process.env.REACT_APP_ENV];
 */
const supervisorTimeZone = moment.tz.guess(true);

const AVModule = ({
  candidateName,
  candidateTestData,
  setCandidateTestData,
  candidateTestId,
  candidateJoined,
  setCandidateJoined,
  setTestStartTime,
}) => {
  const candidateTest = useSelector(
    (state) => state?.getCandidateTestById?.data
  );
  const [callOnline, setCallOnline] = useState();

  if (callOnline === "expiredEnd") {
    setTestStartTime(null);
    return <TestLobbyPage testExpired />;
  }

  return (
    <VideoCall
      channelName={candidateTestId}
      candidateName={candidateName}
      candidateTest={candidateTest}
      candidateTestData={candidateTestData}
      setCandidateTestData={setCandidateTestData}
      setCallOnline={setCallOnline}
      candidateJoined={candidateJoined}
      setCandidateJoined={setCandidateJoined}
      setTestStartTime={setTestStartTime}
    />
  );
};

// this hook can be used the get the client/stream in any component
const useClient = createClient(config);
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

const VideoCall = (props) => {
  const {
    channelName,
    candidateName,
    candidateTest,
    candidateTestData,
    setCandidateTestData,
    setCallOnline,
    candidateJoined,
    setCandidateJoined,
    setTestStartTime,
  } = props;
  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  /**
   * Commenting for future use
  const [videoCallStatus, setVideoCallStatus] = useState(undefined);
   */
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [hasScreenSharingUser, setHasScreenSharingUser] = useState(false);

  useEffect(() => {
    if (users) {
      let screenShareUsers = users.filter(
        (user) => user.uid === +process.env.REACT_APP_CANDIDATE_SCREEN_UID
      );
      setFilteredUsers(screenShareUsers);
    }
  }, [users]);

  useEffect(() => {
    if (filteredUsers.length > 0) {
      setHasScreenSharingUser(true);
    } else setHasScreenSharingUser(false);
  }, [filteredUsers]);

  const client = useClient();
  // ready is a state variable, which returns true when the local tracks are initialized, untill then tracks variable is null
  const { ready, tracks } = useMicrophoneAndCameraTracks();

  const fetchingCandidateTest = useSelector(
    ({ getCandidateTestById }) => getCandidateTestById?.fetching
  );

  useEffect(() => {
    // function to initialise the SDK
    let init = async (name) => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        if (mediaType === "video") {
          setUsers((prevUsers) => {
            return [...prevUsers, user];
          });
        }
        if (mediaType === "audio") {
          user.audioTrack?.play();
        }
        if (
          user.uid === Number(process.env.REACT_APP_CANDIDATE_UID) ||
          Number(process.env.REACT_APP_CANDIDATE_SCREEN_UID) ||
          10001
        ) {
          setCandidateJoined(true);
        }
      });

      client.on("user-unpublished", (user, type) => {
        if (type === "audio") {
          user.audioTrack?.stop();
        }
        if (type === "video") {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
        }
      });

      client.on("user-left", (user) => {
        if (user.uid === +process.env.REACT_APP_CANDIDATE_UID) {
          setTimeout(async () => {
            const data = await api.candidateTest.get(channelName);
            setCandidateTestData(data.data);
            if (data.data === types.testCompleted.duration) {
              setCallOnline("expiredEnd");
              leaveChannel();
            }
          }, 5000);
        }
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      await client.join(
        appId,
        name,
        token,
        +process.env.REACT_APP_SUPERVISOR_UID
      );
      if (tracks) await client.publish([tracks[0], tracks[1]]);
      setStart(true);
    };

    if (ready && tracks) {
      init(channelName);
    }
  }, [channelName, client, ready, tracks]);

  const leaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    tracks[0].setEnabled(false);
    tracks[1].setEnabled(false);
    tracks[0].close();
    tracks[1].close();
    setStart(false);
  };

  useEffect(() => {
    if (candidateJoined && candidateTestData === types.testCompleted.status) {
      if (Array.isArray(users) && users.length === 0) {
        leaveChannel();
      }
    }
  }, [users, candidateTestData]);

  /**
   * Dispatch method used to getCandidateTestById moved to
   * useEffect hook below to avoid unneccasary renders occured
   * next environment.
   * useEffect(() => {
   *   const timer = setTimeout(() => {
   *     if (channelName && candidateJoined && !candidateTest.sid) {
   *       dispatch(actions.getCandidateTestById(channelName));
   *     }
   *   }, 3000);
   *   return () => clearTimeout(timer);
   * }, [channelName, candidateJoined, dispatch]);
   */

  useEffect(() => {
    if (
      candidateTest?.sid &&
      candidateTest?.resourceId &&
      moment
        .tz(candidateTest?.scheduledDate, supervisorTimeZone)
        .endOf("minutes")
        .isBefore()
    ) {
      // queryStatus();
    }
  }, [candidateTest?.sid, candidateTest?.resourceId]);

  if (!candidateJoined && !candidateTest?.sid) {
    const TestLobby = (
      <TestLobbyPage candidate={candidateName} ready={ready} tracks={tracks} />
    );
    if (
      candidateTest === types.testCompleted.duration &&
      !candidateJoined &&
      !users.length
    ) {
      const apiUrl = endpoints.LIVE_TEST_RECORDING[process.env.REACT_APP_ENV];
      channelName &&
        api.candidateTest.get(channelName).then(async (data) => {
          setTestStartTime(data?.data.startTime || data?.result.startTime);
          await axios
            .post(`${apiUrl}/query`, {
              resource: data?.data.resourceId || data?.result.resourceId,
              sid: data?.data.sid || data?.result.sid,
            })
            .then((data) => {
              if (
                data.data.status === types.testCompleted.agoraVideoInprogress
              ) {
                return TestLobby;
              } else if (
                data.data.status === types.testCompleted.agoraVideoEnded
              ) {
                return <TestLobbyPage testExpired />;
              } else return <TestLobbyPage testExpired />;
            });
        });
    } else if (
      candidateTest === types.testCompleted.duration &&
      candidateJoined &&
      users.length
    ) {
      return TestLobby;
    } else {
      return TestLobby;
    }
  }

  if (fetchingCandidateTest) {
    return <Loader />;
  }

  return (
    <div className={classNames.supervisorViewContainer}>
      <ConnectionStatus />
      {hasScreenSharingUser && (
        <h4 className={classNames.titleText}>
          You are observing {`${props.candidateName}`}’s screen
        </h4>
      )}
      {start && tracks && (
        <Videos
          users={users}
          tracks={tracks}
          channelName={channelName}
          setStart={setStart}
          candidateName={candidateName}
          hasScreenSharingUser={hasScreenSharingUser}
          candidate={candidateJoined}
          filteredUsers={filteredUsers}
        />
      )}
    </div>
  );
};

const Videos = (props) => {
  const {
    users,
    tracks,
    channelName,
    setStart,
    candidateName,
    candidate,
    hasScreenSharingUser,
    filteredUsers,
  } = props;
  const cardRef = useRef(null);

  const initialSupervisorCamState = { isOn: true };

  const [supervisorCamState, dispatch] = useReducer(
    camStateReducer,
    initialSupervisorCamState
  );

  const url = window.location.pathname;
  const regex = /\/([^/]+)\/([^/]+)$/;
  const match = url && regex.exec(url);
  const param = match?.[2]?.replace("+", " ") || "S";

  const supervisorName = useSelector(
    (state) => state.getCandidateTestById?.data?.supervisorName || param
  );

  const otherUsers = users.filter(
    (user) => user.uid !== +process.env.REACT_APP_CANDIDATE_SCREEN_UID
  );

  return (
    <div
      id="videos"
      className={
        hasScreenSharingUser
          ? classNames.fullscreen
          : classNames.vidWrapperWhenNoScreen
      }
    >
      {!hasScreenSharingUser && candidate && users.length === 0 && (
        <div className={classNames.videoWrapperSupervisorWhenNoScreen}>
          <div className={classNames.noScreenVideoWhenNoScreen}>
            <span className={classNames.largeCircleWhenNoScreen}>
              {candidateName[0]?.charAt(0)}
            </span>
          </div>
          <h6 className={classNames.videoName}>{candidateName}</h6>
        </div>
      )}

      {!hasScreenSharingUser && users.length > 0 && otherUsers && (
        <div className={classNames.videoSzSupervisorWhenNoScreen}>
          {otherUsers.map((user) => {
            if (user.videoTrack) {
              return (
                <>
                  <AgoraVideoPlayer
                    style={{ height: "100%", width: "100%" }}
                    className="video-feed-no-screen"
                    videoTrack={user.videoTrack}
                    key={user.uid}
                  />
                  <h6 className={classNames.videoName}>{candidateName}</h6>
                </>
              );
            } else return null;
          })}
        </div>
      )}

      {hasScreenSharingUser && (
        <ScreenShareFeed
          classNames={classNames}
          filteredUsers={filteredUsers}
        />
      )}

      <div
        className={
          hasScreenSharingUser
            ? classNames.vidWrapper
            : classNames.vidWrapperWhenNoScreen
        }
      >
        <VideoFeed
          classNames={classNames}
          users={users}
          candidate={candidate}
          candidateName={candidateName}
        />
        <div
          ref={cardRef}
          className={
            hasScreenSharingUser
              ? classNames.videoSzSupervisor
              : classNames.vidWrapperWhenNoScreenSz
          }
          style={{ cursor: "unset" }}
        >
          {supervisorCamState.isOn ? (
            <AgoraVideoPlayer
              id="player"
              className={
                hasScreenSharingUser
                  ? "video-feed-supervisor"
                  : "video-feed-no-screen"
              }
              videoTrack={tracks[1]}
              style={{
                borderRadius: "15px",
                overflow: "hidden",
              }}
            />
          ) : (
            <div
              className={
                hasScreenSharingUser
                  ? "video-feed-supervisor"
                  : classNames.videoWrapperSupervisorWhenNoScreen
              }
            >
              <div className={classNames.noScreenVideoWhenNoScreen}>
                <span
                  className={
                    hasScreenSharingUser
                      ? classNames.smallCircleWhenScreen
                      : classNames.largeCircleWhenNoScreen
                  }
                >
                  {supervisorName?.[0]?.charAt(0)}
                </span>
              </div>
            </div>
          )}
          <Controls
            tracks={tracks}
            setStart={setStart}
            channelName={channelName}
            dispatch={dispatch}
          />
          <h6 className={classNames.videoName}>{supervisorName}</h6>
        </div>
      </div>
    </div>
  );
};

export const Controls = (props) => {
  const { tracks, dispatch } = props;
  const [trackState, setTrackState] = useState({ video: true, audio: true });

  const micVidState = {
    mic: localStorage.getItem("audio") || false,
    cam: localStorage.getItem("video") || false,
  };

  const mute = async (type) => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      dispatch({ type: "setSupervisorCameState", payload: !trackState.video });
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    }
  };

  useEffect(() => {
    if (tracks?.[0] && !micVidState.mic) {
      mute("audio");
    }
    if (tracks?.[1] && !micVidState.cam) {
      mute("video");
    }
  }, []);

  return (
    <div className={classNames.controlWrapperSz}>
      <div className={classNames.controls} onClick={() => mute("video")}>
        {trackState.video ? (
          <FiVideo color="white" size="21px" />
        ) : (
          <FiVideoOff color="white" size="21px" />
        )}
      </div>
      <div className={classNames.controls} onClick={() => mute("audio")}>
        {trackState.audio ? (
          <BsMic color="white" size="21px" />
        ) : (
          <BsMicMute color="white" size="21px" />
        )}
      </div>
    </div>
  );
};

export default AVModule;
