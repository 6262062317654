import classNames from "./Tag.module.scss";
import { Tag as AntTag } from "antd";

export default function Tag({ children, className, primary, style, ...props }) {
  return (
    <AntTag
      {...props}
      style={{ ...style }}
      className={`${className} ${classNames.tag} ${
        primary ? classNames.primary : ""
      }`}
    >
      {children}
    </AntTag>
  );
}
