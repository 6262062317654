import types from "../../../types";

export default function listCandidateTestsByCandidateId(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.candidateTest.list.started:
      return Object.assign({}, { fetching: true });

    case types.candidateTest.list.success:
      return Object.assign(
        {},
        { fetching: false, success: true, data: { ...action.data } }
      );

    case types.candidateTest.list.failed:
      return Object.assign({}, { fetching: false, success: false });

    default:
      return state;
  }
}
