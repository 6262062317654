import api from "../../../../api";
import types from "../../../types";
import { generateSyncAction } from "../../../utils.redux";

export default function getAllTestDuration() {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.testDuration.getAllTestDuration.started));

    try {
      const data = await api.testDuration.list();
      dispatch(
        generateSyncAction(types.testDuration.getAllTestDuration.success, data)
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.testDuration.getAllTestDuration.failed, error)
      );
    }
  };
}
