import types from "../../types";

export default function createJob(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.jobs.createJob.started:
      return Object.assign({}, { fetching: true });

    case types.jobs.createJob.success:
      return Object.assign(
        {},
        { fetching: false, data: action.data.data.createJob }
      );

    case types.jobs.createJob.failed:
      if (action.data.data.createJob.id) {
        return Object.assign(
          {},
          {
            fetching: false,
            data: action.data.data.createJob,
            partialError: true,
            error: action.data.errors,
          }
        );
      }
      return Object.assign(
        {},
        {
          fetching: false,
          error: action.data.errors,
        }
      );

    default:
      return state;
  }
}
