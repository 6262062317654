import types from "../../types";

export default function listInterviewsByCompanyId(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.candidates.listInterviewsByCompanyId.started:
      return Object.assign({}, { fetching: true });

    case types.candidates.listInterviewsByCompanyId.success:
      return Object.assign(
        {},
        {
          fetching: false,
          data: [...action.data.data.listInterviewsByCompanyId.items],
        }
      );

    case types.candidates.listInterviewsByCompanyId.failed:
      return Object.assign({}, { fetching: false, error: action.data.data });

    default:
      return state;
  }
}
