import classNames from "./CompleteProfile.module.scss";
import ac from "../../redux/actions";
import mollyAvatar from "../../images/Signin/molly-avatar.svg";
import mollyText from "../../images/Signin/molly-text.svg";
import companySizes from "./companySizes.data";
import { countryList } from "../data";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { validation } from "./config";
import { useEffect, useState } from "react";
import { industries } from "./config";
import { stages } from "./config";
import { Title, PrimaryButton, Input, Select } from "../common/index.js";

export default function CompleteProfile(props) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [disable, setDisable] = useState(true);

  const { attributes: userAttribute } = useSelector(function ({
    signin: { data },
  }) {
    return data ? data : {};
  });

  const fetching = useSelector(function ({
    createOnBoardingProfiles: { fetching },
  }) {
    return fetching;
  });

  const data = useSelector(function ({ createOnBoardingProfiles: { data } }) {
    return data;
  });

  const user = useSelector(function ({ user: { data } }) {
    return data;
  });

  const companyId = useSelector(function ({ company }) {
    return company.data ? company.data.id : undefined;
  });

  const onBoardingSteps = useSelector(function ({ company }) {
    return company.data ? company.data.steps : [];
  });

  // const role = useSelector((state) =>
  //   state.userProfile.userRole ? state.userProfile.userRole.id : null
  // );

  // const stages = useSelector(({ companyStages }) => companyStages.list);

  // useEffect(() => {
  //   if (wasCompanyCreated) {
  //     dispatch(getComapany());
  //     dispatch(getUserProfile());
  //   }
  // }, [dispatch, wasCompanyCreated]);

  /**
   * Handles form submition
   * @param {Object} event Event triggered by form submition
   */
  function handleSubmit(data) {
    const inputs = {
      active: true,
      address: data.companyAddress,
      approved: false,
      companyEmail: data.companyEmail,
      companyName: data.companyName,
      country: data.country,
      currentStage: data.currentStage,
      deleted: false,
      email: userAttribute.email,
      firstName: data.firstName,
      industry: data.companyIndustry,
      lastName: data.lastName,
      phone: data.companyPhone,
      size: data.companySize,
      website: data.companyWebsite,
      companyId: user.companyId,
      ownerId: user.id,
    };

    dispatch(ac.createOnBoardingProfiles(inputs));
    // dispatch(ac.updateProfileCompletionCognito());

    if (!JSON.parse(onBoardingSteps).step1) {
      const stepInput = {
        steps: JSON.stringify({
          step1: true,
          step2: JSON.parse(onBoardingSteps).step2,
          step3: JSON.parse(onBoardingSteps).step3,
        }),
        companyId,
      };

      dispatch(ac.completeOnBoardingSteps(stepInput));
    }
  }

  useEffect(
    function () {
      if (data && data.createOnboardingProfiles.profileCreated) {
        window.location.replace("/profile-created");
      }
    },
    [data]
  );
  return (
    <div className={classNames.wrapper}>
      <div>
        <div className={classNames.logoWrapper}>
          <img src={mollyAvatar} alt="Molly logo" className={classNames.logo} />
        </div>

        <Title
          as="h1"
          style={{
            fontSize: "36px",
            textAlign: "center",
            marginTop: 0,
            marginBottom: 30,
          }}
        >
          Finish account setup
        </Title>

        <Form
          onFinish={handleSubmit}
          className={classNames.form}
          form={form}
          layout="vertical"
          hideRequiredMark
          initialValues={{ country: "USA", companySize: "1-10" }}
        >
          {/* {error && <Alert type="error" message={alerts.error.createCompany} />} */}

          <div className={classNames.nameFields}>
            <Form.Item
              label={
                <Title as="h5" className={classNames.fieldLabels}>
                  Your First name <sup style={{ top: 1 }}>*</sup>
                </Title>
              }
              name="firstName"
              rules={[
                { required: true, message: validation.firstName },
                { max: 100, message: "Max 100 characters are allowed" },
              ]}
            >
              <Input
                placeholder=""
                className={classNames.input}
                onClick={() => setDisable(false)}
              />
            </Form.Item>

            <Form.Item
              label={
                <Title as="h5" className={classNames.fieldLabels}>
                  Last name <sup style={{ top: 1 }}>*</sup>
                </Title>
              }
              name="lastName"
              rules={[
                { required: true, message: validation.lastName },
                { max: 100, message: "Max 100 characters are allowed" },
              ]}
            >
              <Input
                placeholder=""
                className={classNames.input}
                onClick={() => setDisable(false)}
              />
            </Form.Item>
          </div>

          {/* {role && role === 1 && ( */}
          <>
            <div>
              <Form.Item
                label={
                  <Title as="h5" className={classNames.fieldLabels}>
                    Company Name <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: validation.companyName,
                  },
                  {
                    max: 200,
                    message: "Max 200 characters are allowed",
                  },
                ]}
              >
                <Input
                  placeholder=""
                  className={classNames.input}
                  onClick={() => setDisable(false)}
                />
              </Form.Item>

              <Form.Item
                label={
                  <Title as="h5" className={classNames.fieldLabels}>
                    Company Email <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                name="companyEmail"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                  {
                    required: true,
                    message: validation.companyEmail,
                  },
                ]}
              >
                <Input
                  placeholder=""
                  className={classNames.input}
                  onClick={() => setDisable(false)}
                />
              </Form.Item>

              <Form.Item
                label={
                  <Title as="h5" className={classNames.fieldLabels}>
                    Company Website <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                name="companyWebsite"
                rules={[
                  {
                    pattern: /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
                    message: "Please enter a valid web address",
                  },
                  {
                    required: true,
                    message: validation.companyWebsite,
                  },
                  {
                    max: 255,
                    message: "Max 255 characters are allowed",
                  },
                ]}
              >
                <Input
                  placeholder="https://www.example.com"
                  className={classNames.input}
                  onClick={() => setDisable(false)}
                />
              </Form.Item>

              <Form.Item
                label={
                  <Title as="h5" className={classNames.fieldLabels}>
                    Company Phone <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                name="companyPhone"
                rules={[
                  { required: true, message: validation.companyPhone },
                  {
                    max: 30,
                    message: "Max 30 characters are allowed ",
                  },
                  {
                    pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g,
                    message: "Invalid format",
                  },
                ]}
              >
                <Input
                  placeholder=""
                  className={classNames.input}
                  onClick={() => setDisable(false)}
                />
              </Form.Item>

              <Form.Item
                label={
                  <Title as="h5" className={classNames.fieldLabels}>
                    Company Address (Headquarters){" "}
                    <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                name="companyAddress"
                rules={[
                  { required: true, message: validation.companyAddress },
                  {
                    max: 200,
                    message: "Max 200 characters are allowed",
                  },
                ]}
              >
                <Input
                  placeholder=""
                  className={classNames.input}
                  onClick={() => setDisable(false)}
                />
              </Form.Item>

              <Form.Item
                label={
                  <Title as="h5" className={classNames.fieldLabels}>
                    Country <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                name="country"
                rules={[{ required: true, message: validation.country }]}
              >
                <Select
                  className={classNames.select}
                  onClick={() => setDisable(false)}
                >
                  {Object.keys(countryList).map((key) => {
                    return (
                      <Select.Option value={key} key={key}>
                        {countryList[key]}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label={
                  <Title as="h5" className={classNames.fieldLabels}>
                    Industry <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                name="companyIndustry"
                rules={[{ required: true, message: validation.companyIndutry }]}
              >
                <Select
                  className={classNames.select}
                  onClick={() => setDisable(false)}
                >
                  {industries.map((industry) => (
                    <Select.Option value={industry.name} key={industry.name}>
                      {industry.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={
                  <Title as="h5" className={classNames.fieldLabels}>
                    Current stage <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                name="currentStage"
                rules={[{ required: true, message: validation.currentStage }]}
              >
                <Select
                  className={classNames.select}
                  onClick={() => setDisable(false)}
                >
                  {stages.map((stage) => (
                    <Select.Option value={stage.name} key={stage.id}>
                      {stage.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={
                  <Title as="h5" className={classNames.fieldLabels}>
                    Company size <sup style={{ top: 1 }}>*</sup>
                  </Title>
                }
                name="companySize"
                rules={[{ required: true, message: validation.companySize }]}
              >
                <Select
                  className={classNames.select}
                  onClick={() => setDisable(false)}
                >
                  {companySizes.map((item) => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </>
          {/* )} */}
          <PrimaryButton
            htmlType="submit"
            type="primary"
            loading={fetching}
            disabled={disable}
            style={{ width: "100%", fontWeight: "bold" }}
          >
            Finish Account Setup
          </PrimaryButton>
        </Form>

        <div className={classNames.logoWrapper}>
          <img
            src={mollyText}
            alt="Molly logo"
            className={classNames.mollyLogoText}
          />
        </div>
      </div>
    </div>
  );
}
