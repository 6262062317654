import classNames from "./Loader.module.scss";
import mollyAvatar from "../../../assets/molly-avatar.svg";

function Loader() {
  return (
    <div className={classNames.wrapper}>
      <img src={mollyAvatar} alt="Molly logo" />
    </div>
  );
}

export default Loader;
