import api from "../../../../api";
import types from "../../../types";
import { generateSyncAction } from "../../../utils.redux";

export default function getAllIndustry() {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.industry.getAllIndustry.started));

    try {
      const data = await api.industry.list();
      dispatch(generateSyncAction(types.industry.getAllIndustry.success, data));
    } catch (error) {
      dispatch(generateSyncAction(types.industry.getAllIndustry.failed, error));
    }
  };
}
