import endpoints from "../config";

export default async function submitCandidateTest(data) {
  return await (
    await fetch(
      endpoints.CANDIDATE_TEST[process.env.REACT_APP_ENV] +
        "/submit?id=" +
        data.candidateTestId,
      {
        method: "PUT",
        body: JSON.stringify({
          answers: data.answers,
          mark: "done",
          expirationDate: data.expirationDate,
        }),
      }
    )
  ).json();
}
