import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ac from "../../redux/actions";
import TestLobbyPage from "./TestLobbyPage";
import filterCandidates from "./common/filterCandidates";
import types from "../../redux/types";
import { Header } from "./layout";
import classNames from "./SkillTest.module.scss";
import AVModuleSupervisor from "./AgoraAV/AVModuleSupervisor";
import api from "../../api";

export default function SupervisorTestPage(props) {
  const [testId, setTestId] = useState("");
  const [candidateTestId, setCandidateTestId] = useState("");
  const [jobId, setJobId] = useState("");
  const [candidateId, setCandidateId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [candidateName, setCandidateName] = useState("Candidate");
  const [candidateTestData, setCandidateTestData] = useState();
  const [candidateJoined, setCandidateJoined] = useState();
  const [testStartTime, setTestStartTime] = useState();

  const dispatch = useDispatch();

  const skillTestObj = useSelector(function ({ skillTestById }) {
    return skillTestById.data ? skillTestById.data : {};
  });

  const { durationTime, title: testTitle } = skillTestObj;

  const milliseconds =
    skillTestObj !== {} && durationTime ? durationTime * 60000 : "0";

  const candidateTest = useSelector(
    (state) => state?.getCandidateTestById?.data
  );

  const jobData = useSelector(function ({ skillTestTempData }) {
    return skillTestTempData?.data?.job;
  });

  useEffect(() => {
    if (props.match.params.testId !== testId) {
      setTestId(props.match.params.testId);
    }

    if (props.match.params.candidateName !== candidateName) {
      setCandidateName(props.match.params.candidateName?.replace("+", " "));
    }

    if (props.match.params.candidateTestId !== candidateTestId) {
      setCandidateTestId(props.match.params.candidateTestId);
    }

    if (props.match.params.candidateTestId !== candidateId) {
      setCandidateId(props.match.params.candidateId);
    }

    if (props.match.params.setJobId !== jobId) {
      setJobId(props.match.params.jobId);
    }

    if (props.match.params.companyId !== companyId) {
      setCompanyId(props.match.params.companyId);
    }
  }, [
    props.match.params,
    candidateId,
    candidateTestId,
    jobId,
    testId,
    companyId,
    candidateName,
  ]);

  useEffect(() => {
    if (testId) {
      dispatch(ac.getSKBySkillTestId(testId));
    }
  }, [dispatch, testId]);

  useEffect(() => {
    if (candidateTestId) {
      dispatch(ac.getCandidateTestById(candidateTestId));
    }
  }, [candidateTestId, dispatch]);

  const candidatesData = useSelector(({ getCandidatesByCompanyId }) => {
    return getCandidatesByCompanyId?.data?.items;
  });

  useEffect(() => {
    if (!candidatesData) return;
    setCandidateName(
      filterCandidates(testId, companyId, jobId, candidateId, candidatesData)
    );
  }, [testId, companyId, jobId, candidateId, candidatesData]);

  useEffect(() => {
    if (candidateTestId && candidateJoined) {
      api.candidateTest.get(candidateTestId).then((data) => {
        setTestStartTime(data?.data?.startTime || data?.result?.startTime);
      });
    }
  }, [candidateJoined, candidateTestId]);

  if (
    candidateTest === types.testCompleted.status ||
    candidateTestData === types.testCompleted.status
  ) {
    return <TestLobbyPage testCompleted />;
  }

  if (candidateTest === types.testCompleted.expired) {
    return <TestLobbyPage testExpired />;
  }

  return (
    <>
      <Header
        numberOfMS={milliseconds}
        testStartTime={testStartTime}
        testTitle={testTitle}
        job={jobData}
        candidateId={candidateId}
        isSupervisor
      />
      <AVModuleSupervisor
        className={classNames.avModule}
        candidateName={candidateName}
        candidateTestData={candidateTestData}
        setCandidateTestData={setCandidateTestData}
        candidateTestId={candidateTestId}
        candidateJoined={candidateJoined}
        setCandidateJoined={setCandidateJoined}
        setTestStartTime={setTestStartTime}
      />
    </>
  );
}
