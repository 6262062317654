import AceEditor from "react-ace";

import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-typescript";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-php";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-ruby";
import "ace-builds/src-noconflict/mode-swift";

function EditorInput({ value, language }) {
  return (
    <AceEditor
      mode={language ? "javascript" : language}
      theme="monokai"
      value={value}
      name="questions_editor"
      editorProps={{ $blockScrolling: true }}
      highlightActiveLine
      wrapEnabled={true}
      height={300}
      width="100%"
      showGutter={false}
    />
  );
}

export default EditorInput;
