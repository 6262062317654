import types from "../types";
import api from "../../api";
import { generateSyncAction } from "../utils.redux";

export default function userSignup(username, password) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.user.signup.started));

    try {
      const data = await api.user.signup(username, password);
      dispatch(generateSyncAction(types.user.signup.success, data));
    } catch (error) {
      dispatch(generateSyncAction(types.user.signup.failed, error));
    }
  };
}
