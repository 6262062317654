import api from "../../../../api";
import types from "../../../types";
import { generateSyncAction } from "../../../utils.redux";

export default function listQuestions() {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.question.listQuestions.started));

    try {
      const data = await api.question.list();
      dispatch(generateSyncAction(types.question.listQuestions.success, data));
    } catch (error) {
      dispatch(generateSyncAction(types.question.listQuestions.failed, error));
    }
  };
}
