import { CSSProperties } from "react";
import classNames from "./Item.module.scss";

type Props = {
  children: React.ReactNode;
  style?: CSSProperties;
};

const Item = ({ children, style }: Props): JSX.Element => {
  return (
    <div className={classNames.item} style={style}>
      {children}
    </div>
  );
};

export default Item;
