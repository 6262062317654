import classNames from "./Drawer.module.scss";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import findChildByTypeName from "../utils/findChildByTypeName";
import { Children, isValidElement, ReactNode, cloneElement } from "react";
import { Drawer as AntDrawer } from "antd";

interface Props {
  className?: string;
  children: ReactNode;
  noBorder: boolean;
  headerStyle: React.CSSProperties;
}

const Drawer = ({
  className,
  children,
  noBorder = false,
  headerStyle,
  ...rest
}: Props): ReactNode => {
  const headerComp: any = findChildByTypeName(children, "Header");

  const propsForCloneElement = {
    classNames:
      headerComp &&
      headerComp.props &&
      headerComp.props.className + " " + noBorder
        ? "noBorder"
        : "",
  };
  const AlteredHeader = isValidElement(headerComp)
    ? cloneElement(headerComp, propsForCloneElement)
    : null;

  const otherComps = Children.toArray(children).filter((Child): ReactNode => {
    if (isValidElement(Child) && typeof Child.type === "function") {
      return Child.type.name !== "Header";
    } else if (isValidElement(Child)) {
      return Child;
    }
    return null;
  });

  return (
    <AntDrawer
      className={`${classNames.wrapper} ${className}`}
      title={AlteredHeader}
      headerStyle={{ border: noBorder ? "none" : "", ...headerStyle }}
      {...rest}
    >
      {otherComps}
    </AntDrawer>
  );
};

Drawer.Header = Header;
Drawer.Body = Body;
Drawer.Footer = Footer;

export default Drawer;
