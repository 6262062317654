import types from "../../types";

export default function getCompanyLogo(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.company.getCompanyLogo.started:
      return Object.assign({}, { fetching: true, data: action.data });

    case types.company.getCompanyLogo.success:
      return Object.assign(
        {},
        { fetching: false, success: true, data: action.data }
      );

    case types.company.getCompanyLogo.failed:
      return Object.assign({}, { fetching: false, success: false });

    default:
      return state;
  }
}
