import { Skeleton, Row } from "antd";
import classNames from "./CandidateListSkeleton.module.scss";

const CandidateSkeleton = () => {
  const cols = [1, 2, 3, 4, 5];
  const candidates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <div className={classNames.wrapper}>
      <div className={classNames.header}>
        {cols.map((key) => (
          <Skeleton.Avatar
            key={key}
            active={true}
            shape="square"
            style={{
              borderRadius: 5,
              width: 200,
              height: 30,
              marginBottom: 10,
            }}
          />
        ))}
      </div>
      <div className={classNames.content}>
        {candidates.map((key) => (
          <Row>
            <Skeleton.Avatar
              key={key}
              active={true}
              shape="square"
              style={{
                borderRadius: 5,
                width: "79vw",
                height: 50,
                marginBottom: 15,
              }}
            />
          </Row>
        ))}
      </div>
    </div>
  );
};

export default CandidateSkeleton;
