import types from "../../types";
import api from "../../../api";
import { generateSyncAction } from "../../utils.redux";

export default function activateMemberAccount(inputs) {
  const input = inputs;
  return async function (dispatch) {
    dispatch(generateSyncAction(types.user.activateMemberAccount.started));

    try {
      const data = await api.user.activateMemberAccount(input);
      dispatch(
        generateSyncAction(types.user.activateMemberAccount.success, data)
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.user.activateMemberAccount.failed, error)
      );
    }
  };
}
