import types from "../../../types";

export default function submitAnswer(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.skillTest.submitAnswers.started:
      return Object.assign({}, { fetching: true });

    case types.skillTest.submitAnswers.success:
      return Object.assign({}, { fetching: false, success: true });

    case types.skillTest.submitAnswers.failed:
      return Object.assign({}, { fetching: false, success: false });

    default:
      return state;
  }
}
