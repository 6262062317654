import types from "../types";
import { generateSyncAction } from "../utils.redux";
import API from "../../api";

export default function getCurrentUser(username, password) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.user.signin.started));

    try {
      const data = await API.user.fetchCurrentUser(username, password);
      dispatch(generateSyncAction(types.user.signin.success, data));
    } catch (error) {
      dispatch(generateSyncAction(types.user.signin.failed, error));
    }
  };
}
