import types from "../../types";

export default function createExercise(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.codeEditor.createExercise.started:
      return Object.assign({}, { fetching: true });

    case types.codeEditor.createExercise.success:
      return Object.assign(
        {},
        { fetching: false, success: true, data: action.data }
      );

    case types.codeEditor.createExercise.failed:
      return Object.assign({}, { fetching: false, success: false });

    case types.codeEditor.resetExercise.success:
      return {
        ...state,
        data: null,
        success: false,
        fetching: false,
      };
    default:
      return state;
  }
}
