import types from "../../types";
import { generateSyncAction } from "../../utils.redux";

export default function tempAddQuestionToTest(data) {
  return function (dispatch) {
    dispatch(
      generateSyncAction(types.tempQuestions.addQuestionsToTest.success, data)
    );
  };
}
