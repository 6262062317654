import React from "react";
import Item from "./Item";
import classNames from "./DataRow.module.scss";

type Props = {
  noBorder: boolean;
  noPadding: boolean;
  noBoxProps: boolean;
  children: React.ReactNode;
  className: string;
  noMargin: boolean;
  boldTitle: boolean;
  boldDesc: boolean;
  style?: React.CSSProperties;
  itemStyles: React.CSSProperties;
  bodyStyles: React.CSSProperties;
  data: [
    {
      title?: string;
      body?: string;
      comp?: () => React.ReactNode;
      icon?: React.ReactNode;
    }
  ];
};

const DataRow = ({
  className,
  noBorder,
  noBoxProps,
  noPadding,
  noMargin,
  children,
  data,
  boldDesc = false,
  boldTitle = false,
  style,
  itemStyles,
  bodyStyles,
}: Props): React.ReactNode => {
  return (
    <div
      className={`${className} ${classNames.wrapper} ${
        noBorder ? classNames["no-border"] : ""
      } ${noPadding ? classNames["no-padding"] : ""} ${
        noMargin ? classNames["no-margin"] : ""
      } ${noBoxProps ? classNames["no-box-props"] : ""}`}
      style={style}
    >
      {data
        ? data.map((item, index) =>
            item.comp ? (
              item.comp()
            ) : (
              <Item style={{ ...itemStyles }} key={index}>
                <div className={boldTitle ? classNames.bold : ""}>
                  {item?.title}
                </div>
                <div
                  className={boldDesc ? classNames.bold : ""}
                  style={{ ...bodyStyles }}
                >
                  {item.body}
                </div>
              </Item>
            )
          )
        : children}
    </div>
  );
};

DataRow.Item = Item;

export default DataRow;
