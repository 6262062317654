import types from "../../types";
import API from "../../../api";
import { generateSyncAction } from "../../utils.redux";

export default function updateUserAvatar(inputs) {
  const input = inputs;
  return async function (dispatch) {
    dispatch(generateSyncAction(types.user.updateUserAvatar.started));

    try {
      const data = await API.user.updateUserAvatar(inputs);
      const allData = {
        data,
        input,
      };

      dispatch(
        generateSyncAction(types.user.updateUserAvatar.success, allData)
      );
    } catch (error) {
      dispatch(generateSyncAction(types.user.updateUserAvatar.failed, error));
    }
  };
}
