import types from "../../types";

export default function companyMembers(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.company.fetchCompanyProfile.started:
      return Object.assign({}, { fetching: true });

    case types.company.fetchCompanyProfile.success:
      return Object.assign(
        {},
        {
          fetching: false,
          data: [...action.data.data.getCompany.members.items],
        }
      );

    case types.company.fetchCompanyProfile.failed:
      const data = action.data?.data?.getCompany?.members?.items;

      if (data) {
        return Object.assign(
          {},
          {
            fetching: false,
            error: action.data.errors,
            data: [...data],
          }
        );
      }

      return Object.assign(
        {},
        {
          fetching: false,
          error: action.data.errors,
          data: state.data,
        }
      );

    default:
      return state;
  }
}
