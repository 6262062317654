import endpoints from "../../config";

export default async function getQuestionById() {
  return await fetch(endpoints.QUESTION[process.env.REACT_APP_ENV], {
    method: "GET",
    headers: {
      // "X-API-KEY": "Tqu3tlbhav7njEvKRMMnr39CmwNm4Ff31Mdoisb0",
    },
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => console.log("error: ", err));
}
