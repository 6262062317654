import types from "../../types";
import { generateSyncAction } from "../../utils.redux";
import API from "../../../api";

export default function list(candidateId) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.messaging.list.started));

    try {
      const data = await API.message.list(candidateId);
      dispatch(generateSyncAction(types.messaging.list.success, data));
    } catch (error) {
      dispatch(generateSyncAction(types.messaging.list.failed, error));
    }
  };
}
