import endpoints from "../config";

export default async function listExercises({ companyId, candidateId }) {
  const params = new URLSearchParams({
    companyId,
    candidateId,
  });

  return await (
    await fetch(
      `${endpoints.EXERCISES[process.env.REACT_APP_ENV]}exercise` +
        `${params.toString()}`,
      {
        method: "GET",
      }
    )
  ).json();
}
