import { Result, PrimaryButton } from "../../common/";
import { FiLock } from "react-icons/fi";

export default function UserProfileDeactivateNotice() {
  function handleLoginClick() {
    window.location.replace("/");
    localStorage.clear();
  }

  function handleSignUpClick() {
    window.location.replace("/signup");
    localStorage.clear();
  }

  return (
    <div>
      <Result
        icon={<FiLock size={120} color="rgba(0, 0, 0, 0.25)" />}
        title="Account has been deactivated!"
        subTitle="Account has been deactivated. Please contact your company Admin or Molly support"
        extra={[
          <PrimaryButton type="primary" key="login" onClick={handleLoginClick}>
            Login
          </PrimaryButton>,
          <PrimaryButton
            type="primary"
            key="signup"
            onClick={handleSignUpClick}
          >
            Sign Up
          </PrimaryButton>,
        ]}
      />
    </div>
  );
}
