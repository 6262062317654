import types from "../../../types";

export default function listQuestionTypes(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.questionTypes.listQuestionTypes.started:
      return Object.assign({}, { fetching: true });

    case types.questionTypes.listQuestionTypes.success:
      return Object.assign(
        {},
        { fetching: false, success: true, data: action.data.data }
      );

    case types.questionTypes.listQuestionTypes.failed:
      return Object.assign({}, { fetching: false, success: false });

    default:
      return state;
  }
}
