import classNames from "./Input.module.scss";
import { Input as AntInput } from "antd";
import React from "react";

type Props = {
  className?: string;
  style?: React.CSSProperties;
  size?: "default" | "small";
};

export default function Input({
  className,
  size = "default",
  ...props
}: Props): JSX.Element {
  return (
    <AntInput
      {...props}
      className={`${classNames.input} ${classNames[size]} ${className} `}
    ></AntInput>
  );
}
