import { API } from "aws-amplify";
import { getCandidatesByCompanyId } from "../../graphql/queries";

export default async function getCandidatesByCompany(companyId) {
  return await API.graphql({
    query: getCandidatesByCompanyId,
    variables: {
      companyId,
    },
  });
}
