import types from "../../types";
import api from "../../../api";
import { generateSyncAction } from "../../utils.redux";

export default function globalSignOut(username) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.user.globalSignOut.started));

    try {
      const data = await api.user.globalSignOut(username);
      dispatch(generateSyncAction(types.user.globalSignOut.success, data));
    } catch (error) {
      dispatch(generateSyncAction(types.user.globalSignOut.failed, error));
    }
  };
}
