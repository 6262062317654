import types from "../../types";

export default function inviteMember(
  state = { sent: false, fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.user.inviteMember.started:
      return Object.assign({}, { fetching: true });

    case types.user.inviteMember.success:
      return Object.assign(
        {},
        {
          fetching: false,
          data: { ...action.data.data.inviteMember },
          sent: true,
        }
      );

    case types.reset.inviteMember:
      return Object.assign(
        {},
        {
          fetching: false,
          ...state,
          sent: false,
          error: false,
        }
      );

    case types.user.inviteMember.failed:
      return Object.assign(
        {},
        { sent: false, fetching: false, error: { ...action.data } }
      );

    default:
      return state;
  }
}
