import types from "../../types";
import { generateSyncAction } from "../../utils.redux";
import API from "../../../api";

export default function createJob(inputs) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.jobs.createJob.started));
    // FIX: Move this to λ.
    inputs.handlers = [
      `${inputs.companyId}adminON_TRIAL`,
      `${inputs.companyId}managerON_TRIAL`,
      `${inputs.companyId}adminON_SUBSCRIPTION`,
      `${inputs.companyId}managerON_SUBSCRIPTION`,
    ];
    try {
      const data = await API.jobs.create(inputs);
      dispatch(generateSyncAction(types.jobs.createJob.success, data));
    } catch (error) {
      dispatch(generateSyncAction(types.jobs.createJob.failed, error));
    }
  };
}
