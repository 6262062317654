import classNames from "./Wrapper.module.scss";
import PropTypes from "prop-types";
/**
 * Function representing Wrapper component
 * @param {Object} param0 component props
 */
function Wrapper({ el: El = "div", children, classes, style }) {
  return (
    <El style={{ ...style }} className={`${classNames.wrapper} ${classes}`}>
      {children}
    </El>
  );
}

Wrapper.propTypes = {
  el: PropTypes.string,
  className: PropTypes.string,
};

export default Wrapper;
