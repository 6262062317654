import classNames from "./Footer.module.scss";
import { Radio } from "antd";

type Props = {
  onClick: () => void;
  onRatingChange: () => void;
  rate: string;
  disable?: boolean;
};

const Footer = ({
  onClick,
  onRatingChange,
  rate,
  disable,
}: Props): JSX.Element => {
  return (
    <footer className={classNames.wrapper}>
      <div>Rate this answer</div>
      <div className={classNames.radioGroupWrapper}>
        <p>Very poor</p>
        <Radio.Group
          buttonStyle="solid"
          onChange={onRatingChange}
          defaultValue={rate !== "undefined" ? rate : null}
          disabled={disable}
        >
          <Radio.Button value="0" onClick={onClick}>
            0
          </Radio.Button>
          <Radio.Button value="1" onClick={onClick}>
            1
          </Radio.Button>
          <Radio.Button value="2" onClick={onClick}>
            2
          </Radio.Button>
          <Radio.Button value="3" onClick={onClick}>
            3
          </Radio.Button>
          <Radio.Button value="4" onClick={onClick}>
            4
          </Radio.Button>
          <Radio.Button value="5" onClick={onClick}>
            5
          </Radio.Button>
        </Radio.Group>

        <p>Very good</p>
      </div>
    </footer>
  );
};

export default Footer;
