const { useState, useEffect } = require("react");

// function getOnlineStatus() {
//   return typeof navigator !== "undefined" &&
//     typeof navigator.onLine === "boolean"
//     ? navigator.onLine
//     : true;
// }

function useOnlineStatus() {
  const [onlineStatus, setOnlineStatus] = useState("initial");
  const [label, setLabel] = useState("");

  const goOnline = () => {
    setOnlineStatus(true);
    setLabel("Internet connection restored");
  };

  const goOffline = () => {
    setOnlineStatus(false);
    setLabel("No internet connection available");
  };

  useEffect(() => {
    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);

    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);

  useEffect(() => {
    if (onlineStatus) {
      const timer = setTimeout(() => {
        // After 3 seconds, set onlineStatus back to "initial"
        setOnlineStatus("initial");
      }, 3000); // set the timer for 3 seconds

      // Clear the timer on component unmount or when the dependency changes
      return () => clearTimeout(timer);
    }
  }, [onlineStatus]);

  return { onlineStatus, label };
}

export default useOnlineStatus;
