import { Radio } from "antd";
import classNames from "./QuestionTypes.module.scss";

export default function MCQ({
  questionId,
  onChange,
  mcqSelectedAnswer,
  allAnswers,
}) {
  return (
    <Radio.Group
      className={classNames.wrapper}
      value={mcqSelectedAnswer?.option ?? null}
    >
      {allAnswers &&
        allAnswers.map((option, index) => {
          return (
            <Radio
              value={option}
              key={index + questionId}
              onClick={() => onChange(option, index)}
            >
              {option}
            </Radio>
          );
        })}
    </Radio.Group>
  );
}
