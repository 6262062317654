import types from "../../types";
import API from "../../../api";
import { generateSyncAction } from "../../utils.redux";

export default function getTempSkillTestData(candidateId, companyId, jobId) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.skillTest.getTempSkillTestData.started));

    try {
      const data = await API.skillTest.getTempSkillTestData(
        candidateId,
        companyId,
        jobId
      );
      dispatch(
        generateSyncAction(
          types.skillTest.getTempSkillTestData.success,
          data.data
        )
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.skillTest.getTempSkillTestData.failed, error)
      );
    }
  };
}
