import api from "../../../../api";
import types from "../../../types";
import { generateSyncAction } from "../../../utils.redux";

export default function listDesignation() {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.designation.listDesignation.started));

    try {
      const data = await api.designation.list();
      dispatch(
        generateSyncAction(types.designation.listDesignation.success, data)
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.designation.listDesignation.failed, error)
      );
    }
  };
}
