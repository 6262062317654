import classNames from "./Support.module.scss";
import matchEmailDomain from "../../../common/utils/matchEmailDomain";

export default function Support({ candidateId, userId }) {
  return (
    <div className={classNames.support}>
      <a
        href={`mailto:hello+${candidateId}+${userId}@${matchEmailDomain(
          process.env.REACT_APP_ENV
        )}`}
      >
        Need Help?
      </a>
    </div>
  );
}
