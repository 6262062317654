import types from "../../types";

export default function signup(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.user.completeUserProfile.started:
      return Object.assign({}, { fetching: true });

    case types.user.completeUserProfile.success:
      return Object.assign({}, { fetching: false, data: action.data.data });

    case types.user.completeUserProfile.failed:
      return Object.assign({}, { fetching: false, error: action.data });

    default:
      return state;
  }
}
