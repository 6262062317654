import types from "../../../types";

export default function listIndustry(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.industry.getAllIndustry.started:
      return Object.assign({}, { fetching: true });

    case types.industry.getAllIndustry.success:
      return Object.assign({}, { fetching: false, data: action.data.data });

    case types.industry.getAllIndustry.failed:
      return Object.assign({}, { fetching: false, error: action.data });

    default:
      return state;
  }
}
