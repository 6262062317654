import types from "../../types";
import { generateSyncAction } from "../../utils.redux";
import API from "../../../api";

export default function getUserById(userId) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.user.fetchUserProfileById.started));

    try {
      const data = await API.user.fetchUserProfile(userId);
      dispatch(
        generateSyncAction(types.user.fetchUserProfileById.success, data)
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.user.fetchUserProfileById.failed, error)
      );
    }
  };
}
