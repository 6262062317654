import React from "react";
import classNames from "./Body.module.scss";

type Props = {
  children?: React.ReactNode;
  as?: "div" | "section" | "main" | "article";
  style?: React.CSSProperties;
  className?: string;
};

const Body = ({
  children,
  as: El = "div",
  style,
  className,
}: Props): JSX.Element => {
  return (
    <El
      style={style}
      className={`${classNames.body} ${classNames[El]} ${className} `}
    >
      {children}
    </El>
  );
};

export default Body;
