import types from "../../types";

export default function deleteFeedback(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.feedback.deleteFeedback.started:
      return Object.assign({}, { fetching: true });

    case types.feedback.deleteFeedback.success:
      return Object.assign(
        {},
        { fetching: false, deleted: true, data: action.data }
      );

    case types.feedback.deleteFeedback.failed:
      return Object.assign(
        {},
        { fetching: false, deleted: false, data: action.data }
      );
    case types.reset.deleteFeedback:
      return Object.assign({}, { ...state }, { deleted: false });

    default:
      return state;
  }
}
