import types from "../../types";
import api from "../../../api";
import { generateSyncAction } from "../../utils.redux";

export default function updateStage(input) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.candidates.updateStage.started, input));

    try {
      const data = await api.candidates.updateStage(input);
      dispatch(generateSyncAction(types.candidates.updateStage.success, data));
    } catch (error) {
      dispatch(
        generateSyncAction(types.candidates.updateStage.failed, {
          error,
          data: input,
        })
      );
    }
  };
}
