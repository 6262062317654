import endpoints from "../config";

export default async function getTempData(candidateId, companyId, jobId) {
  return await fetch(
    endpoints.TEMP_DATA[process.env.REACT_APP_ENV] +
      `?candidateId=${candidateId}&companyId=${companyId}&jobId=${jobId}`,
    {
      method: "GET",
    }
  ).then((res) => res.json());
}
