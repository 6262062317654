import types from "../../types";
import api from "../../../api";
import { generateSyncAction } from "../../utils.redux";

export default function listInterviewsByCompanyId(input) {
  return async function (dispatch) {
    dispatch(
      generateSyncAction(types.candidates.listInterviewsByCompanyId.started)
    );

    try {
      const data = await api.candidates.listInterviewsByCompanyId(input);
      dispatch(
        generateSyncAction(
          types.candidates.listInterviewsByCompanyId.success,
          data
        )
      );
    } catch (error) {
      dispatch(
        generateSyncAction(
          types.candidates.listInterviewsByCompanyId.failed,
          error
        )
      );
    }
  };
}
