import types from "../../types";
import api from "../../../api";
import { generateSyncAction } from "../../utils.redux";

export default function listTestsByCompanyId(companyId) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.tests.listByCompanyId.started));

    try {
      const data = await api.tests.getByCompanyId(companyId);
      dispatch(
        generateSyncAction(types.tests.listByCompanyId.success, data.data)
      );
    } catch (error) {
      console.log(error);
      dispatch(generateSyncAction(types.tests.listByCompanyId.failed, error));
    }
  };
}
