import types from "../../types";

export default function completeOnboardingSteps(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.common.completeOnBoardingSteps.started:
      return Object.assign({}, { fetching: true });

    case types.common.completeOnBoardingSteps.success:
      return Object.assign(
        {},
        { fetching: false, data: { ...action.data.data.completeSteps } }
      );

    case types.common.completeOnBoardingSteps.failed:
      return Object.assign({}, { fetching: false, error: action.data.data });

    default:
      return state;
  }
}
