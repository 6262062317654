import types from "../../../types";
export default function getCandidateTestById(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.candidateTest.getById.started:
      return Object.assign({}, { fetching: true });
    case types.candidateTest.getById.success:
      if (
        (typeof action.data === "string" && action.data === "test expired") ||
        action.data === types.testCompleted.status ||
        action.data === types.testCompleted.duration
      ) {
        return Object.assign(
          {},
          { fetching: false, success: false, data: action.data }
        );
      }

      return Object.assign(
        {},
        { fetching: false, success: true, data: action.data }
      );
    case types.candidateTest.getById.failed:
      return Object.assign({}, { fetching: false, success: false });
    default:
      return state;
  }
}
