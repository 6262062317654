import types from "../../types";
import { generateSyncAction } from "../../utils.redux";
import api from "../../../api";

export default function getLanguages() {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.codeEditor.getLanguages.started));
    try {
      const data = await api.codeEditor.getLanguages();
      dispatch(generateSyncAction(types.codeEditor.getLanguages.success, data));
    } catch (error) {
      dispatch(generateSyncAction(types.codeEditor.getLanguages.failed, error));
    }
  };
}
