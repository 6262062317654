const matchEmailDomain = (env) => {
  switch (env) {
    case "dev":
      return `${env}-reply.hellomolly.io`;
    case "qa":
      return `next-reply.hellomolly.io`;
    case "prod":
      return `reply.hellomolly.io`;
    default:
      break;
  }
};

export default matchEmailDomain;

/**
 * sendgrid inbound-parse host email domains are as follows
 *
 * "dev" environment :- dev-reply.hellomolly.io
 * "next" environment :- next-reply.hellomolly.io
 * "prod" environment :- reply.hellomolly.io
 */
