import { combineReducers } from "redux";
import {
  signup,
  confirmAuth,
  signin,
  userProfileById as user,
  updateProfileCompletionCognito as profileCompletionCognito,
  createOnBoardingProfiles,
  inviteMember,
  resetMemberPassword,
  companyMembers,
  updateUserAvatar,
  globalSignOut,
  deactivateUserProfile,
  activateMemberAccount,
  removeMember,
} from "./user";
import {
  getCompanyProfile as company,
  updateCompanyProfile,
  updateCompanyHandler,
  updateCompanyLogo,
  getCompanyLogo,
} from "./company";
import { createJob, jobList, jobTitles, job, updateJob } from "./jobs";
import {
  createFeedback,
  updateFeedback,
  deleteFeedback,
  getFeedbackByCandidate,
} from "./feedback";
import {
  candidates,
  updateStage,
  changeManagerInCharge,
  scheduleInterview,
  interviewsByCandidateId,
  getCandidatesByCompanyId,
  listInterviewsByCompanyId,
  recordSentSkillTests,
  resetScheduleInterview,
} from "./candidates";
import { sendMessage, listMessages } from "./messaging";
import { resetPasswordReducer } from "./userManagement";
import { subscriptionPlan } from "./subscriptionPlan";
import { completeOnBoardingSteps } from "./common";
import {
  submitAnswer,
  getQuestionById,
  listTestsByCompanyId,
  // listTestsByCompanyIdCopy,
  skillTestById,
  getAllAnswers,
  listSkillTestsByCompanyId,
  submitCandidateTest,
  listDifficulty,
  listTestDuration,
  listIndustry,
  listQuestions,
  listQuestionTypes,
  listDesignation,
  createAnswer,
  createQuestion,
  createSkillTest,
  listQuestionsByIds,
  assignDefaultSkillTest,
  listCandidateTestsByCandidateId,
  updateCandidateTestResult,
  updateSkillTest,
  listTestsTemplates,
  rateCandidateTestResult,
  skillTestTempData,
  filterExercise,
  customizeExercise,
  getSignedURL,
} from "./skillTest";
import {
  runCode,
  getLanguages,
  listExercises,
  createExercise,
} from "./codeEditor";
import {
  getCandidateTestById,
  startCandidateTest,
  createCandidateTest,
  sendManagerEmail,
} from "./candidateTest";
import { tempAddQuestionToTest } from "./tempQuestionInTest";

export default combineReducers({
  signup,
  confirmAuth,
  signin,
  user,
  profileCompletionCognito,
  createOnBoardingProfiles,
  company,
  createJob,
  jobList,
  candidates,
  jobTitles,
  job,
  updateJob,
  inviteMember,
  resetMemberPassword,
  companyMembers,
  createFeedback,
  deleteFeedback,
  getFeedbackByCandidate,
  sendMessage,
  listMessages,
  updateStage,
  changeManagerInCharge,
  updateFeedback,
  scheduleInterview,
  interviewsByCandidateId,
  getCandidatesByCompanyId,
  listInterviewsByCompanyId,
  resetPasswordReducer,
  updateCompanyProfile,
  subscriptionPlan,
  updateCompanyHandler,
  completeOnBoardingSteps,
  updateCompanyLogo,
  updateUserAvatar,
  globalSignOut,
  submitAnswer,
  listTestsByCompanyId,
  // listTestsByCompanyIdCopy,
  getQuestionById,
  skillTestById,
  getAllAnswers,
  getCompanyLogo,
  submitCandidateTest,
  startCandidateTest,
  getCandidateTestById,
  listDifficulty,
  listTestDuration,
  listIndustry,
  tempAddQuestionToTest,
  listQuestions,
  listQuestionTypes,
  listDesignation,
  createAnswer,
  createQuestion,
  createSkillTest,
  listSkillTestsByCompanyId,
  listQuestionsByIds,
  assignDefaultSkillTest,
  createCandidateTest,
  recordSentSkillTests,
  listCandidateTestsByCandidateId,
  updateCandidateTestResult,
  updateSkillTest,
  listTestsTemplates,
  rateCandidateTestResult,
  skillTestTempData,
  sendManagerEmail,
  deactivateUserProfile,
  resetScheduleInterview,
  runCode,
  getLanguages,
  filterExercise,
  listExercises,
  createExercise,
  customizeExercise,
  getSignedURL,
  activateMemberAccount,
  removeMember,
});
