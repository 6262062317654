import types from "../../types";
import api from "../../../api";
import { generateSyncAction } from "../../utils.redux";

export default function getFeedbackByCandidate(inputs) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.feedback.getFeedbackByCandidate.started));

    try {
      const data = await api.feedback.getFeedbackByCandidate(inputs);
      dispatch(
        generateSyncAction(
          types.feedback.getFeedbackByCandidate.success,
          data.data.getFeedbackByCandidateId
        )
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.feedback.getFeedbackByCandidate.failed, error)
      );
    }
  };
}
