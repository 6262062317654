import types from "../../types";
import api from "../../../api";
import { generateSyncAction } from "../../utils.redux";

export default function listCandidateInterviews(candidateId) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.candidates.listInterviews.started));

    try {
      const data = await api.candidates.listInterviews(candidateId);

      dispatch(
        generateSyncAction(types.candidates.listInterviews.success, data)
      );
    } catch (error) {
      console.log(error);
      dispatch(
        generateSyncAction(types.candidates.listInterviews.failed, error)
      );
    }
  };
}
