/* eslint-disable react-hooks/exhaustive-deps */

import { BsMic } from "react-icons/bs";
import { TiVideoOutline } from "react-icons/ti";
import { useState, useEffect } from "react";
import VolumeIndicator from "./VolumeIndicator";
import { Button } from "antd";
// import { Menu, Dropdown } from "antd";
import checkMark from "../../../../images/AudioVideo/AVcheckmark.png";
// import { DownOutlined } from "@ant-design/icons";
import { Alert } from "antd";
// import { FiMonitor } from "react-icons/fi";

/*
Commented code can be used in future
Code commented here due to the bug reported https://smashtaps.atlassian.net/browse/MO-1493
Requirement is not finalized yet.
*/
export default function AudioVideo({
  classNames,
  supervisor,
  camMicInitialState,
  tracks,
  ready,
}) {
  const [checkVideoStarted, setVideoCheckStarted] = useState(false);
  const [checkAudioStarted, setAudioCheckStarted] = useState(false);
  const [alreadyVideoStarted, setAlreadyVideoStarted] = useState(false);
  const [alreadyAudioStarted, setAlreadyAudioStarted] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(0);
  // const [audioDevices, setAudioDevices] = useState(null);
  // const [videoDevices, setVideoDevices] = useState(null);
  const [allowWebcam, setAllowWebcam] = useState(false);
  const [allowMicrophone, setAllowMicrophone] = useState(false);
  const [videoTrack, setVideoTrack] = useState(null);
  const [audioTrack, setAudioTrack] = useState(null);
  const [permissionGranted, setPermissionGranted] = useState(false);
  // const [screenCaptureStarted, setScreenCaptureStarted] = useState(false);
  // const [allowScreenCapture, setAllowScreenCapture] = useState(false);
  // const screenSharePlayer = document.getElementById("pre-screen-share");

  // useEffect(() => {
  //   AgoraRTC.getDevices().then((devices) => {
  //     setAudioDevices(
  //       devices.filter(function (device) {
  //         return device.kind === "audioinput";
  //       })
  //     );
  //     setVideoDevices(
  //       devices.filter(function (device) {
  //         return device.kind === "videoinput";
  //       })
  //     );
  //   });
  // }, [AgoraRTC.onCameraChanged, AgoraRTC.onMicrophoneChanged]);

  // const setDeviceVideo = async (deviceId) => {
  //   if (videoTrack) {
  //     videoTrack
  //       .setDevice(deviceId)
  //       .then(() => {
  //         console.log("set device success");
  //       })
  //       .catch((e) => {
  //         console.log("set device error", e);
  //       });
  //   } else {
  //     setAllowWebcam(true);
  //   }
  // };

  // const setDeviceAudio = async (deviceId) => {
  //   console.log(audioTrack, deviceId);
  //   if (audioTrack) {
  //     audioTrack
  //       .setDevice(deviceId)
  //       .then(() => {
  //         console.log("set device success");
  //       })
  //       .catch((e) => {
  //         console.log("set device error", e);
  //       });
  //   } else {
  //     setAllowMicrophone(true);
  //   }
  // };

  // const menu = (av) => {
  //   if (av === "audio") {
  //     return (
  //       <Menu>
  //         {audioDevices &&
  //           audioDevices.map((device) => {
  //             return (
  //               <Menu.Item key={device.deviceId}>
  //                 <div onClick={() => setDeviceAudio(device.deviceId)}>
  //                   {device.label}
  //                 </div>
  //               </Menu.Item>
  //             );
  //           })}
  //       </Menu>
  //     );
  //   }
  //   if (av === "video") {
  //     return (
  //       <Menu>
  //         {videoDevices &&
  //           videoDevices.map((device) => {
  //             return (
  //               <Menu.Item key={device.deviceId}>
  //                 <div onClick={() => setDeviceVideo(device.deviceId)}>
  //                   {device.label}
  //                 </div>
  //               </Menu.Item>
  //             );
  //           })}
  //       </Menu>
  //     );
  //   }
  // };

  const checkAudioVideo = (media) => {
    if (media === "video") {
      setVideoCheckStarted(true);
      localStorage.setItem("video", "on");
    } else if (media === "audio") {
      setAudioCheckStarted(true);
      localStorage.setItem("audio", "on");
    }
  };

  function setVolumeWave() {
    setTimeout(() => {
      setVolumeLevel(tracks[0].getVolumeLevel() * 100);
      setVolumeWave();
    }, 200);
  }
  //   const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();
  //   const { ready, tracks } = useMicrophoneAndCameraTracks();

  useEffect(async () => {
    if (ready && !checkAudioStarted) {
      setTimeout(async () => {
        await tracks[0].setEnabled(false);
      }, 1000);
    }
    if (ready && !checkVideoStarted) {
      setTimeout(async () => {
        await tracks[1].setEnabled(false);
      }, 1000);
    }
  }, [tracks]);

  useEffect(() => {
    async function mediaDeviceTest() {
      // create local tracks
      if (checkVideoStarted) {
        setVideoTrack(tracks[1]);
        await tracks[1].setEnabled(true);
      }
      if (checkAudioStarted) {
        setAudioTrack(tracks[0]);
        await tracks[0].setEnabled(true);
      }
      // play local track on device detect dialog
      if (checkVideoStarted && !alreadyVideoStarted) {
        setAlreadyVideoStarted(true);
        tracks[1].play("pre-local-player");
      }
      if (checkAudioStarted && !alreadyAudioStarted) {
        /**
         * removed below line due to the play back occured
        tracks[0].play();
         */
        setAlreadyAudioStarted(true);
        setVolumeWave();
      }
    }

    async function checkMicCameraPermission() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        // Permission granted
        console.log("Mic and camera permission granted");
        setAllowMicrophone(false);
        setAllowWebcam(false);
        setPermissionGranted(true);
        mediaDeviceTest();
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      } catch (err) {
        // Permission denied
        console.error("Mic and camera permission denied", err);
        setAllowMicrophone(true);
        setAllowWebcam(true);
        // Ask for permission
        navigator.mediaDevices
          .getUserMedia({
            audio: true,
            video: true,
          })
          .then((stream) => {
            console.log("Mic and camera permission granted");
            setAllowMicrophone(false);
            setAllowWebcam(false);
            setPermissionGranted(true);
            mediaDeviceTest();
            stream.getTracks().forEach((track) => {
              track.stop();
            });
          })
          .catch((err) => {
            console.error("Mic and camera permission still denied", err);
            setAllowMicrophone(true);
            setAllowWebcam(true);
          });
      }
    }
    checkMicCameraPermission();
  }, [checkVideoStarted, checkAudioStarted]);

  const toggleAudio = async () => {
    if (!checkAudioStarted) {
      checkAudioVideo("audio");
    } else {
      audioTrack.stop();
      setAudioCheckStarted(false);
      localStorage.setItem("audio", "off");
      setAlreadyAudioStarted(false);
    }
  };

  useEffect(() => {
    if (permissionGranted) {
      setTimeout(() => {
        if (camMicInitialState) {
          console.log("HEREE");
          checkAudioVideo("audio");
          checkAudioVideo("video");
          localStorage.setItem("audio", "on");
          localStorage.setItem("video", "on");
        }
      }, 500);
    }
  }, [permissionGranted]);
  // Commenting for future use.
  // async function startCapture() {
  //   try {
  //     let screenSrc = await navigator.mediaDevices.getDisplayMedia({
  //       video: true,
  //       audio: true,
  //     });
  //     screenSharePlayer.srcObject = screenSrc;
  //     setAllowScreenCapture(false);
  //     setScreenCaptureStarted(true);
  //   } catch (err) {
  //     console.error("screen share failed", err);
  //     setAllowScreenCapture(true);
  //     setScreenCaptureStarted(false);
  //   }
  // }

  // function stopCapture(evt) {
  //   let tracks = screenSharePlayer.srcObject.getTracks();

  //   tracks.forEach((track) => track.stop());
  //   screenSharePlayer.srcObject = null;
  //   setAllowScreenCapture(true);
  //   setScreenCaptureStarted(false);
  // }

  return (
    <div className={classNames.AudioAndVideo}>
      {/* Following ui development carried out due to bug reported MO-1636.
          Feature cannot developed due to misatches between current architecture and 
          Agra web SDK.
          Commenting for future use.
      */}
      {/* {!supervisor && (
        <>
          <h4>{`Audio & Video`}</h4>
          <p>Please check your Camera and Microphone here</p>
        </>
      )} */}
      <div className={classNames.AudioAndVideo__content}>
        {/* {!screenCaptureStarted && !supervisor && (
          <Button
            className={classNames.AudioAndVideo__content__button}
            onClick={startCapture}
            // disabled={allowScreenCapture}
          >
            <div className={classNames.AudioAndVideo__content__iconwrapper}>
              <FiMonitor size={27} />
              <div
                className={classNames.AudioAndVideo__content__iconwrapper__text}
              >
                Allow screen share
              </div>
            </div>
          </Button>
        )} */}

        {/* <video
          id="pre-screen-share"
          autoPlay
          className={classNames.AudioAndVideo__content__button}
          onClick={stopCapture}
          style={{
            display: screenCaptureStarted && !supervisor ? "block" : "none",
            cursor: "pointer",
          }}
        >
          <img
            src={checkMark}
            alt="Dog holding an electric code."
            className={classNames.AudioAndVideo__content__button__checkmark}
          />
        </video> */}

        {!checkVideoStarted ? (
          <Button
            className={classNames.AudioAndVideo__content__button}
            onClick={() => checkAudioVideo("video")}
            disabled={allowWebcam}
          >
            <div className={classNames.AudioAndVideo__content__iconwrapper}>
              <TiVideoOutline size={27} />
              <div
                className={classNames.AudioAndVideo__content__iconwrapper__text}
              >
                Turn on webcam
              </div>
            </div>
          </Button>
        ) : (
          <>
            <div
              id="pre-local-player"
              className={classNames.AudioAndVideo__content__button}
              onClick={async () => {
                videoTrack.stop();
                await tracks[1].setEnabled(!videoTrack);
                setVideoCheckStarted(false);
                localStorage.setItem("video", "off");
                setAlreadyVideoStarted(false);
              }}
            >
              <img
                src={checkMark}
                alt="Dog holding an electric code."
                className={classNames.AudioAndVideo__content__button__checkmark}
              />
            </div>
          </>
        )}
        <Button
          className={classNames.AudioAndVideo__content__button}
          onClick={toggleAudio}
          disabled={allowMicrophone}
        >
          <div className={classNames.AudioAndVideo__content__iconwrapper}>
            {checkAudioStarted && (
              <img
                src={checkMark}
                alt="Dog holding an electric code."
                className={
                  classNames.AudioAndVideo__content__button__checkmarkmic
                }
              />
            )}
            <BsMic size={25} />
            {!checkAudioStarted ? (
              <div
                className={classNames.AudioAndVideo__content__iconwrapper__text}
              >
                Turn on microphone
              </div>
            ) : (
              <VolumeIndicator level={volumeLevel} classNames={classNames} />
            )}
          </div>
        </Button>
      </div>
      {/* <div className={classNames.changeDeviceWrapper}>
        <div className={classNames.changeDeviceWrapper__content}>
          <Dropdown overlay={menu("video")}>
            <DownOutlined onClick={(e) => e.preventDefDownOutlinedult()}>
              Video input device
            </DownOutlined>
          </Dropdown>
        </div>

        <div className={classNames.changeDeviceWrapper__content}>
          <Dropdown overlay={menu("audio")}>
            <DownOutlined onClick={(e) => e.preventDefault()}>
              Audio input device
            </DownOutlined>
          </Dropdown>
        </div>
      </div> */}
      <div className={classNames.alertWrapper}>
        <div>
          {allowMicrophone && (
            <Alert message="Please allow microphone " type="error" closable />
          )}
        </div>
        <div>
          {allowWebcam && (
            <Alert message="Please allow webcam " type="error" closable />
          )}
        </div>
        <div>
          {/* {allowScreenCapture && (
            <Alert
              message="Please allow screen capture "
              type="error"
              closable
            />
          )} */}
        </div>
      </div>
    </div>
  );
}
