import endpoints from "../../config";

function appendParam(params, url) {
  let paramStr = "";
  Object.keys(params).forEach((value) => {
    paramStr += `${value}=${params[value]}&`;
  });
  return `${url}?${paramStr}`;
}

export default async function getQuestionById(data) {
  switch (typeof data) {
    case "object":
      let urlWithParams = appendParam(
        data,
        endpoints.QUESTION[process.env.REACT_APP_ENV]
      );
      return await (await fetch(urlWithParams)).json();

    case "string":
      return await (
        await fetch(
          `${endpoints.QUESTION[process.env.REACT_APP_ENV]}${
            data ? "?id=" + data : ""
          }`
        )
      ).json();

    default:
      return await (
        await fetch(endpoints.QUESTION[process.env.REACT_APP_ENV])
      ).json();
  }
}
