import types from "../../types";
import api from "../../../api";
import { generateSyncAction } from "../../utils.redux";

export default function updateStage(input) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.candidates.scheduleInterview.started));

    try {
      const data = await api.candidates.scheduleInterview(input);
      dispatch(
        generateSyncAction(types.candidates.scheduleInterview.success, data)
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.candidates.scheduleInterview.failed, error)
      );
    }
  };
}
