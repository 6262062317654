export default function camStateReducer(state, action) {
  switch (action.type) {
    case "setSupervisorCameState":
      return { ...state, isOn: action.payload };
    case "setCandidateCameState":
      return { ...state, isOn: action.payload };
    default:
      throw new Error();
  }
}
