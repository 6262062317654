import endpoints from "../../config";

export default async function getQuestionsByIds(obj) {
  let url;
  if (Array.isArray(obj)) {
    url = `${
      endpoints.QUESTION[process.env.REACT_APP_ENV]
    }/batch-get?data=${JSON.stringify(obj)}`;
  } else if (typeof obj === "object") {
    url = `${
      endpoints.QUESTION[process.env.REACT_APP_ENV]
    }/batch-get?data=${JSON.stringify(obj.questions)}`;

    if (obj.isCandidate !== undefined) {
      url = `${
        endpoints.QUESTION[process.env.REACT_APP_ENV]
      }/batch-get?data=${JSON.stringify(
        obj.questions
      )}&isCandidate=${JSON.stringify(obj.isCandidate)}`;
    }
  } else {
    url = `${
      endpoints.QUESTION[process.env.REACT_APP_ENV]
    }/batch-get?data=${JSON.stringify(obj)}`;
  }

  return await fetch(url)
    .then((res) => res.json())
    .catch((err) => console.log("error: ", err));
}
