import types from "../../types";
import { generateSyncAction } from "../../utils.redux";

export default function tempRemoveQuestionFromTest(data) {
  return function (dispatch) {
    dispatch(
      generateSyncAction(
        types.tempQuestions.removeQuestionsFromTest.success,
        data
      )
    );
  };
}
