import types from "../../types";

export default function signup(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.user.confirmAuth.started:
      return Object.assign({}, { fetching: true });

    case types.user.confirmAuth.success:
      return Object.assign({}, { fetching: false, data: action.data });

    case types.user.confirmAuth.failed:
      return Object.assign({}, { fetching: false, error: action.data });

    default:
      return state;
  }
}
