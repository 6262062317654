import types from "../../types";
import { message } from "antd";

import { generateSyncAction } from "../../utils.redux";
import api from "../../../api";

export default function createExercise(input) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.codeEditor.createExercise.started));
    try {
      const data = await api.codeEditor.create(input);
      dispatch(
        generateSyncAction(types.codeEditor.createExercise.success, data)
      );
      message.success("Question created successfully!");
    } catch (error) {
      dispatch(
        generateSyncAction(types.codeEditor.createExercise.failed, error)
      );
      message.error("An error occured! Please try again!");
    }
  };
}
