import React from "react";
import classNames from "./Title.module.scss";

type Props = {
  as?: "h1" | "h2" | "h3" | "h4" | "h5";
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  weight?: "normal" | "light";
};

const Title = ({
  as: El = "h1",
  children,
  className,
  style,
  weight = "normal",
}: Props): JSX.Element => {
  return (
    <El
      style={{ ...style }}
      className={`${classNames.title} ${classNames[El]} ${classNames[weight]} ${className} `}
    >
      {children}
    </El>
  );
};

export default Title;
