import { API } from "aws-amplify";
import { listMessagesByCandidateId } from "../../graphql/queries";

export default async function fetchCompanyProfile(candidateId) {
  return await API.graphql({
    query: listMessagesByCandidateId,
    variables: {
      candidateId,
    },
  });
}
