import types from "../../../types";

export default function listTestTemplates(
  state = { fetching: false, success: false },
  action
) {
  switch (action.type) {
    case types.skillTest.getTestTemplates.started:
      return Object.assign({}, { fetching: true });

    case types.skillTest.getTestTemplates.success:
      return Object.assign(
        {},
        { fetching: false, success: true, data: action.data }
      );

    case types.skillTest.getTestTemplates.failed:
      return Object.assign(
        {},
        { fetching: false, success: false, error: action.data }
      );

    default:
      return state;
  }
}
