import {
  fetchCompanyProfile,
  updateCompanyProfile,
  updateCompanyHandler,
  updateCompanyLogo,
  getCompanyLogo,
} from "./company";
import {
  getLanguages,
  runCode,
  listExercises,
  createExercise,
} from "./codeEditor";
import {
  signup,
  confirmAuth,
  signin,
  fetchCurrentUser,
  createOnboardingProfiles,
  fetchUserProfile,
  updateProfileCompletionCognito,
  inviteMember,
  resetMemberPassword,
  activateMemberAccount,
  updateUserAvatar,
  globalSignOut,
  deactivateUserProfile,
  removeMember,
} from "./user";
import {
  createJob,
  fetchJob,
  listJobs,
  listJobTitles,
  updateJob,
} from "./Jobs";
import {
  createFeedback,
  updateFeedback,
  deleteFeedback,
  getFeedbackByCandidate,
} from "./feedback";
import {
  fetchCandidates,
  updateCandidateStage,
  changeManagerInCharge,
  scheduleInterview,
  fetchInterviewsByCandidate,
  listCandidatesByCompanyId,
  listInterviewsByCompanyId, //Fix
  getCandidatesByCompanyId,
  recordSentSkillTests,
} from "./candidates";
import { send, list } from "./messaging";
import { fetchSubcrptionPlanByCompanyId } from "./subscriptionPlan";
import { completeSteps } from "./common";
import {
  getSkillTestById,
  listSkillTestsByCompanyId,
  createTest,
  updateTest,
  listTestsTemplates,
} from "./skillTest";
import { listTestsByCompanyId } from "./tests";
import {
  getQuestionById,
  listQuestions,
  createQuestion,
  listQuestionsByIds,
  skillTestQuestions,
} from "./question";
import {
  startCandidateTest,
  getCandidateTestById,
  createCandidateTestApi,
  listCandidateTestsByCandidateId,
  updateCandidateTestResult,
  rateCandidateTestResult,
  sendManagerEmail,
} from "./candidateTest";
import { getAllAnswers, createAnswerApi } from "./answer";
import { submitCandidateTest } from "./candidateTest";
import { listDifficulty } from "./difficulty";
import { getAllIndustry } from "./industry";
import { getAllTestDuration } from "./testDuration";
import { listQuestionTypes } from "./questionTypes";
import { listDesignation } from "./designation";
import { assignDefaultSkillTest, getTempSkillTestData } from "./skillTest";
import { filterQuesitons } from "./exercise";
import { getSignedURL } from "./liveRecording";

const api = {
  user: {
    signin,
    signup,
    fetchCurrentUser,
    updateProfile: null,
    deleteProfile: null,
    changePassword: null,
    confirmAuth,
    createOnboardingProfiles,
    fetchUserProfile,
    updateProfileCompletionCognito,
    inviteMember,
    resetMemberPassword,
    activateMemberAccount,
    updateUserAvatar,
    globalSignOut,
    deactivateUserProfile,
    removeMember,
  },
  jobs: {
    create: createJob,
    list: listJobs,
    get: fetchJob,
    listTitles: listJobTitles,
    update: updateJob,
  },
  company: {
    get: fetchCompanyProfile,
    create: null,
    update: updateCompanyProfile,
    delete: null,
    deactivate: null,
    updateHandler: updateCompanyHandler,
    updateCompanyLogo,
    getCompanyLogo,
  },
  candidates: {
    list: fetchCandidates,
    updateStage: updateCandidateStage,
    changeManagerInCharge,
    scheduleInterview,
    listInterviews: fetchInterviewsByCandidate,
    listByCompanyId: listCandidatesByCompanyId,
    listInterviewsByCompanyId,
    getCandidatesByCompanyId,
    recordSentSkillTests,
  },
  feedback: {
    create: createFeedback,
    update: updateFeedback,
    getFeedbackByCandidate: getFeedbackByCandidate,
    delete: deleteFeedback,
  },
  message: {
    send,
    list,
  },
  subscription: {
    getByCompanyId: fetchSubcrptionPlanByCompanyId,
  },
  common: {
    completeSteps,
  },
  skillTest: {
    getById: getSkillTestById,
    create: createTest,
    list: listSkillTestsByCompanyId,
    assignToAJob: assignDefaultSkillTest,
    updateById: updateTest,
    listTemplates: listTestsTemplates,
    getTempSkillTestData,
  },
  tests: {
    getByCompanyId: listTestsByCompanyId,
  },
  question: {
    get: getQuestionById,
    list: listQuestions,
    create: createQuestion,
    listQuestionsByIds: listQuestionsByIds,
    skillTestQuestions: skillTestQuestions,
  },
  candidateTest: {
    get: getCandidateTestById,
    start: startCandidateTest,
    submit: submitCandidateTest,
    create: createCandidateTestApi,
    list: listCandidateTestsByCandidateId,
    updateResult: updateCandidateTestResult,
    rateResult: rateCandidateTestResult,
    sendManagerEmail: sendManagerEmail,
  },
  answer: {
    getAll: getAllAnswers,
    create: createAnswerApi,
  },
  difficulty: {
    list: listDifficulty,
  },
  industry: {
    list: getAllIndustry,
  },
  testDuration: {
    list: getAllTestDuration,
  },
  questionType: {
    listQuestionTypes,
  },
  designation: {
    list: listDesignation,
  },
  codeEditor: {
    getLanguages,
    runCode,
    list: listExercises,
    create: createExercise,
  },
  exercise: {
    filterQuesitons,
  },
  liveRecording: {
    getURL: getSignedURL,
  },
};

export default api;
