import { useState, useEffect, useCallback } from "react";
import ReactQuill from "react-quill";
import { PrimaryButton, Title } from "../../../common";
import { useDispatch } from "react-redux";
import ac from "../../../../redux/actions";
import classNames from "./Content.module.scss";
import {
  Mcq,
  MultiSelect,
  CodingExercise,
  FreeText,
} from "../../QuestionTypes";
import { Form } from "antd";
import WarnBeforeNavigating from "../../../../helpers/WarnBeforeNavigating";
import { deepClone } from "../../../../helpers/utils";

export default function Content({
  allQType,
  currentQuestion,
  allQue,
  changeCurrentQuestion,
  setAnsweredQuestion,
  answeredQuestions,
}) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const [multiValue, setMultiValue] = useState([]);
  const [form] = Form.useForm();

  const [codingQuestion, setCodingQuestion] = useState({
    files: [],
  });

  const currentQType = allQType?.find(
    (value) => value.id === currentQuestion.typeId
  )?.questionText;

  function findNextQuestion() {
    let currentQIndex = allQue.findIndex(
      (que) => que.id === currentQuestion.id
    );
    if (currentQIndex !== -1 && allQue.length - 1 > currentQIndex) {
      return allQue[currentQIndex + 1];
    } else {
      return allQue[currentQIndex];
    }
  }

  function handleAnswerSubmit() {
    if (value !== null) {
      changeCurrentQuestion(findNextQuestion());
      dispatch(
        ac.mapAnswersWithQuestionTemp({
          option: [value],
          questionId: currentQuestion.id,
        })
      );
    }
  }

  function handleMultiAnswerSubmit() {
    if (multiValue.length > 0) {
      changeCurrentQuestion(findNextQuestion());
      dispatch(
        ac.mapAnswersWithQuestionTemp({
          option: [...multiValue],
          questionId: currentQuestion.id,
        })
      );
    }
  }

  function handleCodingAnswerSubmit() {
    /**
     * currentQuestion?.initialLan will be only available for
     * coding questions with pre selected language
     * This will be the one and only prop to identify
     * the coding question with pre selected language
     */
    if (!form.getFieldValue("language") && !currentQuestion?.initialLan) {
      form.validateFields(["language"]);
      return;
    }
    if (currentQType === "codingExercise" && codingQuestion?.files.length > 0) {
      currentQuestion.initialLan
        ? dispatch(
            ac.mapAnswersWithQuestionTemp({
              option: [codingQuestion],
              questionId: currentQuestion.id,
              candidateSelectedLang: currentQuestion.initialLan
                ? currentQuestion.initialLan
                : form.getFieldValue("language"),
            })
          )
        : dispatch(
            ac.mapAnswersWithQuestionTemp({
              option: [codingQuestion],
              questionId: currentQuestion.id,
              candidateSelectedLang: form.getFieldValue("language"),
            })
          );
      changeCurrentQuestion(findNextQuestion());
      setCodingQuestion({ files: [] });
      form.resetFields();
    }
  }

  function handleFreeTextInput(value) {
    setValue(value);
  }

  function handleAnswerSelect(option, index) {
    setValue({ option, index });
  }

  function handleMultiAnswerSelect(option, index) {
    if (multiValue.length === 0) {
      setMultiValue([{ option, index }]);
    }
    let copy = [...multiValue];
    let objectIndex = copy.findIndex((value) => value.option === option);
    if (objectIndex !== -1) {
      copy.splice(objectIndex, 1);
    } else {
      copy.push({ option, index });
    }
    setMultiValue(copy);
  }

  const onAnswerSubmit = () => {
    if (
      currentQType === "codingExercise" &&
      !form.getFieldValue("language") &&
      !currentQuestion?.selectedLan
    ) {
      setAnsweredQuestion([...new Set([...answeredQuestions])]);
    } else {
      setAnsweredQuestion([
        ...new Set([...answeredQuestions, currentQuestion?.id]),
      ]);
    }
    return currentQType === "multiSelect"
      ? handleMultiAnswerSubmit()
      : currentQType === "codingExercise"
      ? handleCodingAnswerSubmit()
      : handleAnswerSubmit();
  };

  const onAnswerChange = (value1, value2) => {
    const newList = answeredQuestions.filter(
      (question) => question !== currentQuestion?.id
    );
    setAnsweredQuestion(newList);
    return currentQType === "multiSelect"
      ? handleMultiAnswerSelect(value1, value2)
      : currentQType === "mcq"
      ? handleAnswerSelect(value1, value2)
      : currentQType === "freeText"
      ? handleFreeTextInput(value1)
      : setCodingQuestion(value1);
  };

  const renderAnswerComponent = () => {
    const props = {
      options: currentQuestion?.options,
      questionId: currentQuestion?.id,
      onChange: onAnswerChange,
      selectedAnswer: currentQuestion?.answer,
      mcqSelectedAnswer: value,
      allAnswers: currentQuestion?.answers,
      codingLanguage: currentQuestion?.selectedLan,
      files: codingQuestion,
      originalFiles: currentQuestion?.files ?? { files: [] },
    };

    switch (currentQType) {
      case "freeText":
        return <FreeText {...props} />;
      case "mcq":
        return <Mcq {...props} />;
      case "multiSelect":
        return <MultiSelect {...props} />;
      case "codingExercise":
        return <CodingExercise formIns={form} {...props} />;
      default:
        return null;
    }
  };

  const renderCodingExerciseQuestionComponent = useCallback(() => {
    return (
      <div className={classNames.wrapperQues} id="testQuill">
        <Title as="h3" style={{ fontSize: "22px", fontWeight: "700" }}>
          Description
        </Title>
        <ReactQuill
          readOnly={true}
          theme={"bubble"}
          value={currentQuestion?.detailedDescription}
          className={classNames.description}
        />
      </div>
    );
  }, [currentQuestion]);

  //When current question change, reset the local state
  useEffect(() => {
    setValue(null);
    setMultiValue([]);
    if (currentQuestion.answer && currentQType === "multiSelect") {
      setMultiValue([...currentQuestion.answer]);
    }
    if (currentQuestion?.answer && currentQType === "mcq") {
      Array.isArray(currentQuestion?.answer) &&
        currentQuestion?.answer?.length &&
        setValue(currentQuestion?.answer[0]);
    }
  }, [currentQuestion, currentQType]);

  useEffect(() => {
    if (currentQType === "codingExercise") {
      if (currentQuestion?.files) {
        const answerdQuestion = allQue.find((e) => e.id === currentQuestion.id);
        setCodingQuestion(
          (answerdQuestion?.answer && answerdQuestion?.answer[0]) ??
            deepClone(currentQuestion?.files)
        );
      } else {
        setCodingQuestion({ files: [] });
      }
    }
  }, [currentQuestion.id, currentQType, currentQuestion.files, allQue]);

  const isSubmitDisabled = () => {
    //If all questions are answered we disable submit
    if (allQue?.length === answeredQuestions?.length) {
      return true;
    }
    if (currentQType !== "codingExercise") {
      if (currentQuestion.answer || value !== null || multiValue.length > 0) {
        return false;
      }
      return true;
    }
    return false;
  };

  useEffect(() => {
    return () => dispatch(ac.resetEditor());
  }, [currentQuestion?.id, dispatch]);

  return (
    currentQuestion !== null &&
    currentQuestion !== undefined && (
      <div className={classNames.wrapper}>
        <WarnBeforeNavigating />
        <Title as="h3" style={{ fontSize: "22px", fontWeight: "700" }}>
          Question {currentQuestion.order}
        </Title>
        <p style={{ marginBottom: "46px" }}>{currentQuestion?.question}</p>
        {currentQType === "codingExercise" &&
        currentQuestion?.detailedDescription
          ? renderCodingExerciseQuestionComponent(currentQuestion)
          : null}

        {currentQType && renderAnswerComponent()}
        <div className={classNames.submitAnswer}>
          <PrimaryButton
            variant="secondary"
            className={classNames.submitAnswerBtn}
            onClick={onAnswerSubmit}
            //  Removed nested ternaries. It can make code more difficult to understand
            disabled={isSubmitDisabled()}
          >
            Submit Answer
          </PrimaryButton>
        </div>
      </div>
    )
  );
}
