const stages = [
    {
      id: "4093a987-42ea-4071-adc7-bb88fc3e39f1",
      name: "startup",
      title: "Startup",
    },
    {
      id: "edd3ddb6-94e8-426f-9e25-85c6d29f508a",
      name: "early",
      title: "Early",
    },
    {
      id: "b0c1d98c-742d-4d0d-8156-570021f9b7a5",
      name: "growth",
      title: "Growth",
    },
    {
      id: "9d9911dd-ef1b-43e1-afcf-7a4a78b3ebe0",
      name: "exit",
      title: "Exit",
    },
  ];

  export default stages;