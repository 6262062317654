import classNames from "./FreeText.module.scss";
import Title from "../../Title";

type Props = {
  question: string;
  hint: string;
  candidateAnswer: string;
  noTitle?: boolean;
  fullWidth?: boolean;
};

const FreeText = ({
  hint,
  candidateAnswer,
  question,
  noTitle,
  fullWidth,
}: Props) => {
  return (
    <div>
      <div className={classNames.wrapper}>
        <div className={fullWidth === true ? classNames.fullWidth : ""}>
          <Title as="h4" style={{ fontSize: "13px", opacity: "0.5" }}>
            Hint
          </Title>
          <span>{hint}</span>
        </div>
        {candidateAnswer ? (
          <div>
            <Title as="h4" style={{ fontSize: "13px", opacity: "0.5" }}>
              Candidate Answer
            </Title>
            <span>{candidateAnswer}</span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default FreeText;
