import { Children, isValidElement, ReactNode } from "react";

const findChildByTypeName = (children: ReactNode, name: string): ReactNode =>
  Children.toArray(children).find(
    (Child): React.ReactNode => {
      if (isValidElement(Child) && typeof Child.type === "function") {
        return Child.type.name === name;
      }
      return null;
    }
  );

export default findChildByTypeName;
