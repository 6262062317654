import types from "../../../types";
import { generateSyncAction } from "../../../utils.redux";
import API from "../../../../api";

function formatData(questionsList, questionTypes) {
  let resultArray = [];

  const freeTextType = questionTypes.find(
    (type) => type.questionText === "freeText"
  );

  const codeExerciseType = questionTypes.find(
    (type) => type.questionText === "codingExercise"
  );

  questionsList.forEach((ques) => {
    if (ques.answer) {
      if (
        ques.typeId !== freeTextType.id &&
        ques.typeId !== codeExerciseType.id
      ) {
        resultArray.push({
          questionId: ques.id,
          answer: [...ques.answer.map((ans) => ans.option)], // user selected answer for "mcq or multiSelect"
        });
      } else if (ques.typeId === codeExerciseType.id) {
        resultArray = [
          ...resultArray,
          {
            questionId: ques.id,
            answer: ques?.answer[0]?.files ?? [],
            ...(ques.selectedLan && { selectedLan: ques.selectedLan }),
          },
        ];
      } else {
        resultArray.push({
          questionId: ques.id,
          answer: [...ques.answer], // user typed answer for "freeText"
        });
      }
    } else {
      // when user did not provide answer execute else block
      resultArray.push({
        questionId: ques.id,
        answer: [], // when user did not provide answer, then assign empty array
      });
    }
  });

  return resultArray;
}

export default function submitAnswers(
  questionsList,
  questionTypes,
  testId,
  candidateTestId,
  expirationDate
) {
  return async function (dispatch) {
    dispatch(
      generateSyncAction(types.candidateTest.submit.started, {
        success: undefined,
      })
    );

    const data = await API.candidateTest.submit({
      skillTestId: testId,
      candidateTestId: candidateTestId,
      answers: formatData(questionsList, questionTypes),
      expirationDate: expirationDate,
    });

    try {
      dispatch(generateSyncAction(types.candidateTest.submit.success, data));
    } catch (error) {
      dispatch(generateSyncAction(types.candidateTest.submit.failed, error));
    }
  };
}
