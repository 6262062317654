import { useCallback, useEffect } from "react";

export default function WarnBeforeNavigating({ onInputChange }) {
  const confirmationMessage =
    "It looks like you have been editing something. " +
    "If you leave before saving, your changes will be lost.";

  const unloadListener = useCallback(
    (e) => {
      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    },
    [confirmationMessage]
  );
  const inputChangeListener = useCallback(() => {
    onInputChange?.(true);
  }, [onInputChange]);

  // Check form input change or not
  useEffect(() => {
    const el = document.getElementsByTagName("form")?.[0];
    el?.addEventListener("change", inputChangeListener);
    return () => {
      window.removeEventListener("change", inputChangeListener);
    };
  }, [onInputChange, inputChangeListener]);

  // Resources unload event, eg: page close or reload
  useEffect(() => {
    window.removeEventListener("beforeunload", unloadListener);
    window.addEventListener("beforeunload", unloadListener);
    // Remove the event from other pages after navigating a "warning" page
    return () => {
      window.removeEventListener("beforeunload", unloadListener);
    };
  }, [confirmationMessage, unloadListener]);

  return <></>;
}
