/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOnboardingProfiles = /* GraphQL */ `
  mutation CreateOnboardingProfiles(
    $companyId: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $companyName: String!
    $companyEmail: String!
    $currentStage: String!
    $website: String
    $size: String!
    $industry: String!
    $address: String!
    $country: String!
    $approved: Boolean!
    $active: Boolean!
    $deleted: Boolean!
    $phone: String!
    $ownerId: ID!
  ) {
    createOnboardingProfiles(
      companyId: $companyId
      firstName: $firstName
      lastName: $lastName
      email: $email
      companyName: $companyName
      companyEmail: $companyEmail
      currentStage: $currentStage
      website: $website
      size: $size
      industry: $industry
      address: $address
      country: $country
      approved: $approved
      active: $active
      deleted: $deleted
      phone: $phone
      ownerId: $ownerId
    ) {
      companyId
      userId
      companyHandler
      profileCreated
    }
  }
`;
export const inviteMember = /* GraphQL */ `
  mutation InviteMember(
    $firstName: String!
    $lastName: String!
    $companyId: ID!
    $email: AWSEmail!
  ) {
    inviteMember(
      firstName: $firstName
      lastName: $lastName
      companyId: $companyId
      email: $email
    ) {
      id
      role
    }
  }
`;
export const removeMember = /* GraphQL */ `
  mutation RemoveMember($id: ID!, $companyId: ID!, $email: String!) {
    removeMember(id: $id, companyId: $companyId, email: $email) {
      id
      firstName
      lastName
      email
      role
      companyId
      active
    }
  }
`;
export const companyMemberChallenge = /* GraphQL */ `
  mutation CompanyMemberChallenge($email: AWSEmail!) {
    companyMemberChallenge(email: $email) {
      id
      accountActivated
    }
  }
`;
export const updateCandidateStage = /* GraphQL */ `
  mutation UpdateCandidateStage($stage: String!, $candidateId: ID!) {
    updateCandidateStage(stage: $stage, candidateId: $candidateId) {
      stage
      candidateId
    }
  }
`;
export const updateCandidateManager = /* GraphQL */ `
  mutation UpdateCandidateManager($candidateId: ID!, $managerId: ID!) {
    updateCandidateManager(candidateId: $candidateId, managerId: $managerId) {
      candidateId
      managerId
    }
  }
`;
export const updateCompanyProfile = /* GraphQL */ `
  mutation UpdateCompanyProfile(
    $id: ID!
    $companyEmail: AWSEmail
    $phone: String
    $address: String
    $country: String
    $industry: String
    $website: String
  ) {
    updateCompanyProfile(
      id: $id
      companyEmail: $companyEmail
      phone: $phone
      address: $address
      country: $country
      industry: $industry
      website: $website
    ) {
      id
      companyEmail
      phone
      address
      country
      industry
      website
    }
  }
`;
export const updateCompanyHandler = /* GraphQL */ `
  mutation UpdateCompanyHandler($companyId: ID!, $companyHandler: String!) {
    updateCompanyHandler(
      companyId: $companyId
      companyHandler: $companyHandler
    ) {
      companyId
      handler
    }
  }
`;
export const assignDefaultTest = /* GraphQL */ `
  mutation AssignDefaultTest($jobId: ID!, $defaultSkillTestId: ID!) {
    assignDefaultTest(jobId: $jobId, defaultSkillTestId: $defaultSkillTestId) {
      jobId
      defaultSkillTestId
    }
  }
`;
export const completeSteps = /* GraphQL */ `
  mutation CompleteSteps($companyId: ID!, $steps: AWSJSON) {
    completeSteps(companyId: $companyId, steps: $steps) {
      companyId
      steps
    }
  }
`;
export const updateUserAvatar = /* GraphQL */ `
  mutation UpdateUserAvatar($ext: String!, $companyId: ID!) {
    updateUserAvatar(ext: $ext, companyId: $companyId) {
      avatar
    }
  }
`;
export const updateCompanyLogo = /* GraphQL */ `
  mutation UpdateCompanyLogo($ext: String!, $companyId: ID!) {
    updateCompanyLogo(ext: $ext, companyId: $companyId) {
      logo
    }
  }
`;
export const sendNewCandidateTest = /* GraphQL */ `
  mutation SendNewCandidateTest($candidateId: ID!, $testId: ID!) {
    sendNewCandidateTest(candidateId: $candidateId, testId: $testId) {
      candidateId
      testId
    }
  }
`;
export const deactivateUserProfile = /* GraphQL */ `
  mutation DeactivateUserProfile(
    $id: ID!
    $accountActivated: Boolean!
    $subscription_id: String
    $subscriptionStatus: String
    $role: String
  ) {
    deactivateUserProfile(
      id: $id
      accountActivated: $accountActivated
      subscription_id: $subscription_id
      subscriptionStatus: $subscriptionStatus
      role: $role
    ) {
      deactivated
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      handlers
      email
      role
      companyId
      accountActivated
      company {
        id
        ownerId
        companyName
        companyEmail
        currentStage
        website
        size
        companyHandler
        industry
        address
        country
        approved
        active
        deleted
        phone
        handlers
        members {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          active
          avatar
          createdAt
          updatedAt
        }
        steps
        logo
        createdAt
        updatedAt
      }
      interviews {
        items {
          id
          candidateId
          managerId
          companyId
          interviewStartTime
          interviewFinishTime
          name
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      active
      avatar
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      handlers
      email
      role
      companyId
      accountActivated
      company {
        id
        ownerId
        companyName
        companyEmail
        currentStage
        website
        size
        companyHandler
        industry
        address
        country
        approved
        active
        deleted
        phone
        handlers
        members {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          active
          avatar
          createdAt
          updatedAt
        }
        steps
        logo
        createdAt
        updatedAt
      }
      interviews {
        items {
          id
          candidateId
          managerId
          companyId
          interviewStartTime
          interviewFinishTime
          name
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      active
      avatar
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      handlers
      email
      role
      companyId
      accountActivated
      company {
        id
        ownerId
        companyName
        companyEmail
        currentStage
        website
        size
        companyHandler
        industry
        address
        country
        approved
        active
        deleted
        phone
        handlers
        members {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          active
          avatar
          createdAt
          updatedAt
        }
        steps
        logo
        createdAt
        updatedAt
      }
      interviews {
        items {
          id
          candidateId
          managerId
          companyId
          interviewStartTime
          interviewFinishTime
          name
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      active
      avatar
      createdAt
      updatedAt
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      ownerId
      companyName
      companyEmail
      currentStage
      website
      size
      companyHandler
      industry
      address
      country
      approved
      active
      deleted
      phone
      handlers
      members {
        items {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          active
          avatar
          createdAt
          updatedAt
        }
        nextToken
      }
      owner {
        id
        firstName
        lastName
        handlers
        email
        role
        companyId
        accountActivated
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        active
        avatar
        createdAt
        updatedAt
      }
      steps
      logo
      createdAt
      updatedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      ownerId
      companyName
      companyEmail
      currentStage
      website
      size
      companyHandler
      industry
      address
      country
      approved
      active
      deleted
      phone
      handlers
      members {
        items {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          active
          avatar
          createdAt
          updatedAt
        }
        nextToken
      }
      owner {
        id
        firstName
        lastName
        handlers
        email
        role
        companyId
        accountActivated
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        active
        avatar
        createdAt
        updatedAt
      }
      steps
      logo
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      ownerId
      companyName
      companyEmail
      currentStage
      website
      size
      companyHandler
      industry
      address
      country
      approved
      active
      deleted
      phone
      handlers
      members {
        items {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          active
          avatar
          createdAt
          updatedAt
        }
        nextToken
      }
      owner {
        id
        firstName
        lastName
        handlers
        email
        role
        companyId
        accountActivated
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        active
        avatar
        createdAt
        updatedAt
      }
      steps
      logo
      createdAt
      updatedAt
    }
  }
`;
export const createJob = /* GraphQL */ `
  mutation CreateJob(
    $input: CreateJobInput!
    $condition: ModelJobConditionInput
  ) {
    createJob(input: $input, condition: $condition) {
      id
      experience
      jobRegion
      questions
      currency
      stages
      managerInCharge
      country
      isRemoteJob
      deleted
      minSalaray
      jobType
      maxSalary
      handlers
      description
      title
      isSalaryNegotiable
      companyId
      defaultSkillTestId
      companyHandler
      company {
        id
        ownerId
        companyName
        companyEmail
        currentStage
        website
        size
        companyHandler
        industry
        address
        country
        approved
        active
        deleted
        phone
        handlers
        members {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          active
          avatar
          createdAt
          updatedAt
        }
        steps
        logo
        createdAt
        updatedAt
      }
      candidates {
        items {
          id
          companyId
          jobId
          managerInCharge
          stage
          mobile
          cv
          handlers
          lastName
          firstName
          answers
          email
          candidateTests
          createdAt
          updatedAt
        }
        nextToken
      }
      published
      createdAt
      updatedAt
    }
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob(
    $input: UpdateJobInput!
    $condition: ModelJobConditionInput
  ) {
    updateJob(input: $input, condition: $condition) {
      id
      experience
      jobRegion
      questions
      currency
      stages
      managerInCharge
      country
      isRemoteJob
      deleted
      minSalaray
      jobType
      maxSalary
      handlers
      description
      title
      isSalaryNegotiable
      companyId
      defaultSkillTestId
      companyHandler
      company {
        id
        ownerId
        companyName
        companyEmail
        currentStage
        website
        size
        companyHandler
        industry
        address
        country
        approved
        active
        deleted
        phone
        handlers
        members {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          active
          avatar
          createdAt
          updatedAt
        }
        steps
        logo
        createdAt
        updatedAt
      }
      candidates {
        items {
          id
          companyId
          jobId
          managerInCharge
          stage
          mobile
          cv
          handlers
          lastName
          firstName
          answers
          email
          candidateTests
          createdAt
          updatedAt
        }
        nextToken
      }
      published
      createdAt
      updatedAt
    }
  }
`;
export const deleteJob = /* GraphQL */ `
  mutation DeleteJob(
    $input: DeleteJobInput!
    $condition: ModelJobConditionInput
  ) {
    deleteJob(input: $input, condition: $condition) {
      id
      experience
      jobRegion
      questions
      currency
      stages
      managerInCharge
      country
      isRemoteJob
      deleted
      minSalaray
      jobType
      maxSalary
      handlers
      description
      title
      isSalaryNegotiable
      companyId
      defaultSkillTestId
      companyHandler
      company {
        id
        ownerId
        companyName
        companyEmail
        currentStage
        website
        size
        companyHandler
        industry
        address
        country
        approved
        active
        deleted
        phone
        handlers
        members {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          active
          avatar
          createdAt
          updatedAt
        }
        steps
        logo
        createdAt
        updatedAt
      }
      candidates {
        items {
          id
          companyId
          jobId
          managerInCharge
          stage
          mobile
          cv
          handlers
          lastName
          firstName
          answers
          email
          candidateTests
          createdAt
          updatedAt
        }
        nextToken
      }
      published
      createdAt
      updatedAt
    }
  }
`;
export const createCandidate = /* GraphQL */ `
  mutation CreateCandidate(
    $input: CreateCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    createCandidate(input: $input, condition: $condition) {
      id
      companyId
      jobId
      managerInCharge
      stage
      mobile
      cv
      handlers
      lastName
      firstName
      answers
      email
      candidateTests
      messages {
        items {
          id
          message
          to
          from
          subject
          senderId
          candidateId
          employeeId
          recipientId
          html
          createdAt
          updatedAt
        }
        nextToken
      }
      feeedback {
        items {
          id
          feedback
          employeeId
          candidateId
          rate
          stageId
          createdAt
          updatedAt
        }
        nextToken
      }
      interview {
        items {
          id
          candidateId
          managerId
          companyId
          interviewStartTime
          interviewFinishTime
          name
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCandidate = /* GraphQL */ `
  mutation UpdateCandidate(
    $input: UpdateCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    updateCandidate(input: $input, condition: $condition) {
      id
      companyId
      jobId
      managerInCharge
      stage
      mobile
      cv
      handlers
      lastName
      firstName
      answers
      email
      candidateTests
      messages {
        items {
          id
          message
          to
          from
          subject
          senderId
          candidateId
          employeeId
          recipientId
          html
          createdAt
          updatedAt
        }
        nextToken
      }
      feeedback {
        items {
          id
          feedback
          employeeId
          candidateId
          rate
          stageId
          createdAt
          updatedAt
        }
        nextToken
      }
      interview {
        items {
          id
          candidateId
          managerId
          companyId
          interviewStartTime
          interviewFinishTime
          name
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCandidate = /* GraphQL */ `
  mutation DeleteCandidate(
    $input: DeleteCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    deleteCandidate(input: $input, condition: $condition) {
      id
      companyId
      jobId
      managerInCharge
      stage
      mobile
      cv
      handlers
      lastName
      firstName
      answers
      email
      candidateTests
      messages {
        items {
          id
          message
          to
          from
          subject
          senderId
          candidateId
          employeeId
          recipientId
          html
          createdAt
          updatedAt
        }
        nextToken
      }
      feeedback {
        items {
          id
          feedback
          employeeId
          candidateId
          rate
          stageId
          createdAt
          updatedAt
        }
        nextToken
      }
      interview {
        items {
          id
          candidateId
          managerId
          companyId
          interviewStartTime
          interviewFinishTime
          name
          notes
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) {
      id
      feedback
      employeeId
      candidateId
      rate
      stageId
      candidate {
        id
        companyId
        jobId
        managerInCharge
        stage
        mobile
        cv
        handlers
        lastName
        firstName
        answers
        email
        candidateTests
        messages {
          nextToken
        }
        feeedback {
          nextToken
        }
        interview {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback(
    $input: UpdateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    updateFeedback(input: $input, condition: $condition) {
      id
      feedback
      employeeId
      candidateId
      rate
      stageId
      candidate {
        id
        companyId
        jobId
        managerInCharge
        stage
        mobile
        cv
        handlers
        lastName
        firstName
        answers
        email
        candidateTests
        messages {
          nextToken
        }
        feeedback {
          nextToken
        }
        interview {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback(
    $input: DeleteFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      id
      feedback
      employeeId
      candidateId
      rate
      stageId
      candidate {
        id
        companyId
        jobId
        managerInCharge
        stage
        mobile
        cv
        handlers
        lastName
        firstName
        answers
        email
        candidateTests
        messages {
          nextToken
        }
        feeedback {
          nextToken
        }
        interview {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      message
      to
      from
      subject
      senderId
      candidateId
      employeeId
      recipientId
      html
      candidate {
        id
        companyId
        jobId
        managerInCharge
        stage
        mobile
        cv
        handlers
        lastName
        firstName
        answers
        email
        candidateTests
        messages {
          nextToken
        }
        feeedback {
          nextToken
        }
        interview {
          nextToken
        }
        createdAt
        updatedAt
      }
      member {
        id
        firstName
        lastName
        handlers
        email
        role
        companyId
        accountActivated
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        active
        avatar
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      message
      to
      from
      subject
      senderId
      candidateId
      employeeId
      recipientId
      html
      candidate {
        id
        companyId
        jobId
        managerInCharge
        stage
        mobile
        cv
        handlers
        lastName
        firstName
        answers
        email
        candidateTests
        messages {
          nextToken
        }
        feeedback {
          nextToken
        }
        interview {
          nextToken
        }
        createdAt
        updatedAt
      }
      member {
        id
        firstName
        lastName
        handlers
        email
        role
        companyId
        accountActivated
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        active
        avatar
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      message
      to
      from
      subject
      senderId
      candidateId
      employeeId
      recipientId
      html
      candidate {
        id
        companyId
        jobId
        managerInCharge
        stage
        mobile
        cv
        handlers
        lastName
        firstName
        answers
        email
        candidateTests
        messages {
          nextToken
        }
        feeedback {
          nextToken
        }
        interview {
          nextToken
        }
        createdAt
        updatedAt
      }
      member {
        id
        firstName
        lastName
        handlers
        email
        role
        companyId
        accountActivated
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        active
        avatar
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createInterview = /* GraphQL */ `
  mutation CreateInterview(
    $input: CreateInterviewInput!
    $condition: ModelInterviewConditionInput
  ) {
    createInterview(input: $input, condition: $condition) {
      id
      candidateId
      managerId
      companyId
      interviewStartTime
      interviewFinishTime
      name
      notes
      candidate {
        id
        companyId
        jobId
        managerInCharge
        stage
        mobile
        cv
        handlers
        lastName
        firstName
        answers
        email
        candidateTests
        messages {
          nextToken
        }
        feeedback {
          nextToken
        }
        interview {
          nextToken
        }
        createdAt
        updatedAt
      }
      manager {
        id
        firstName
        lastName
        handlers
        email
        role
        companyId
        accountActivated
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        active
        avatar
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateInterview = /* GraphQL */ `
  mutation UpdateInterview(
    $input: UpdateInterviewInput!
    $condition: ModelInterviewConditionInput
  ) {
    updateInterview(input: $input, condition: $condition) {
      id
      candidateId
      managerId
      companyId
      interviewStartTime
      interviewFinishTime
      name
      notes
      candidate {
        id
        companyId
        jobId
        managerInCharge
        stage
        mobile
        cv
        handlers
        lastName
        firstName
        answers
        email
        candidateTests
        messages {
          nextToken
        }
        feeedback {
          nextToken
        }
        interview {
          nextToken
        }
        createdAt
        updatedAt
      }
      manager {
        id
        firstName
        lastName
        handlers
        email
        role
        companyId
        accountActivated
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        active
        avatar
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteInterview = /* GraphQL */ `
  mutation DeleteInterview(
    $input: DeleteInterviewInput!
    $condition: ModelInterviewConditionInput
  ) {
    deleteInterview(input: $input, condition: $condition) {
      id
      candidateId
      managerId
      companyId
      interviewStartTime
      interviewFinishTime
      name
      notes
      candidate {
        id
        companyId
        jobId
        managerInCharge
        stage
        mobile
        cv
        handlers
        lastName
        firstName
        answers
        email
        candidateTests
        messages {
          nextToken
        }
        feeedback {
          nextToken
        }
        interview {
          nextToken
        }
        createdAt
        updatedAt
      }
      manager {
        id
        firstName
        lastName
        handlers
        email
        role
        companyId
        accountActivated
        company {
          id
          ownerId
          companyName
          companyEmail
          currentStage
          website
          size
          companyHandler
          industry
          address
          country
          approved
          active
          deleted
          phone
          handlers
          steps
          logo
          createdAt
          updatedAt
        }
        interviews {
          nextToken
        }
        active
        avatar
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSubscriptionPlan = /* GraphQL */ `
  mutation CreateSubscriptionPlan(
    $input: CreateSubscriptionPlanInput!
    $condition: ModelSubscriptionPlanConditionInput
  ) {
    createSubscriptionPlan(input: $input, condition: $condition) {
      id
      cancel_url
      checkout_id
      validity
      email
      event_time
      next_bill_date
      cancellation_effective_date
      subscription_plan_id
      update_url
      subscription_id
      trial
      companyId
      subscriptionStatus
      company {
        id
        ownerId
        companyName
        companyEmail
        currentStage
        website
        size
        companyHandler
        industry
        address
        country
        approved
        active
        deleted
        phone
        handlers
        members {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          active
          avatar
          createdAt
          updatedAt
        }
        steps
        logo
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSubscriptionPlan = /* GraphQL */ `
  mutation UpdateSubscriptionPlan(
    $input: UpdateSubscriptionPlanInput!
    $condition: ModelSubscriptionPlanConditionInput
  ) {
    updateSubscriptionPlan(input: $input, condition: $condition) {
      id
      cancel_url
      checkout_id
      validity
      email
      event_time
      next_bill_date
      cancellation_effective_date
      subscription_plan_id
      update_url
      subscription_id
      trial
      companyId
      subscriptionStatus
      company {
        id
        ownerId
        companyName
        companyEmail
        currentStage
        website
        size
        companyHandler
        industry
        address
        country
        approved
        active
        deleted
        phone
        handlers
        members {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          active
          avatar
          createdAt
          updatedAt
        }
        steps
        logo
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubscriptionPlan = /* GraphQL */ `
  mutation DeleteSubscriptionPlan(
    $input: DeleteSubscriptionPlanInput!
    $condition: ModelSubscriptionPlanConditionInput
  ) {
    deleteSubscriptionPlan(input: $input, condition: $condition) {
      id
      cancel_url
      checkout_id
      validity
      email
      event_time
      next_bill_date
      cancellation_effective_date
      subscription_plan_id
      update_url
      subscription_id
      trial
      companyId
      subscriptionStatus
      company {
        id
        ownerId
        companyName
        companyEmail
        currentStage
        website
        size
        companyHandler
        industry
        address
        country
        approved
        active
        deleted
        phone
        handlers
        members {
          nextToken
        }
        owner {
          id
          firstName
          lastName
          handlers
          email
          role
          companyId
          accountActivated
          active
          avatar
          createdAt
          updatedAt
        }
        steps
        logo
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const messageCandidate = /* GraphQL */ `
  mutation MessageCandidate(
    $query: MutationMessageCandidateQueryInput
    $body: MutationMessageCandidateBodyInput
  ) {
    messageCandidate(query: $query, body: $body) {
      id
    }
  }
`;
export const createCandidateTest = /* GraphQL */ `
  mutation CreateCandidateTest(
    $query: MutationCreateCandidateTestQueryInput
    $body: MutationCreateCandidateTestBodyInput
  ) {
    createCandidateTest(query: $query, body: $body) {
      id
      candidateId
      testId
      createdBy
    }
  }
`;
