import api from "../../../api";
import types from "../../types";
import { generateSyncAction } from "../../utils.redux";

export default function recordSentSkillTestsAC(data) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.candidates.recordSentSkillTests.started));
    try {
      const result = await api.candidates.recordSentSkillTests(data);
      dispatch(
        generateSyncAction(
          types.candidates.recordSentSkillTests.success,
          result.data
        )
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.candidates.recordSentSkillTests.failed, error)
      );
    }
  };
}
