import api from "../../../../api";
import types from "../../../types";
import { generateSyncAction } from "../../../utils.redux";

export default function listDifficulty() {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.difficulty.listDifficulty.started));

    try {
      const data = await api.difficulty.list();
      dispatch(
        generateSyncAction(types.difficulty.listDifficulty.success, data)
      );
    } catch (error) {
      dispatch(
        generateSyncAction(types.difficulty.listDifficulty.failed, error)
      );
    }
  };
}
