const companySizes = [
  {
    id: "1-10",
    value: "1-10",
  },
  {
    id: "11-50",
    value: "11-50",
  },
  {
    id: "51-100",
    value: "51-100",
  },
  {
    id: "101-250",
    value: "51-100",
  },
  {
    id: "251-500",
    value: "251-500",
  },
  {
    id: "501-1000",
    value: "501-1000",
  },
  {
    id: "1001-5000",
    value: "1,001-5,000",
  },
  {
    id: "5001-10000",
    value: "1,001-5,000",
  },
  {
    id: "10001-20000",
    value: "10,001-20,00",
  },
  {
    id: "20000+",
    value: "20,000+",
  },
];

export default companySizes;
