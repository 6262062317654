import types from "../../types";
import { generateSyncAction } from "../../utils.redux";
import API from "../../../api";

export default function updateProfileCompletionCognito(userId) {
  return async function (dispatch) {
    dispatch(
      generateSyncAction(types.user.updateProfileCompletionCognito.started)
    );

    try {
      const data = await API.user.updateProfileCompletionCognito(userId);
      dispatch(
        generateSyncAction(
          types.user.updateProfileCompletionCognito.success,
          data
        )
      );
    } catch (error) {
      dispatch(
        generateSyncAction(
          types.user.updateProfileCompletionCognito.failed,
          error
        )
      );
    }
  };
}
