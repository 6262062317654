import types from "../../types";
import { generateSyncAction } from "../../utils.redux";
import API from "../../../api";

export default function createFeedback(inputs) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.feedback.createFeedback.started));

    try {
      const data = await API.feedback.create(inputs);
      dispatch(generateSyncAction(types.feedback.createFeedback.success, data));
      dispatch(generateSyncAction(types.reset.createFeedback));
    } catch (error) {
      dispatch(generateSyncAction(types.feedback.createFeedback.failed, error));
    }
  };
}
