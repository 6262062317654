import classNames from "./Signup.module.scss";
import mollyAvatar from "../../../assets/molly-avatar.svg";
import mollyText from "../../../assets/molly-text.svg";
import ac from "../../../redux/actions";
import { Form, Spin, Alert } from "antd";
import { Link, useHistory } from "react-router-dom";
import { AuthLinkWrapper } from "../../common";
import { BsDot } from "react-icons/bs";
import { validation } from "./config";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Cache, Hub } from "aws-amplify";
import Drift from "react-driftjs";
import { Title, PrimaryButton, Input } from "../../common/index.js";

export default function Signup() {
  const [form] = Form.useForm();
  const [confirmDirty, setConfirmDirty] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const signUpData = useSelector(({ signup }) => signup);
  const [disable, setDisable] = useState(true);

  function handleSubmit({ email, password }) {
    const username = email;
    dispatch(ac.userSignup(username, password));
    setSubmitted(true);
  }

  const handleConfirmBlur = (event) =>
    setConfirmDirty(confirmDirty || !!event.target.value);

  useEffect(() => {
    Hub.listen("auth", function (data) {
      if (data.payload.event === "signUp") {
        Cache.setItem(`molly-signup`, data.payload.data.user.username);
        history.push("/confirm-auth");
      }
    });

    return function () {
      Hub.remove();
      // dispatch(ac.resetErrors());
    };
  }, [history, dispatch]);

  return (
    <div className={classNames.wrapper}>
      <img src={mollyAvatar} alt="Molly logo" className={classNames.logo} />
      <Title>Sign up</Title>
      {signUpData.error && submitted && (
        <Alert
          message={signUpData.error.message}
          type="error"
          style={{ marginBottom: 10 }}
        />
      )}
      <Form
        onFinish={handleSubmit}
        form={form}
        layout="vertical"
        hideRequiredMark
        className={classNames.formWrapper}
      >
        {/* Email */}
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: validation.email.required },
            { type: "email", message: validation.email.valid },
          ]}
        >
          <Input
            placeholder=""
            className={classNames.email}
            onClick={() => {
              setSubmitted(false);
              setDisable(false);
            }}
          />
        </Form.Item>

        {/* Password field */}
        <Form.Item
          label="Password"
          name="password"
          validateFirst={true}
          rules={[
            { required: true, message: validation.password.required },
            {
              min: 8,
              message: validation.password.minLength,
            },
            {
              pattern: new RegExp(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?\"!@#%&/,><':;|_~`^\\]\\[\\)\\(]).{6,}"
              ),
              message: validation.password.regex,
            },
          ]}
        >
          <Input
            placeholder=""
            className={classNames.input}
            type="password"
            onClick={() => {
              setDisable(false);
            }}
          />
        </Form.Item>

        {/* Confirm password field */}
        <Form.Item
          label="Confirm Password"
          name="confirm-password"
          dependencies={["password"]}
          rules={[
            { required: true, message: validation.confirmPassword.required },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(validation.confirmPassword.match);
              },
            }),
          ]}
        >
          <Input
            type="password"
            placeholder=""
            className={classNames.input}
            onBlur={handleConfirmBlur}
            onClick={() => {
              setDisable(false);
            }}
          />
        </Form.Item>

        <Spin spinning={false}>
          {/* Submit button */}
          <PrimaryButton
            htmlType="submit"
            type="primary"
            style={{ width: "100%", fontWeight: "bold" }}
            loading={signUpData.fetching}
            disabled={disable}
          >
            Sign up
          </PrimaryButton>
        </Spin>
      </Form>
      <div className={classNames.links}>
        <AuthLinkWrapper>
          <AuthLinkWrapper.Link to="/Signin" el={Link}>
            I already have an account
          </AuthLinkWrapper.Link>
          <BsDot />
          <AuthLinkWrapper.Link to="/forgot-password" el={Link}>
            Forgot password
          </AuthLinkWrapper.Link>
        </AuthLinkWrapper>
      </div>
      <img
        src={mollyText}
        alt="Molly logo"
        className={classNames.mollyLogoText}
      />
      <Drift appId="txw66twatn49" />
    </div>
  );
}
