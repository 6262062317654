import types from "../../types";
import { generateSyncAction } from "../../utils.redux";

export default function mapAnswersWithQuestionTemp(data) {
  return function (dispatch) {
    dispatch(
      generateSyncAction(types.tempAnswers.mapAnswersWithQuestion.started)
    );
    dispatch(
      generateSyncAction(types.tempAnswers.mapAnswersWithQuestion.success, data)
    );
  };
}
