import { useState, useEffect } from "react";
import { Tabs } from "antd";
import EditorInput from "./EditorInput";
import EditorOutput from "./EditorOutput";

const EditorView = ({ files, codingLanguage, displayOutput = false }) => {
  const [result] = useState("");
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    setTabs(
      files?.files?.map((o, i) => {
        return {
          key: `${i}`,
          label: o?.name ?? "",
          children: (
            <EditorInput value={o?.content ?? ""} language={codingLanguage} />
          ),
        };
      })
    );
  }, [files, codingLanguage]);

  return (
    <div className="code-editor-tabs">
      <Tabs
        type="editable-card"
        items={tabs}
        defaultActiveKey={0}
        tabBarGutter={0}
      />
      {displayOutput && <EditorOutput data={result} runCode={() => {}} />}
    </div>
  );
};

export default EditorView;
