import api from "../../../../api";
import types from "../../../types";
import { generateSyncAction } from "../../../utils.redux";

export default function rateCandidateTestResult(data) {
  return async function (dispatch) {
    dispatch(generateSyncAction(types.candidateTest.rateResult.started));
    try {
      const res = await api.candidateTest.rateResult(data);
      dispatch(generateSyncAction(types.candidateTest.rateResult.success, res));
    } catch (error) {
      dispatch(
        generateSyncAction(types.candidateTest.rateResult.failed, error)
      );
    }
  };
}
