import types from "../../types";

export default function tempAddQuestionToTest(
  state = { data: [], error: false },
  action
) {
  switch (action.type) {
    case types.tempQuestions.addQuestionsToTest.success:
      if (Array.isArray(action.data)) {
        return Object.assign(
          {},
          { success: true, data: [...state.data, ...action.data] }
        );
      } else if (typeof action.data === "object") {
        return Object.assign(
          {},
          { success: true, data: [...state.data, action.data] }
        );
      } else {
        return state;
      }

    case types.tempQuestions.removeQuestionsFromTest.success:
      let copy = [...state.data].filter(
        (question) => question.id !== action.data.id
      );
      return Object.assign({}, { success: true, data: [...copy] });

    case types.tempQuestions.cleanupTempQuestionsInTest.success:
      return Object.assign({}, { data: [], error: false });

    default:
      return state;
  }
}
