import types from "../../types";

export default function getFeedbackByCandidate(
  state = { fetching: false, error: false },
  action
) {
  switch (action.type) {
    case types.feedback.getFeedbackByCandidate.started:
      return Object.assign({}, { fetching: true });

    case types.feedback.getFeedbackByCandidate.success:
      return Object.assign(
        {},
        {
          fetching: false,
          data: { ...action.data },
        }
      );

    case types.feedback.getFeedbackByCandidate.failed:
      return Object.assign(
        {},
        {
          fetching: false,
          error: action.data,
        }
      );

    default:
      return state;
  }
}
